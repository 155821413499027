let __assets: Record<AssetKey, string>
export const assetPromises = async () => {
  if (__assets) {
    return __assets
  }
  const importPromise = await import.meta.glob(
    "~/assets/**/*.(png|jpg|jpeg|gif|webp)",
    {
      eager: true,
      import: "default",
    }
  )
  const promiseObject = { ...importPromise }
  __assets = Object.fromEntries(
    Object.entries(promiseObject).map(([__path, value]) => {
      const key = __path
        .replace(/(\/assets\/)|(\.\w+)/g, "")
        .replace(/\//g, "-")
      const path = value
      return [key, path]
    })
  ) as typeof __assets

  return __assets
}
