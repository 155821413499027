import HttpInstance from "~/request/server"
import { EnumKycStatus } from "~/types/enum"
import { wait, __raffle_ticket_map } from "#imports"
import { useUserStore } from "~/stores/user"
export enum EnumRaffleTicket {
  // 注册
  REG,
  // 充值
  DEPOSIT,
  // 圣诞
  XMAS,
  // KYC
  KYC,
  // 投注
  BET,
  // 助力
  XMAS_HELP,
}

export enum EnumRaffleTicketClaimable {
  // 已领取
  CLAIMED = "CLAIMED",
  // 不可领取
  NOT_CLAIMABLE = "NOT_CLAIMABLE",
  // 可领取
  CLAIMABLE = "CLAIMABLE",
}

export type TRaffleTicketData = {
  uid: number
  ticket_id: string
  event_type: EnumRaffleTicket
  created_at: string
}

export type TRaffleTicket = {
  name: string
  rightBtnText?: string
  desc: string
  isShowClaimed?: boolean
  isClaimable: () =>
    | Promise<EnumRaffleTicketClaimable>
    | EnumRaffleTicketClaimable
  claimTimes?: number
  type: EnumRaffleTicket
  onClaim?: (ticket: TRaffleTicket) => void
  onRightBtnClick?: () => void
}
const raffleTicketPopupProps = reactive<{
  show: boolean
  ticket?: TRaffleTicketData
}>({
  show: false,
  ticket: undefined,
})

const getRaffleTicketList = async () => {
  const userStore = useUserStore()
  const uid = Number(userStore.info?.id)
  if (uid) {
    const response = await HttpInstance.post("ticketList", {
      uid,
    })
    return (response.data.items ?? []) as TRaffleTicketData[]
  }
  return []
}

const getRaffleTicketListToday = async () => {
  const tickets = await getRaffleTicketList()
  return tickets.filter((item) => {
    return dayjsUtilManila(item.created_at).isAfter(
      dayjsUtilManila().startOf("day")
    )
  })
}

const raffleTicketList = reactive<TRaffleTicket[]>([
  {
    name: "KYC",
    type: EnumRaffleTicket.KYC,
    desc: "KYC成功，您将获得抽奖券一张。",
    rightBtnText: "去验证",
    async isClaimable() {
      const userStore = useUserStore()
      await userStore.GET_USER_KYC()
      const kycStatus = useKycStatus()
      const isComplete = [
        EnumKycStatus.CSR_APPROVED,
        EnumKycStatus.COMPLETED,
      ].includes(kycStatus.value)
      if (isComplete) {
        const tickets = await getRaffleTicketList()
        if (
          tickets.filter(
            (ticket) => Number(ticket.event_type) === EnumRaffleTicket.KYC
          ).length > 0
        ) {
          return EnumRaffleTicketClaimable.CLAIMED
        }
        return EnumRaffleTicketClaimable.CLAIMABLE
      }
      return EnumRaffleTicketClaimable.NOT_CLAIMABLE
    },
    onRightBtnClick() {
      useDialog().openDialog("kycSetting")
    },
  },
  {
    name: "充值",
    type: EnumRaffleTicket.DEPOSIT,
    desc: "充值成功，您将获得抽奖券一张。",
    rightBtnText: "去充值",
    claimTimes: 1,
    async isClaimable() {
      const response = await HttpInstance.post("getDepositRecord", {
        current: 1,
        data: {
          status: 1,
          endTime: dayjsUtilManila().endOf("day").unix(),
          startTime: dayjsUtilManila().startOf("day").unix(),
        },
      })
      return response.data.list?.length > 0
        ? EnumRaffleTicketClaimable.CLAIMABLE
        : EnumRaffleTicketClaimable.NOT_CLAIMABLE
    },
    async onRightBtnClick() {
      const route = useRoute()
      await navigateTo({
        name: "Deposit",
        query: {
          ...route.query,
        },
      })
    },
  },
  {
    name: "BET",
    type: EnumRaffleTicket.BET,
    desc: "投注成功，您将获得抽奖券一张。",
    rightBtnText: "去投注",
    claimTimes: 1,
    async isClaimable() {
      const parameters = {
        current: 1,
        data: {
          status: 2,
          startTime: dayjsUtilManila().startOf("day").unix(),
          endTime: dayjsUtilManila().endOf("day").unix(),
        },
        size: 5,
      }
      const promBetList = HttpInstance.post("betList", parameters)
      const promTickets = getRaffleTicketListToday()

      const { data } = await promBetList
      const tickets = await promTickets

      if (
        tickets.filter(
          (ticket) => Number(ticket.event_type) === EnumRaffleTicket.BET
        ).length > 0
      ) {
        return EnumRaffleTicketClaimable.CLAIMED
      }

      return data.list?.length > 0
        ? EnumRaffleTicketClaimable.CLAIMABLE
        : EnumRaffleTicketClaimable.NOT_CLAIMABLE
    },
    async onRightBtnClick() {
      await navigateTo({
        name: "Slots",
      })
    },
  },
  {
    name: "REGISTER",
    type: EnumRaffleTicket.REG,
    desc: "Register successful,you will receive a raffle ticket.",
    async isClaimable() {
      const userStore = useUserStore()
      const isLogin = userStore.isLogin ?? false
      if (isLogin) {
        const tickets = await getRaffleTicketList()
        if (
          tickets.filter(
            (ticket) => Number(ticket.event_type) === EnumRaffleTicket.REG
          ).length > 0
        ) {
          return EnumRaffleTicketClaimable.CLAIMED
        }
        return EnumRaffleTicketClaimable.CLAIMABLE
      }
      return EnumRaffleTicketClaimable.NOT_CLAIMABLE
    },
  },
])

export const useRaffleTicketManager = () => {
  const userStore = useUserStore()
  const raffleTicketStorageMap = useLocalStorage<{
    timeout: number
    ticketMap: Partial<
      Record<
        TRaffleTicket["type"],
        {
          isClaimed: boolean
        }
      >
    >
  }>(`${__raffle_ticket_map}-${userStore.info?.id || "0"}`, {
    timeout: dayjsUtilManila().endOf("day").valueOf(),
    ticketMap: {},
  })

  const raffleTicketStorageMapReset = () => {
    if (
      raffleTicketStorageMap.value.timeout <
      dayjsUtilManila().startOf("day").valueOf()
    ) {
      raffleTicketStorageMap.value = {
        timeout: dayjsUtilManila().endOf("day").valueOf(),
        ticketMap: {},
      }
    }
  }

  const popRaffleTicket = (ticket: TRaffleTicketData) => {
    raffleTicketPopupProps.show = true
    raffleTicketPopupProps.ticket = ticket
  }

  const createRaffleTicket = async (
    event_type: EnumRaffleTicket,
    pop = true
  ) => {
    try {
      if (userStore.isLogin) {
        const uid = Number(userStore.info?.id)
        if (uid) {
          const response = await HttpInstance.post("ticketCreate", {
            uid: userStore.info?.id,
            event_type,
          })
          const data = (response.data as TRaffleTicketData) ?? undefined
          raffleTicketStorageMap.value.ticketMap[data.event_type] = {
            isClaimed: true,
          }
          pop && popRaffleTicket(data)
          return data
        }
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  }

  return {
    raffleTicketStorageMap,
    raffleTicketStorageMapReset,
    raffleTicketList,
    raffleTicketPopupProps,
    popRaffleTicket,
    createRaffleTicket,
    getRaffleTicketList,
  }
}
