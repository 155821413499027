import { defineStore } from "pinia"
import type { StoreTypes } from "~/types/store"

const initialValue: StoreTypes.Svg.State = {
  svgIdList: [],
  svgViewBoxMap: {},
  svgSpriteLoaded: false,
}

export const useSvgStore = defineStore("Svg", {
  state: (): StoreTypes.Svg.State => ({
    ...initialValue,
  }),
  getters: {},
  actions: {
    async INIT_SVG_VIEWBOX_MAP() {
      const json = (await import("~/assets/generated/defs.json")).default
      this.$patch({
        svgViewBoxMap: json,
        svgIdList: Object.keys(json),
        svgSpriteLoaded: true,
      })
    },
  },
})
