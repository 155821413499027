import { Apis } from "./Apis"
import { useAppFetch } from "#imports"
import { axiosBaseURL } from "~/assets/others/constant"
import { useAppStore } from "~/stores/app"

type ApiKey = keyof typeof Apis | String

interface HttpOptions {
  ignoreError?: boolean
  isNotTrim?: boolean
}

// type ApiKey = Extract<keyof typeof Apis, string>
class Http {
  async post<DataType = any>(
    apiKey: ApiKey,
    params?: any,
    headers?: any,
    options?: HttpOptions
  ) {
    const app = useAppStore()
    const lang = computed(()=>{
      return app.language || "en"
    })
    const parameters = isFormData(params)
      ? params
      : params
      ? options?.isNotTrim
        ? { ...params }
        : trim(deleteKey({ ...params }))
      : {}

    type ResponseType = {
      code: number
      data: DataType
      msg: string
      headers: Headers
    }

    if (process.client) {
      const query = {
        ...parameters,
        sign: false,
      }
      const key = btoa(encodeURIComponent(`${apiKey}_${JSON.stringify(parameters)}}`))
      const data = await useAppFetch(apiKey, {
        method: "POST",
        body: isFormData(params) ? params : query,
        headers,
        deep: false,
        key,
        ...options,
      })
      return data as ResponseType
    }
    const query = {
      ...parameters,
      sign: false,
    }
    const url = Apis[apiKey as keyof typeof Apis]
    const responseData = (await $fetch(url, {
      method: "POST",
      baseURL: axiosBaseURL,
      body: isFormData(params) ? params : query,
      headers: {
        "Accept-Device": "m",
        "Accept-Theme": "dark",
        "Accept-Language": lang.value ?? "en"  ,
        "Web-Type": "uni",
      },
    })) as any

    return {
      code: responseData.code,
      data: responseData.data,
      msg: responseData.msg,
    } as ResponseType
  }
  async postServerSide<DataType = any>(
    apiKey: ApiKey,
    params?: any,
    headers?: any,
    options?: HttpOptions
  ) {
    const app = useAppStore()
    const parameters = isFormData(params)
      ? params
      : params
      ? options?.isNotTrim
        ? { ...params }
        : trim(deleteKey({ ...params }))
      : {}
    const query = {
      ...parameters,
      sign: false,
    }
    const url = Apis[apiKey as keyof typeof Apis]

    const data = await $fetch(url, {
      method: "POST",
      baseURL: axiosBaseURL,
      body: isFormData(params) ? params : query,
      headers: {
        "Accept-Device": "m",
        "Accept-Theme": "dark",
        "Accept-Language": app.language ?? "en",
        "Web-Type": "uni",
      },
    })

    return data as DataType
  }
}

const HttpInstance = new Http()

export default HttpInstance
