import { defineStore } from "pinia"
import { ElMessageBox } from "element-plus"
import { useWallet } from "./wallet"
import { useCheckInStore } from "./checkIn"

import {
  isMobileequipment,
  useDialog,
  useFingerprint,
  formatMobile,
} from "#imports"

import Http from "~/request/server"
import type { StoreTypes } from "~/types/store"
import { useMessage } from "~/stores/message"

const { getValue } = useStorage()

const initialValue: StoreTypes.User.State = {
  isLogin: null,
  info: null,
  withdrawConfig: null,
  isPlay: null,
  vipInfo: null, // vip信息
  vipList: null,
  dialogType: "",
  isNavBarOpne: false,
  userKyc: null,
  showFiatBalance: true,
  showZeroBalance: false,
  kycIsVisibleData:[],
}

export const useUserStore = defineStore("user", {
  persist: true,
  state: (): StoreTypes.User.State => {
    return {
      ...initialValue,
      isLogin: !!useToken().getToken(),
      kycIsVisibleData:[],
    }
  },
  getters: {
    // get player vip icon
    USER_VIP_ICON(state) {
      const iconInfo = computed(() => {
        const info = state.info
        const vipList = state.vipList
        return vipList?.find((item) => item.id === info?.levelId)
      })?.value
      return iconInfo
    },
    IS_LOGO_SHOW(): boolean {
      return !this.isNavBarOpne
    },
    GET_UESR_KYC_STATUS_ORIGINAL(state): IKycForm["status"] {
      const __status = Number(
        state.userKyc?.status ?? state.info?.kycStatus ?? -1
      ) as Parameters<typeof determineKycStatus>[0]
      return __status
    },
  },
  actions: {
    GET_UESR_KYC_STATUS_ACTION() {
      const state = this.$state
      const isLogin = state.isLogin
      const __status = Number(
        isLogin ? state?.userKyc?.status ?? 0 : state?.userKyc?.status ?? -1
      ) as Parameters<typeof determineKycStatus>[0]

      const output = determineKycStatus(__status)
      return output
    },
    GET_ORIGINAL_UESR_KYC_STATUS_ACTION() {
      const state = this.$state
      const __status = Number(
        state?.userKyc?.status ?? state?.info?.kycStatus ?? -1
      ) as Parameters<typeof determineKycStatus>[0]
      return __status
    },
    // login
    async LOGIN(params: any) {
      const query = getHybridQuery()
      const deviceIdPromise = useFingerprint().generateFingerprint()
      if (params.mobile) {
        params.mobile = formatMobile(params.mobile)
      }
      const res = await Http.post("login", {
        activityLaunchHelpId: query.activityLaunchHelpId || "",
        ...params,
        deviceId: await deviceIdPromise,
      })
      if (params.verifyPhoneNumber && res.data.mobile !== "") {
        this.$patch({
          info: res.data,
        })
        return res
      }
      await useToken().setToken(res.data.apiToken)
      this.SAVE_PROFILE(res.data)
      useWallet().GET_USER_BALANCE() // 登陆成功请求钱包
      useMessage().GET_NOTICE_COUNT()
      useCheckInStore().CHECK_IN_STORY()

      return res
    },
    // 手机号登录
    async MOBILE_LOGIN(params: any) {
      const query = getHybridQuery()
      try {
        const deviceIdPromise = useFingerprint().generateFingerprint()
        if (params.mobile) {
          params.mobile = formatMobile(params.mobile)
        }
        const res = await Http.post("mobileLogin", {
          activityLaunchHelpId: query.activityLaunchHelpId || "",
          ...params,
          deviceId: await deviceIdPromise,
        })
        await useToken().setToken(res.data.apiToken)
        this.SAVE_PROFILE(res.data)
        useWallet().GET_USER_BALANCE() // 登陆成功请求钱包
        this.CHECK_HASH_STATUS()
        useMessage().GET_NOTICE_COUNT()
        useCheckInStore().CHECK_IN_STORY()
        return Promise.resolve(res)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    // player register
    async REGISTER(params: any) {
      const raffleManager = useRaffleTicketManager()
      const query = getHybridQuery()
      try {
        const deviceIdPromise = useFingerprint().generateFingerprint()
        if (params.mobile) {
          params.mobile = formatMobile(params.mobile)
        }
        const res = await Http.post("register", {
          activityLaunchHelpId: query.activityLaunchHelpId || "",
          ...params,
          deviceId: await deviceIdPromise,
        })
        await useToken().setToken(res.data.apiToken)
        this.SAVE_PROFILE(res.data)
        useWallet().GET_USER_BALANCE() // 请求钱包
        useCheckInStore().CHECK_IN_STORY()
        await raffleManager.createRaffleTicket(EnumRaffleTicket.REG)
        return Promise.resolve(res)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    // save player information without call api
    SAVE_PROFILE(__data: any) {
      const data = {
        ...__data,
        kycStatus: __data.kycStatus || 0,
      }
      this.$patch({
        isLogin: true,
        info: data,
      })
    },
    // get player information
    async GET_USER_PROFILE() {
      if (this.isLogin) {
        const res = await Http.post("profile")
        if (res.code === 0) {
          this.SAVE_PROFILE(res.data)
          useWallet().GET_USER_BALANCE() // 请求钱包
        }
        return res.code
      }
    },
    // player logout
    async LOGOUT(
      customCallbackAfterLogout?: () => void,
      isCallLogoutApi = false
    ) {
      try {
        let isOpen = false
        const isOpenCookies = Number(getValue(__is_sidebar_open))
        if (isOpenCookies === 1) isOpen = true
        else if (isOpenCookies === 0) isOpen = false
        else if (isMobileequipment()) isOpen = false
        else isOpen = true
        await useToken().clearToken()
        this.$patch({
          ...initialValue,
          isLogin: false,
          isNavBarOpne: isOpen,
        })
        useWallet().$patch({
          walletList: null,
        })
      } catch (e) {
        console.log(e)
      } finally {
        if (customCallbackAfterLogout) {
          await customCallbackAfterLogout()
        } else {
          useDialog().closeDialog() // 关闭弹框
          useCheckInStore().UPDATE_STORY("Close") // 重置簽到判斷
          navigateTo({ name: "Home" })
        }
      }
    },

    CHANGE_LOGIN(type: boolean) {
      this.isLogin = type
    },
    // check the player if need the code of google
    async CHECK_NEED_GOOGLE(params = {}) {
      const res = await Http.post("checkOpenGoogleCode", params)
      return res
    },
    // send the verification code of email
    SEND_CODE(params: any) {
      return new Promise((resolve, reject) => {
        resolve(0)
      })
    },
    // 获取用户vip信息
    GET_USER_VIP_INFO() {
      return new Promise((resolve, reject) => {
        Http.post("memberShipDetails")
          .then((res) => {
            if (res.code === 0) this.vipInfo = res.data
            resolve(res.code)
          })
          .catch((error) => reject(error))
      })
    },
    GET_VIP_INFO() {
      return new Promise((resolve, reject) => {
        Http.post("memberShipLevelDetails")
          .then((res) => {
            if (res.code === 0) this.vipList = res.data
            resolve(res.code)
          })
          .catch((error) => reject(error))
      })
    },
    // 发送邮箱验证码
    GET_EMAIL_CODE(params = {}) {
      return new Promise((resolve, reject) => {
        Http.post("sendSesCode", params)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => reject(error))
      })
    },
    // 发送短信验证码
    GET_PHONE_CODE(params: any) {
      return new Promise((resolve, reject) => {
        if (params.mobile) {
          params.mobile = formatMobile(params.mobile)
        }
        Http.post("sendSnsCode", params)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => reject(error))
      })
    },
    // 发送手机验证码
    GET_MOBILE_CODE(params: any) {
      return new Promise((resolve, reject) => {
        if (params.mobile) {
          params.mobile = formatMobile(params.mobile)
        }
        Http.post("sendSmsCode", params)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => reject(error))
      })
    },
    FORGET_PASSWORD(params: {}) {
      return new Promise<any>((resolve, reject) => {
        Http.post("forgotPassword", { ...params }, true)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => reject(error))
      })
    },
    async SET_PASSWORD(params = {}) {
      try {
        const data = await Http.post("setPassword", { ...params })
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async RESET_PASSWORD(params: {}) {
      const res = await Http.post("resetPassword", { ...params })
      return res
    },
    async RESET_MOBILE(params: any) {
      try {
        if (params.mobile) {
          params.mobile = formatMobile(params.mobile)
        }
        const { code } = await Http.post("resetMobile", { ...params })
        return Promise.resolve(code)
      } catch (e) {
        console.log(e)
      }
    },
    GET_SPORT_TOKEN() {
      return new Promise((resolve, reject) => {
        Http.post("getSportToken")
          .then((res) => {
            resolve(res)
          })
          .catch((err) => reject(err))
      })
    },
    // 检查是否存在充值
    async CHECK_DEPOSIT(params: {}) {
      try {
        const res = await Http.post("checkPixDeposit", params)
        return Promise.resolve(res)
      } catch (e) {
        console.log(e)
      }
    },
    // 活动在申请
    APPLY_PROMOTION(params: {}) {
      return new Promise((resolve, reject) => {
        Http.post("promotionsApplyAgain", params)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => reject(err))
      })
    },
    adjustCoin(info: StoreTypes.User.Info) {
      this.$patch({
        info: {
          ...this.info,
          ...info,
        },
      })
    },
    updateInfo(info: StoreTypes.User.Info) {
      this.$patch({
        info: {
          ...this.info,
          ...info,
        },
      })
    },
    // upload
    async UPLOAD(file: File) {
      const form = new FormData()
      form.append("file", file)
      const responseInfo = await Http.post("uploadFile", form, {
        // @ts-ignore
        "content-length": file.length ?? file.size,
      })
      return responseInfo.data
    },
    // updatekyc
    async SET_UPDATE_KYC(data = {}) {
      const responseInfo = await Http.post("setOrUpdateKyc", { ...data })
      return responseInfo
    },
    // getkyc
    async GET_USER_KYC(params = {}) {
      const responseInfo = await Http.post("getUserKyc", { ...params })

      // 解析ocr数据並且刪除callbackJson
      const ocrJsonData = (() => {
        let json
        try {
          json = JSON.parse(responseInfo?.data.callbackJson ?? "null")
        } catch (error) {
          json = null
        }
        const ocrDucument = json?.verification_data?.document ?? {}
        const firstName = ocrDucument?.name?.first_name
        const middleName = ocrDucument?.name?.middle_name
        const lastName = ocrDucument?.name?.last_name
        const birthday = ocrDucument?.dob
        return {
          firstName,
          middleName,
          lastName,
          birthday,
        }
      })()

      delete responseInfo?.data?.callbackJson

      // 保存ocr数据
      const data = {
        ...responseInfo.data,
        ocrJsonData,
      }
      // 保存kyc数据
      this.$patch({
        userKyc: data,
      })
      return {
        data,
      }
    },
    // 三方登录 获取三方地址
    async PROVIDER_LOGIN(data: {}) {
      const responseInfo = await Http.post("thirdAuthUrl", { ...data })
      return responseInfo
    },
    async PROVIDER_BINDING(data: {}) {
      const responseInfo = await Http.post("bindingUrl", { ...data })
      return responseInfo
    },
    //
    async BINDING(data: {}) {
      const responseInfo = await Http.post("binding", { ...data })
      await this.GET_USER_PROFILE()
      return responseInfo
    },
    async InitInfo(data: StoreTypes.User.Info) {
      await useToken().setToken(data.apiToken)
      await this.SAVE_PROFILE(data)
      await useWallet().GET_USER_BALANCE() // 登陆成功请求钱包
    },

    // 三方登录成功 跳转回主页
    async SAVE_PROVIDER_INFO(data: StoreTypes.User.providerLogin) {
      const responseInfo = await Http.post("thirdLogin", { ...data })
      this.InitInfo({ ...responseInfo.data.userInfoResDto })
      return responseInfo
    },
    // 检查hash加密方式，为1时，提示用户修改密码
    CHECK_HASH_STATUS() {
      if (this.info?.isHashB === 1) {
        ElMessageBox.confirm(
          "Due to system security policy upgrade, Please make sure to change your password.",
          "Security Update",
          {
            confirmButtonText: "Now",
            cancelButtonText: "Later",
            type: "warning",
          }
        ).then(() => {
          useDialog().openDialog("changePassword")
        })
      }
    },

    async GET_KYC_VISIBLE(){
      const responseInfo = await Http.post("getKycVisible", { groupName:"kyc_valid" })
      this.$patch({
        kycIsVisibleData:responseInfo.data
      })
      return responseInfo.data
    },

    

    
  },
})
