import { useDeviceSymbolAppFetch } from "./useDeviceSymbol"
import { Apis } from "~/request/Apis"
import { useUserStore } from "~/stores/user"
import { useDialog } from "~/composables/useDialog"
import type { HttpUseFetch } from "~/types/http"
import { AzureCertificate } from "~/utils/constant"
import en from "~/locales/en"
import { axiosBaseURL } from "~/assets/others/constant"
import { useAppStore } from "~/stores/app"

const executeLogout = async () => {
  if (process.client && useToken().getToken()) {
    try {
      await useUserStore().LOGOUT()
      ElMessageBox.confirm(
        "you have been automatically logged out due to inactivity.",
        "Tips",
        {
          showCancelButton: false,
        }
      ).then(() => {
        useDialog().openDialog("PwdSign")
      })
    } catch (e) {
      console.log(e)
    }
  }
}

const delayFunctionLogin = debounce(executeLogout, 1000)

type ApiKey = keyof typeof Apis

type UseFetchResponseType = {
  code: number | string
  msg: string
  data: any
  repCode?: string
}

type UseFetchOptionType = Omit<
  Required<Parameters<typeof useFetch<UseFetchResponseType>>>["1"],
  "method"
> & { method: "POST" | "GET"; ignoreError?: boolean }

const noAuthApis: ApiKey[] = [
  "init",
  "gameList",
  "GameBannerList",
  "getBannerList",
  "searchGameList",
  "getDictionary",
  "thirdLogin",
  "thirdAuthUrl",
  "getCap",
]

const defaultReturnData: HttpUseFetch.Response = {
  msg: "",
  data: null,
  code: -1,
}

export async function useAppFetch(
  apiKey: ApiKey | String,
  options: UseFetchOptionType
) {
  try {
    if (process.server) {
      const requestURL = useRequestURL()
      if (requestURL.pathname.includes("api/doc.html"))
        return { ...defaultReturnData }
    }
    const url = Apis[apiKey as ApiKey]
    const AcceptDevice = useDeviceSymbolAppFetch() ?? ""
    const app = useAppStore()
    const token = useToken().getToken()

    const AuthorizationObject = (() => {
      if (noAuthApis.includes(apiKey as ApiKey)) {
        return {}
      }
      if (useUserStore().isLogin && token) {
        return { Authorization: `Bearer ${token}` }
      }
      return {}
    })() as { Authorization: string }

    const isCustomHeadersAdding = !(["kycGetUrl"] as ApiKey[]).includes(
      apiKey as ApiKey
    )
    const customHeaders = (() =>
      isCustomHeadersAdding
        ? {
            "Accept-Device": AcceptDevice,
            "Accept-Theme": "dark",
            "Accept-Language": app.language ?? "en",
            "Web-Type": "uni",
            "is-server-fetch": process.server ? "yes" : "",
            ...(import.meta.env.DEV ||
            import.meta.env.VITE_APP_ENV_NAME === "dev"
              ? AzureCertificate
              : {}),
          }
        : {})()

    const finalHeaders = {
      ...AuthorizationObject,
      ...customHeaders,
      Accept: "application/json",
      // @ts-ignore
      ...options.headers,
    }
    let headers = null
    await nextTick()
    const { data, error } = await useFetch(url, {
      ...options,
      baseURL: axiosBaseURL,
      headers: {
        ...finalHeaders,
      },
      lazy: true,
      onResponse({ response }) {
        headers = response.headers
      },
    })

    if (error.value) {
      console.error(
        error.value.statusCode,
        error.value.statusMessage,
        error.value.message
      )
      return {
        ...defaultReturnData,
        code: error.value.statusCode,
        msg: error.value.statusMessage || error.value.message,
      } as HttpUseFetch.Response
    }
    const __statusCode = Number(data.value?.code ?? data.value?.repCode ?? 500)

    const statusCode = isNaN(__statusCode) ? 500 : __statusCode
    const message = data.value?.msg ?? "Request Error"
    // 0000 滑块验证码
    if (![70108, 0].includes(statusCode) && !options?.ignoreError) {
      if (statusCode !== 401) {
        if ([400].includes(statusCode)) {
          useNotify({
            position: "top-left",
            title: "Error",
            // @ts-ignore
            message: data.value?.msg || en.errorCode[statusCode],
            type: "error",
          })
        } else if ([24001].includes(statusCode)) {
          useNotify({
            position: "top-left",
            title: "Info",
            // @ts-ignore
            message: en.errorCode[statusCode] ?? data.value?.msg,
            type: "info",
          })
        } else {
          useNotify({
            position: "top-left",
            title: "Error",
            // @ts-ignore
            message: en.errorCode[statusCode] ?? data.value?.msg,
            type: "error",
          })
        }
      }

      // 这里处理错你自定义的错误，例如code !== 1
      if (statusCode === 401 || statusCode === 403) {
        delayFunctionLogin()
      }
      console.log(`Status：${statusCode}, URL：${url}, Msg：${message}`)

      return {
        ...defaultReturnData,
        code: statusCode,
        // @ts-ignore
        msg: en.errorCode[statusCode] ?? data.value?.msg,
      } as HttpUseFetch.Response
    } else {
      return {
        ...defaultReturnData,
        ...data.value,
        headers,
      } as HttpUseFetch.Response
    }
  } catch (error) {
    console.log("useAppFetch", (error as Error).message)
    return defaultReturnData
  }
}
