import { default as _91_46_46_46slug_93eRdf692eseMeta } from "/home/powerbtend-web/pages/[...slug].vue?macro=true";
import { default as indexgeM0iyC80wMeta } from "/home/powerbtend-web/pages/about-us/index.vue?macro=true";
import { default as indexpH1Ml1ie72Meta } from "/home/powerbtend-web/pages/activity/christmas/index.vue?macro=true";
import { default as indexJNUwj26gkvMeta } from "/home/powerbtend-web/pages/activity/christmas/live-broadcast/index.vue?macro=true";
import { default as index3ePuuCwWNFMeta } from "/home/powerbtend-web/pages/activity/christmas/terms/index.vue?macro=true";
import { default as _91id_93CztwDNvS0UMeta } from "/home/powerbtend-web/pages/affiliate/[id].vue?macro=true";
import { default as rulejI9BJgixEpMeta } from "/home/powerbtend-web/pages/affiliate/rule.vue?macro=true";
import { default as indexvJyTQWAf2LMeta } from "/home/powerbtend-web/pages/bingo/index.vue?macro=true";
import { default as _91id_93Soix1JPwy7Meta } from "/home/powerbtend-web/pages/blogs/[id].vue?macro=true";
import { default as indexDcpW6I3pleMeta } from "/home/powerbtend-web/pages/blogs/index.vue?macro=true";
import { default as indexnpdz7FBBViMeta } from "/home/powerbtend-web/pages/chess/index.vue?macro=true";
import { default as index1l9npQm2OhMeta } from "/home/powerbtend-web/pages/favorites/index.vue?macro=true";
import { default as indexfUNtW9c5aVMeta } from "/home/powerbtend-web/pages/fishing/index.vue?macro=true";
import { default as index7lSHWD0VZwMeta } from "/home/powerbtend-web/pages/gameRoom/index.vue?macro=true";
import { default as indexrL5g8a1BsuMeta } from "/home/powerbtend-web/pages/help-center/index.vue?macro=true";
import { default as indexqFKP1bL8GJMeta } from "/home/powerbtend-web/pages/helpActivity/done/index.vue?macro=true";
import { default as index4LMbbZ2j1rMeta } from "/home/powerbtend-web/pages/helpActivity/index.vue?macro=true";
import { default as indexZewZb7LyVSMeta } from "/home/powerbtend-web/pages/index.vue?macro=true";
import { default as indexn5zoxnxCO7Meta } from "/home/powerbtend-web/pages/live/index.vue?macro=true";
import { default as index28x17hph8MMeta } from "/home/powerbtend-web/pages/lottery/index.vue?macro=true";
import { default as accountUQhS5xlZJXMeta } from "/home/powerbtend-web/pages/my/account.vue?macro=true";
import { default as accountHistoryaPI0QH4cl1Meta } from "/home/powerbtend-web/pages/my/accountHistory.vue?macro=true";
import { default as activityRecordFSuiPpTbjXMeta } from "/home/powerbtend-web/pages/my/activityRecord.vue?macro=true";
import { default as betRecord2cNld9c3vfMeta } from "/home/powerbtend-web/pages/my/betRecord.vue?macro=true";
import { default as depositVoml4v8niCMeta } from "/home/powerbtend-web/pages/my/deposit.vue?macro=true";
import { default as _91id_93jf2AxRynioMeta } from "/home/powerbtend-web/pages/my/information/[category]/index/[id].vue?macro=true";
import { default as listf922uoTHSGMeta } from "/home/powerbtend-web/pages/my/information/[category]/index/list.vue?macro=true";
import { default as indexeovC0OzZohMeta } from "/home/powerbtend-web/pages/my/information/[category]/index.vue?macro=true";
import { default as raffleTicketwmCKBI2tX8Meta } from "/home/powerbtend-web/pages/my/raffleTicket.vue?macro=true";
import { default as swapJmiVJXm6CzMeta } from "/home/powerbtend-web/pages/my/swap.vue?macro=true";
import { default as transfernWJSCu8UrTMeta } from "/home/powerbtend-web/pages/my/transfer.vue?macro=true";
import { default as walletTp4vxFzUdEMeta } from "/home/powerbtend-web/pages/my/wallet.vue?macro=true";
import { default as withdrawfe1vu0jH6DMeta } from "/home/powerbtend-web/pages/my/withdraw.vue?macro=true";
import { default as my4yYDSysysYMeta } from "/home/powerbtend-web/pages/my.vue?macro=true";
import { default as indexruGG0AHnbsMeta } from "/home/powerbtend-web/pages/originals/index.vue?macro=true";
import { default as _91id_93U2Ed0vGReSMeta } from "/home/powerbtend-web/pages/promotionInfo/[id].vue?macro=true";
import { default as indexJ2wT0KsmMiMeta } from "/home/powerbtend-web/pages/promotions/index.vue?macro=true";
import { default as indexB87jdFVbxQMeta } from "/home/powerbtend-web/pages/recent/index.vue?macro=true";
import { default as indexWvUZkAOQB5Meta } from "/home/powerbtend-web/pages/responsible-gambling/index.vue?macro=true";
import { default as index6321AIlzdHMeta } from "/home/powerbtend-web/pages/slots/index.vue?macro=true";
import { default as indexa9NBJ6ZiZjMeta } from "/home/powerbtend-web/pages/sports/index.vue?macro=true";
import { default as indexvvPd7nGycgMeta } from "/home/powerbtend-web/pages/staking/index.vue?macro=true";
import { default as indexZkAdlB5VG6Meta } from "/home/powerbtend-web/pages/table/index.vue?macro=true";
import { default as indexuMtnbQmwnsMeta } from "/home/powerbtend-web/pages/terms/index.vue?macro=true";
import { default as indexpdDkHuqr7VMeta } from "/home/powerbtend-web/pages/vipClub/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93eRdf692eseMeta?.name ?? "slug",
    path: _91_46_46_46slug_93eRdf692eseMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93eRdf692eseMeta || {},
    alias: _91_46_46_46slug_93eRdf692eseMeta?.alias || [],
    redirect: _91_46_46_46slug_93eRdf692eseMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexgeM0iyC80wMeta?.name ?? "about-us",
    path: indexgeM0iyC80wMeta?.path ?? "/about-us",
    meta: indexgeM0iyC80wMeta || {},
    alias: indexgeM0iyC80wMeta?.alias || [],
    redirect: indexgeM0iyC80wMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexpH1Ml1ie72Meta?.name ?? "activity-christmas",
    path: indexpH1Ml1ie72Meta?.path ?? "/activity/christmas",
    meta: indexpH1Ml1ie72Meta || {},
    alias: indexpH1Ml1ie72Meta?.alias || [],
    redirect: indexpH1Ml1ie72Meta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/activity/christmas/index.vue").then(m => m.default || m)
  },
  {
    name: indexJNUwj26gkvMeta?.name ?? "activity-christmas-live-broadcast",
    path: indexJNUwj26gkvMeta?.path ?? "/activity/christmas/live-broadcast",
    meta: indexJNUwj26gkvMeta || {},
    alias: indexJNUwj26gkvMeta?.alias || [],
    redirect: indexJNUwj26gkvMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/activity/christmas/live-broadcast/index.vue").then(m => m.default || m)
  },
  {
    name: index3ePuuCwWNFMeta?.name ?? "activity-christmas-terms",
    path: index3ePuuCwWNFMeta?.path ?? "/activity/christmas/terms",
    meta: index3ePuuCwWNFMeta || {},
    alias: index3ePuuCwWNFMeta?.alias || [],
    redirect: index3ePuuCwWNFMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/activity/christmas/terms/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CztwDNvS0UMeta?.name ?? "affiliate-id",
    path: _91id_93CztwDNvS0UMeta?.path ?? "/affiliate/:id()",
    meta: _91id_93CztwDNvS0UMeta || {},
    alias: _91id_93CztwDNvS0UMeta?.alias || [],
    redirect: _91id_93CztwDNvS0UMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/affiliate/[id].vue").then(m => m.default || m)
  },
  {
    name: rulejI9BJgixEpMeta?.name ?? "affiliate-rule",
    path: rulejI9BJgixEpMeta?.path ?? "/affiliate/rule",
    meta: rulejI9BJgixEpMeta || {},
    alias: rulejI9BJgixEpMeta?.alias || [],
    redirect: rulejI9BJgixEpMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/affiliate/rule.vue").then(m => m.default || m)
  },
  {
    name: indexvJyTQWAf2LMeta?.name ?? "bingo",
    path: indexvJyTQWAf2LMeta?.path ?? "/bingo",
    meta: indexvJyTQWAf2LMeta || {},
    alias: indexvJyTQWAf2LMeta?.alias || [],
    redirect: indexvJyTQWAf2LMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/bingo/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Soix1JPwy7Meta?.name ?? "blogs-id",
    path: _91id_93Soix1JPwy7Meta?.path ?? "/blogs/:id()",
    meta: _91id_93Soix1JPwy7Meta || {},
    alias: _91id_93Soix1JPwy7Meta?.alias || [],
    redirect: _91id_93Soix1JPwy7Meta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/blogs/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDcpW6I3pleMeta?.name ?? "blogs",
    path: indexDcpW6I3pleMeta?.path ?? "/blogs",
    meta: indexDcpW6I3pleMeta || {},
    alias: indexDcpW6I3pleMeta?.alias || [],
    redirect: indexDcpW6I3pleMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: indexnpdz7FBBViMeta?.name ?? "chess",
    path: indexnpdz7FBBViMeta?.path ?? "/chess",
    meta: indexnpdz7FBBViMeta || {},
    alias: indexnpdz7FBBViMeta?.alias || [],
    redirect: indexnpdz7FBBViMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/chess/index.vue").then(m => m.default || m)
  },
  {
    name: index1l9npQm2OhMeta?.name ?? "favorites",
    path: index1l9npQm2OhMeta?.path ?? "/favorites",
    meta: index1l9npQm2OhMeta || {},
    alias: index1l9npQm2OhMeta?.alias || [],
    redirect: index1l9npQm2OhMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: indexfUNtW9c5aVMeta?.name ?? "fishing",
    path: indexfUNtW9c5aVMeta?.path ?? "/fishing",
    meta: indexfUNtW9c5aVMeta || {},
    alias: indexfUNtW9c5aVMeta?.alias || [],
    redirect: indexfUNtW9c5aVMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/fishing/index.vue").then(m => m.default || m)
  },
  {
    name: index7lSHWD0VZwMeta?.name ?? "gameRoom",
    path: index7lSHWD0VZwMeta?.path ?? "/gameRoom",
    meta: index7lSHWD0VZwMeta || {},
    alias: index7lSHWD0VZwMeta?.alias || [],
    redirect: index7lSHWD0VZwMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/gameRoom/index.vue").then(m => m.default || m)
  },
  {
    name: indexrL5g8a1BsuMeta?.name ?? "help-center",
    path: indexrL5g8a1BsuMeta?.path ?? "/help-center",
    meta: indexrL5g8a1BsuMeta || {},
    alias: indexrL5g8a1BsuMeta?.alias || [],
    redirect: indexrL5g8a1BsuMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexqFKP1bL8GJMeta?.name ?? "helpActivity-done",
    path: indexqFKP1bL8GJMeta?.path ?? "/helpActivity/done",
    meta: indexqFKP1bL8GJMeta || {},
    alias: indexqFKP1bL8GJMeta?.alias || [],
    redirect: indexqFKP1bL8GJMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/helpActivity/done/index.vue").then(m => m.default || m)
  },
  {
    name: index4LMbbZ2j1rMeta?.name ?? "helpActivity",
    path: index4LMbbZ2j1rMeta?.path ?? "/helpActivity",
    meta: index4LMbbZ2j1rMeta || {},
    alias: index4LMbbZ2j1rMeta?.alias || [],
    redirect: index4LMbbZ2j1rMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/helpActivity/index.vue").then(m => m.default || m)
  },
  {
    name: indexZewZb7LyVSMeta?.name ?? "index",
    path: indexZewZb7LyVSMeta?.path ?? "/",
    meta: indexZewZb7LyVSMeta || {},
    alias: indexZewZb7LyVSMeta?.alias || [],
    redirect: indexZewZb7LyVSMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexn5zoxnxCO7Meta?.name ?? "live",
    path: indexn5zoxnxCO7Meta?.path ?? "/live",
    meta: indexn5zoxnxCO7Meta || {},
    alias: indexn5zoxnxCO7Meta?.alias || [],
    redirect: indexn5zoxnxCO7Meta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/live/index.vue").then(m => m.default || m)
  },
  {
    name: index28x17hph8MMeta?.name ?? "lottery",
    path: index28x17hph8MMeta?.path ?? "/lottery",
    meta: index28x17hph8MMeta || {},
    alias: index28x17hph8MMeta?.alias || [],
    redirect: index28x17hph8MMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/lottery/index.vue").then(m => m.default || m)
  },
  {
    name: my4yYDSysysYMeta?.name ?? "my",
    path: my4yYDSysysYMeta?.path ?? "/my",
    children: [
  {
    name: accountUQhS5xlZJXMeta?.name ?? "my-account",
    path: accountUQhS5xlZJXMeta?.path ?? "account",
    meta: accountUQhS5xlZJXMeta || {},
    alias: accountUQhS5xlZJXMeta?.alias || [],
    redirect: accountUQhS5xlZJXMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my/account.vue").then(m => m.default || m)
  },
  {
    name: accountHistoryaPI0QH4cl1Meta?.name ?? "my-accountHistory",
    path: accountHistoryaPI0QH4cl1Meta?.path ?? "accountHistory",
    meta: accountHistoryaPI0QH4cl1Meta || {},
    alias: accountHistoryaPI0QH4cl1Meta?.alias || [],
    redirect: accountHistoryaPI0QH4cl1Meta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my/accountHistory.vue").then(m => m.default || m)
  },
  {
    name: activityRecordFSuiPpTbjXMeta?.name ?? "my-activityRecord",
    path: activityRecordFSuiPpTbjXMeta?.path ?? "activityRecord",
    meta: activityRecordFSuiPpTbjXMeta || {},
    alias: activityRecordFSuiPpTbjXMeta?.alias || [],
    redirect: activityRecordFSuiPpTbjXMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my/activityRecord.vue").then(m => m.default || m)
  },
  {
    name: betRecord2cNld9c3vfMeta?.name ?? "my-betRecord",
    path: betRecord2cNld9c3vfMeta?.path ?? "betRecord",
    meta: betRecord2cNld9c3vfMeta || {},
    alias: betRecord2cNld9c3vfMeta?.alias || [],
    redirect: betRecord2cNld9c3vfMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my/betRecord.vue").then(m => m.default || m)
  },
  {
    name: depositVoml4v8niCMeta?.name ?? "my-deposit",
    path: depositVoml4v8niCMeta?.path ?? "deposit",
    meta: depositVoml4v8niCMeta || {},
    alias: depositVoml4v8niCMeta?.alias || [],
    redirect: depositVoml4v8niCMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my/deposit.vue").then(m => m.default || m)
  },
  {
    name: indexeovC0OzZohMeta?.name ?? "my-information-category",
    path: indexeovC0OzZohMeta?.path ?? "information/:category()",
    children: [
  {
    name: _91id_93jf2AxRynioMeta?.name ?? "my-information-category-index-id",
    path: _91id_93jf2AxRynioMeta?.path ?? ":id()",
    meta: _91id_93jf2AxRynioMeta || {},
    alias: _91id_93jf2AxRynioMeta?.alias || [],
    redirect: _91id_93jf2AxRynioMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my/information/[category]/index/[id].vue").then(m => m.default || m)
  },
  {
    name: listf922uoTHSGMeta?.name ?? "my-information-category-index-list",
    path: listf922uoTHSGMeta?.path ?? "list",
    meta: listf922uoTHSGMeta || {},
    alias: listf922uoTHSGMeta?.alias || [],
    redirect: listf922uoTHSGMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my/information/[category]/index/list.vue").then(m => m.default || m)
  }
],
    meta: indexeovC0OzZohMeta || {},
    alias: indexeovC0OzZohMeta?.alias || [],
    redirect: indexeovC0OzZohMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my/information/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: raffleTicketwmCKBI2tX8Meta?.name ?? "my-raffleTicket",
    path: raffleTicketwmCKBI2tX8Meta?.path ?? "raffleTicket",
    meta: raffleTicketwmCKBI2tX8Meta || {},
    alias: raffleTicketwmCKBI2tX8Meta?.alias || [],
    redirect: raffleTicketwmCKBI2tX8Meta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my/raffleTicket.vue").then(m => m.default || m)
  },
  {
    name: swapJmiVJXm6CzMeta?.name ?? "my-swap",
    path: swapJmiVJXm6CzMeta?.path ?? "swap",
    meta: swapJmiVJXm6CzMeta || {},
    alias: swapJmiVJXm6CzMeta?.alias || [],
    redirect: swapJmiVJXm6CzMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my/swap.vue").then(m => m.default || m)
  },
  {
    name: transfernWJSCu8UrTMeta?.name ?? "my-transfer",
    path: transfernWJSCu8UrTMeta?.path ?? "transfer",
    meta: transfernWJSCu8UrTMeta || {},
    alias: transfernWJSCu8UrTMeta?.alias || [],
    redirect: transfernWJSCu8UrTMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my/transfer.vue").then(m => m.default || m)
  },
  {
    name: walletTp4vxFzUdEMeta?.name ?? "my-wallet",
    path: walletTp4vxFzUdEMeta?.path ?? "wallet",
    meta: walletTp4vxFzUdEMeta || {},
    alias: walletTp4vxFzUdEMeta?.alias || [],
    redirect: walletTp4vxFzUdEMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my/wallet.vue").then(m => m.default || m)
  },
  {
    name: withdrawfe1vu0jH6DMeta?.name ?? "my-withdraw",
    path: withdrawfe1vu0jH6DMeta?.path ?? "withdraw",
    meta: withdrawfe1vu0jH6DMeta || {},
    alias: withdrawfe1vu0jH6DMeta?.alias || [],
    redirect: withdrawfe1vu0jH6DMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my/withdraw.vue").then(m => m.default || m)
  }
],
    meta: my4yYDSysysYMeta || {},
    alias: my4yYDSysysYMeta?.alias || [],
    redirect: my4yYDSysysYMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/my.vue").then(m => m.default || m)
  },
  {
    name: indexruGG0AHnbsMeta?.name ?? "originals",
    path: indexruGG0AHnbsMeta?.path ?? "/originals",
    meta: indexruGG0AHnbsMeta || {},
    alias: indexruGG0AHnbsMeta?.alias || [],
    redirect: indexruGG0AHnbsMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/originals/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93U2Ed0vGReSMeta?.name ?? "promotionInfo-id",
    path: _91id_93U2Ed0vGReSMeta?.path ?? "/promotionInfo/:id()",
    meta: _91id_93U2Ed0vGReSMeta || {},
    alias: _91id_93U2Ed0vGReSMeta?.alias || [],
    redirect: _91id_93U2Ed0vGReSMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/promotionInfo/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJ2wT0KsmMiMeta?.name ?? "promotions",
    path: indexJ2wT0KsmMiMeta?.path ?? "/promotions",
    meta: indexJ2wT0KsmMiMeta || {},
    alias: indexJ2wT0KsmMiMeta?.alias || [],
    redirect: indexJ2wT0KsmMiMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/promotions/index.vue").then(m => m.default || m)
  },
  {
    name: indexB87jdFVbxQMeta?.name ?? "recent",
    path: indexB87jdFVbxQMeta?.path ?? "/recent",
    meta: indexB87jdFVbxQMeta || {},
    alias: indexB87jdFVbxQMeta?.alias || [],
    redirect: indexB87jdFVbxQMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/recent/index.vue").then(m => m.default || m)
  },
  {
    name: indexWvUZkAOQB5Meta?.name ?? "responsible-gambling",
    path: indexWvUZkAOQB5Meta?.path ?? "/responsible-gambling",
    meta: indexWvUZkAOQB5Meta || {},
    alias: indexWvUZkAOQB5Meta?.alias || [],
    redirect: indexWvUZkAOQB5Meta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/responsible-gambling/index.vue").then(m => m.default || m)
  },
  {
    name: index6321AIlzdHMeta?.name ?? "slots",
    path: index6321AIlzdHMeta?.path ?? "/slots",
    meta: index6321AIlzdHMeta || {},
    alias: index6321AIlzdHMeta?.alias || [],
    redirect: index6321AIlzdHMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/slots/index.vue").then(m => m.default || m)
  },
  {
    name: indexa9NBJ6ZiZjMeta?.name ?? "sports",
    path: indexa9NBJ6ZiZjMeta?.path ?? "/sports",
    meta: indexa9NBJ6ZiZjMeta || {},
    alias: indexa9NBJ6ZiZjMeta?.alias || [],
    redirect: indexa9NBJ6ZiZjMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: indexvvPd7nGycgMeta?.name ?? "staking",
    path: indexvvPd7nGycgMeta?.path ?? "/staking",
    meta: indexvvPd7nGycgMeta || {},
    alias: indexvvPd7nGycgMeta?.alias || [],
    redirect: indexvvPd7nGycgMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/staking/index.vue").then(m => m.default || m)
  },
  {
    name: indexZkAdlB5VG6Meta?.name ?? "table",
    path: indexZkAdlB5VG6Meta?.path ?? "/table",
    meta: indexZkAdlB5VG6Meta || {},
    alias: indexZkAdlB5VG6Meta?.alias || [],
    redirect: indexZkAdlB5VG6Meta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/table/index.vue").then(m => m.default || m)
  },
  {
    name: indexuMtnbQmwnsMeta?.name ?? "terms",
    path: indexuMtnbQmwnsMeta?.path ?? "/terms",
    meta: indexuMtnbQmwnsMeta || {},
    alias: indexuMtnbQmwnsMeta?.alias || [],
    redirect: indexuMtnbQmwnsMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: indexpdDkHuqr7VMeta?.name ?? "vipClub",
    path: indexpdDkHuqr7VMeta?.path ?? "/vipClub",
    meta: indexpdDkHuqr7VMeta || {},
    alias: indexpdDkHuqr7VMeta?.alias || [],
    redirect: indexpdDkHuqr7VMeta?.redirect || undefined,
    component: () => import("/home/powerbtend-web/pages/vipClub/index.vue").then(m => m.default || m)
  }
]