<script lang="ts" setup>
import type { ITheme } from "./utils/theme"
import { useHomeStore } from "~/stores/home"
import { NuxtPage } from "#components"
import { useAppStore } from "~/stores/app"
import 'element-plus/theme-chalk/dark/css-vars.css'
const i18n = useI18n()
const home = useHomeStore()
const app = useAppStore()
const searchFocus = computed(() => home.searchFocus)
const theme: ITheme = "dark"
const layoutName = useLayoutSymbol()
const router = useRouter()
const pageKey = computed(() => `page-key-${router.currentRoute.value.name}`)
watch(
  () => app.language,
  (language) => {
    i18n.setLocale(language as string)
  },
  {
    immediate: true,
  }
)
</script>
<template>
  <Html
    :class="[`${theme === 'dark' ? 'dark' : 'light'}`]"
    :lang="app.language"
  >
    <Body
      class="transition-colors antialiased duration-300 transition-colors text-gray-800 dark:text-gray-200 bg-white h-full dark:bg-gray-900 overflow-x-hidden"
      :class="{ 'overflow-hidden': searchFocus }"
    >
      <NuxtLayout :name="layoutName">
        <NuxtPage :key="pageKey" :page-key="pageKey" transition keepalive />
      </NuxtLayout>
    </Body>
  </Html>
</template>
<style lang="scss" scoped>
.transition-colors {
  background: var(--v-m);
}

::-webkit-scrollbar {
  display: none;
}
</style>
