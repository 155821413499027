import { Dayjs } from "dayjs"
import { useAppStore } from "~/stores/app"
import { useUserStore } from "~/stores/user"
import { EnumKycStatus } from "~/types/enum"

export const aiKycSwitchStatusSync = computed(() => {
  const appStore = useAppStore()
  return Boolean(
    Number(appStore.getConfigByTitle("kyc_switch")?.value ?? 1) ?? 1
  )
})
export const getAiKycSwitchStatus = async () => {
  const userStore = useUserStore()
  const appStore = useAppStore()
    await userStore.GET_KYC_VISIBLE()
    await appStore.GET_CONFIG()
  return aiKycSwitchStatusSync.value
}

export const getHybridQuery = () => {
  return Object.fromEntries(useRequestURL().searchParams.entries())
}

const __dayjs = dayjsUtilManila
export const useCountDownTimer = (
  key: "DepositLockCountDownTimer",
  options: {
    onEnd?: () => void
  } = {}
) => {
  const stateEndTime = useState<number>(`${key}:EndTime`, () => Date.now())
  const stateCountDown = useState<number>(`${key}:CountDown`, () => 0)
  const stateInterval = useState<NodeJS.Timeout | null>(
    `${key}:Interval`,
    () => null
  )

  function setStateEndTime(__time: number) {
    stateEndTime.value = __dayjs().add(__time, "seconds").valueOf()
  }

  function setStateCountDown(__time: number) {
    const leftSeconds = __dayjs(stateEndTime.value).diff(__dayjs(), "seconds")
    stateCountDown.value = leftSeconds
  }
  function setStateInterval() {
    if (stateInterval.value !== null) {
      clearInterval(stateInterval.value)
      stateInterval.value = null
    }
    if (stateInterval.value === null) {
      stateInterval.value = setInterval(() => {
        setStateCountDown(stateEndTime.value)
        if (stateCountDown.value <= 0) {
          // @ts-ignore
          clearInterval(stateInterval.value)
          stateInterval.value = null
          options.onEnd?.()
        }
      }, 500)
    }
  }

  function reset(__time: number) {
    setStateEndTime(__time)
    setStateCountDown(__time)
    setStateInterval()
  }
  const countDownSeconds = computed(() => {
    return (stateCountDown.value ?? 0) > 0 ? stateCountDown.value : 0
  })
  return {
    reset,
    countDownSeconds,
  }
}

export const openKycAfterAuth = async () => {
  const userStore = useUserStore()
  await userStore.GET_USER_KYC()
  const kycStatus = useKycStatus()
  if (
    ![
      //
      EnumKycStatus.CSR_APPROVED,
      EnumKycStatus.BLOCKED,
      EnumKycStatus.COMPLETED,
      EnumKycStatus.REVERIFY,
      EnumKycStatus.CERTIFY,
    ].includes(kycStatus.value)
  ) {
    const dialog = useDialog()
    dialog.openDialog("kycSetting")
    return true
  } else {
    return false
  }
}
