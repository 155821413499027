export function useDeviceSymbol() {
  if (process.server) {
    const event = useRequestEvent()
    const headers = event.node.req.headers
    const userAgent = headers["user-agent"]

    if (!userAgent) {
      return "m"
    }

    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]
    return toMatch.some((toMatchItem) => userAgent.match(toMatchItem))
      ? "m"
      : "d"
  }

  const { width } = useWindowSize()

  return width.value < 768 ? "m" : "d"
}

export function useDeviceSymbolAppFetch() {
  if (process.server) {
    const event = useRequestEvent()
    const userAgent =
      event.headers.get("user-agent") || event.headers.get("User-Agent")

    if (!userAgent) {
      return undefined
    }

    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]
    return toMatch.some((toMatchItem) => userAgent.match(toMatchItem))
      ? "m"
      : "d"
  }

  const { width } = useWindowSize()
  return width.value < 768 ? "m" : "d"
}
