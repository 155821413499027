import { defineStore } from "pinia"
import HttpInstance from "~/request/server"
import type { StoreTypes } from "~/types/store"

export const useGame = defineStore("game", {
  state: (): StoreTypes.Game => ({
    collectionList: [],
    slotGameList: [],
    bannerList: [],
    endingInfo: {}, // 游戏信息
    endingList: [], // ending游戏列表
    gameBannerList: [],
    gameTypeList: [],
  }),
  getters: {},
  actions: {
    async GAME_COLLECTION(params = {}) {
      const { code } = await HttpInstance.post("gameFavorite", params)
      return code
    },
    async GET_COLLECTION_LIST() {
      const params = {
        current: 1,
        size: 100,
        data: {
          category: 3,
          name: "",
        },
      }
      const res = await HttpInstance.post("slotGameList", params)
      if (res.code === 0) {
        this.$patch({
          collectionList: res.data?.list ?? [],
        })
      }
      return res
    },
    async START_GAME(params: any): Promise<any> {
      try {
        const res = await HttpInstance.post("startGame", params)
        return res
      } catch (e) {
        return e
      }
    },
    async GET_GAME_INFO(params: {
      /**
       * 游戏分类ID
       */
      gameProviderSubtypeId: number
      /**
       * 游戏ID(某个游戏-没有则不传)
       */
      id: String
      /**
       * 1:真錢 2:試玩
       */
      playMode: 1 | 2
    }) {
      const res = await HttpInstance.post("details", params)
      return res
    },
    // 获取游戏banner
    async GET_GAME_BANNER_LIST(params = {}) {
      const res = await HttpInstance.post("GameBannerList", params)
      this.$patch({
        gameBannerList: res.data ?? [],
      })
      return res
    },
    // 获取ending游戏信息 判定首页是否展示
    async GET_ENDING() {
      const params = {
        current: 1,
        data: { category: 0, ids: [101] },
        size: 1,
      }
      const res = await HttpInstance.post("slotGameList", params)
      this.$patch({
        endingInfo: res.data?.list?.[0] || {},
      })
      return res
    },
    // 获取ending推荐游戏
    async GET_ENDING_RECOMMEND() {
      try {
        const res = await HttpInstance.post("endingRecommend")
        this.$patch({
          endingList: res.data || [],
        })
        return res
      } catch (e) {
        return e
      }
    },
    async GET_SLOT_GAME_LIST(
      paramData: Partial<SpaceGameRelated.SlotGameList.ParamsData["data"]> = {}
    ) {
      const params = {
        current: 1,
        size: 100000,
        data: {
          ...paramData,
        },
      }
      const res = await HttpInstance.post("slotGameList", params)
      const list = res.data?.list ?? []
      if (res.code === 0) {
        this.$patch({
          slotGameList: list,
        })
      }
      return list as SpaceGameRelated.SlotGameList.GameItem[]
    },
    async GET_GAME_TYPE_LIST() {
      const res = await HttpInstance.post("gameTypeList")
      const list = res.data || []
      const listFixed = list.map((item: any) => {
        return {
          ...item,
          sort: item.sort ?? 0,
        }
      })
      this.$patch({
        gameTypeList: listFixed,
      })
      return listFixed
    },
  },
})
