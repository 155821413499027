import HttpInstance from "~/request/server"
import { useGame } from "~/stores/game"
import { StoreTypes } from "~/types/store"

const state = reactive<{
  gameCategoriesMenu: {
    routeName: string
    lable: ComputedRef<string>
    icon: string
    gameTypes: number
    recommendedStatus?: number
  }[]
  hiddenGameCategoriesMenu: string[]
}>({
  gameCategoriesMenu: [],
  hiddenGameCategoriesMenu: [],
})

export { state as gameCategoriesMenuState }

type GameCategoriesType = {
  routeName: RouteName
  lable: `menu.gameCategories.${string}`
  icon: `icons-${string}`
  gameTypes: StoreTypes.GameType["id"]
  sort?: StoreTypes.GameType["sort"]
  status?: StoreTypes.GameType["status"]
}

const allCategories: GameCategoriesType[] = [
  {
    routeName: "Sports",
    lable: "menu.gameCategories.sports",
    icon: "icons-sliderbar-sports",
    gameTypes: 1,
  },
  {
    routeName: "Bingo",
    lable: "menu.gameCategories.bingo",
    icon: "icons-sliderbar-bingo",
    gameTypes: 12,
  },
  {
    routeName: "Slots",
    lable: "menu.gameCategories.slots",
    icon: "icons-sliderbar-slot-game",
    gameTypes: 2,
  },
  {
    routeName: "Fishing",
    lable: "menu.gameCategories.fishing",
    icon: "icons-sliderbar-fishing",
    gameTypes: 4,
  },
  {
    routeName: "Live",
    lable: "menu.gameCategories.live",
    icon: "icons-sliderbar-live-casino",
    gameTypes: 3,
  },
  {
    routeName: "Chess",
    lable: "menu.gameCategories.chess",
    icon: "icons-sliderbar-chess",
    gameTypes: 5,
  },
  {
    routeName: "Table",
    lable: "menu.gameCategories.table",
    icon: "icons-sliderbar-table-game",
    gameTypes: 11,
  },
  {
    routeName: "Lottery",
    lable: "menu.gameCategories.lottery",
    icon: "icons-sliderbar-lottery",
    gameTypes: 8,
  },
]
export const useGameCategoriesMenu = () => {
  const getGameCategoriesMenu = async () => {
    const gameStore = useGame()
    const resGameTypeListProm = HttpInstance[
      process.client ? "post" : "postServerSide"
    ]("gameTypeList").then((response) => {
      const list = (response.data || []) as StoreTypes.GameType[]
      const listFixed = list.map((item: any) => {
        return {
          ...item,
          sort: item.sort ?? 0,
        }
      })
      gameStore.$patch({
        gameTypeList: listFixed,
      })
      return listFixed
    })
    const resSlotGameListProm = HttpInstance[
      process.client ? "post" : "postServerSide"
    ]("slotGameList", {
      current: 1,
      size: 100000,
      data: {},
    }).then((response) => {
      const list = (response.data?.list ??
        []) as SpaceGameRelated.SlotGameList.GameItem[]
      gameStore.$patch({
        slotGameList: list,
      })
      return list
    })
    const resGameTypeList = await resGameTypeListProm

    const gameTypeListFiltered = resGameTypeList
      .filter((item) => {
        return !!Number(item.display)
      })
      .sort((a, b) => {
        if (a.sort === b.sort) {
          return b.updatedAt - a.updatedAt
        } else {
          return b.sort - a.sort
        }
      })
    const resSlotGameList = await resSlotGameListProm
    const slotGameListFilteredStatus = Array.from(
      new Set(
        resSlotGameList
          ?.filter((item: SpaceGameRelated.SlotGameList.GameItem) =>
            [1, 2].includes(item.status)
          )
          ?.map((item: SpaceGameRelated.SlotGameList.GameItem) =>
            Number(item.gameTypeId)
          ) ?? []
      )
    )
    const menuToShow = (() => {
      if (gameTypeListFiltered.length) {
        const currentMent = gameTypeListFiltered
          .filter((item) =>
            slotGameListFilteredStatus.includes(Number(item.id))
          )
          .map((__item) => {
            const cate = ((): GameCategoriesType | null => {
              return (
                allCategories.find(
                  (item) => item.gameTypes === Number(__item.id)
                ) ?? null
              )
            })()
            if (cate === null) return null
            const item = {
              routeName: cate.routeName,
              icon: cate.icon,
              lable: computed(() => {
                const { t } = useI18n()
                return t(cate.lable)
              }),
              gameTypes: cate.gameTypes,
            }
            return item
          })
          .filter(Boolean)
        state.hiddenGameCategoriesMenu = allCategories
          .filter((item) => {
            const currentId = currentMent.map((_item) => _item?.gameTypes)
            return !currentId.includes(item.gameTypes)
          })
          .map((item) => item.routeName)
        return currentMent
      }
      state.hiddenGameCategoriesMenu = allCategories.map(
        (item) => item.routeName
      )
      return []
    })()
    // 暫時需求是寫死"Live"是NEW,Slots是hot
    const modifiedGameMenu = menuToShow.map((game) => {
      if (game && [""].includes(game.routeName)) {
        // 如果 game 不為 null 且 routeName 為 "Fishing"，添加 recommendedStatus 屬性為 1
        return { ...game, recommendedStatus: 1 }
      } else if (game && ["Slots"].includes(game.routeName)) {
        // 如果 game 不為 null 且 routeName 為 "Slots"，添加 recommendedStatus 屬性為 2
        return { ...game, recommendedStatus: 2 }
      } else {
        return { ...(game || {}), recommendedStatus: 0 }
      }
    })
    // console.log(modifiedGameMenu)
    // @ts-ignore
    state.gameCategoriesMenu = [...modifiedGameMenu]
  }

  return { getGameCategoriesMenu }
}
