// get player balance of wallet
import { defineStore } from "pinia"
import { dayjs } from "element-plus"
import { useUserStore } from "./user"
import Http from "~/request/server"
import type { StoreTypes } from "~/types/store"

export const useWallet = defineStore("wallet", {
  state: (): StoreTypes.Wallet => ({
    walletList: null, // 钱包列表
    platsList: [], // 存款渠道列表
    platAddress: "test", // coin充值钱包地址
    channelList: [],
    cryptoList: [], // 地址添加 提现通道
    depositList: [], // 充值通道地址
    withdrawChannelList: [], // 提款地址添加列表
    withdrawDetail: null,
    coinList: [],
    cryptoAddressList: [],
  }),
  getters: {
    // player wallet information
    MAIN_WALLET(state) {
      const info = useUserStore().info
      return {
        reelCoin: info?.coin,
        mainCurrency: state.walletList?.mainCurrency,
      }
    },
  },
  actions: {
    async GET_USER_BALANCE() {
      const res = await Http.post("getCurrencyCoin")
      this.$patch({
        walletList: res.data,
      })
    },
    async HAS_BIND_FIAT_WALLET() {
      const res = await Http.post("hasBindFiatWallet")
      return res
    },
    async BIND_FIAT_WALLET(params = {}) {
      const res = await Http.post("bindFiatWallet", params)
      return res
    },
    async GET_COIN_LIST(params = {}) {
      const res = await Http.post("getCoinList", params)
      this.$patch({
        coinList: res.data,
      })
    },
    async GET_ONLY_USER_BALANCE() {
      const res = await Http.post("getUserWalletCoin")
      this.$patch({
        walletList: {
          ...this.walletList,
          reelCoin: res.data?.reelCoin ?? "0.00",
        },
      })
    },
    async GET_USER_CRYPTO_ADDRESS(params = {}) {
      const res = await Http.post("getCryptoAddress", params)
      console.log(res)
      this.$patch({
        cryptoAddressList: res.data,
      })
    },
    GET_DEPOSIT_PLATS(params = {}) {
      return new Promise((resolve, reject) => {
        Http.post("getDepositPlats", params).then((res) => {
          if (res.code === 1) {
            this.platsList = res.data
            resolve(res.data)
          }
        })
      })
    },
    GET_WITHDRAW_PLATS(params = {}) {
      return new Promise((resolve, reject) => {
        Http.post("getWithdrawalPlat", params).then((res) => {
          if (res.code === 0) {
            this.cryptoList = res.data
            resolve(res.data)
          }
        })
      })
    },
    async GET_DEPOSIT_ADDRESS(params = {}) {
      try {
        this.platAddress = ""
        const res = await Http.post("getDepositAddress", params)
        this.platAddress = res.data
        return Promise.resolve(res)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async GET_DEPOSIT_LAZADA_INFO(params = {}) {
      try {
        const res = await Http.post(
          "geteVoucherInfo",
          params,
          {},
          { ignoreError: true }
        )
        return Promise.resolve(res)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async SET_DEPOSIT_LAZADA(params = {}) {
      try {
        const res = await Http.post("eVoucherDeposit", params)
        return Promise.resolve(res)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async WITHDRAWAL(params = {}) {
      const res = await Http.post("withdrawal", params)
      return res
    },
    // set main wallet
    SET_MAIN(params = {}) {
      return new Promise((resolve, reject) => {
        Http.post("updateMainWallet", params).then((res) => {
          if (res.code === 0) {
            this.walletList = res.data
            useUserStore().GET_USER_PROFILE()
          }
          resolve(res.data)
        })
      })
    },
    // GET_CHANNEL
    GET_CHANNEL(params = {}) {
      return new Promise((resolve, reject) => {
        Http.post("getChannelList", params).then((res) => {
          if (res.code === 0) {
            this.channelList = res.data
          }
          resolve(res)
        })
      })
    },
    // 获取充值通道
    async GET_DEPOSIT_CHANNEL() {
      try {
        const depositChannelListResponse = await Http.post(
          "getDepositChannelList"
        )

        const depositRecordResponse = await Http.post("getDepositRecord", {
          current: 1,
          data: {
            status: 1,
            endTime: dayjs().unix(),
            startTime: dayjs().subtract(1, "month").unix(),
          },
          size: 5,
        })
        const chipsFormat = (chips: string) => {
          const chipsArray = chips.split(",").map((item) => item.trim())
          const formattedChipsArray = chipsArray.filter((item) => item !== "")
          const resultArray = formattedChipsArray.map(Number)
          return resultArray
        }
        // const isDeposited = depositRecordResponse.data?.list?.length > 0
        const defaultChips = [1000, 2000, 5000, 10000, 20000]
        // const defaultChipsDeposied = [500, 1000, 2000, 5000, 10000]
        const depositList =
          (depositChannelListResponse.data as DepositListType[])?.map(
            (item) => {
              const chips =
                chipsFormat(item.chips).length > 0
                  ? chipsFormat(item.chips)
                  : defaultChips
              const maxCoin = item.maxCoin ? item.maxCoin : Math.max(...chips)
              const minCoin = item.minCoin ? item.minCoin : Math.min(...chips)
              return {
                ...item,
                chips: chips.join(","),
                maxCoin,
                minCoin,
              }
            }
          ) ?? []
        this.$patch({
          depositList,
        })

        return depositChannelListResponse.code
      } catch (e) {}
    },
    // 获取提款通道列表  getWithdrawalAddressType
    GET_WITHDRAW_CHANNEL() {
      return new Promise((resolve, reject) => {
        Http.post("getWithdrawalAddressType").then((res) => {
          if (res.code === 0) {
            this.withdrawChannelList = res.data
          }
          resolve(res)
        })
      })
    },
    // 判斷打碼量
    async CHECK_CODE_COIN(showNote: boolean = true) {
      try {
        const __response = await Http.post("getWithdrawalDetail")
        const response = __response as Omit<typeof __response, "data"> & {
          data: StoreTypes.WithdrawDetail.WithdrawDetail
        }
        if (showNote) {
          if (Number(response.data.isMinActualWithdrawal) > 0) {
            useNotify({
              type: "warning",
              message: `The actual withdrawal amount ${response.data.actualWithdrawal} is less than the minimum withdrawal amount ${response.data.minAmount}.`,
              position: "top-left",
            })
          }
          if (Number(response.data.codeRecordsList?.length) > 0) {
            useNotify({
              type: "warning",
              message: `You have ${response.data.codeRecordsList?.length} uncompleted code records, please complete them first Or you can choose to clear them with a fee.`,
              position: "top-left",
            })
          }
        }

        this.$patch({
          withdrawDetail: response.data,
        })

        return response
      } catch (error) {
        useNotify({
          type: "warning",
          // @ts-ignore
          message: error.message,
          position: "top-left",
        })
        return null
      }
    },
  },
})
