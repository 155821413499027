type PageMeta = Parameters<typeof definePageMeta>["0"]
type PageMetaCustom = Pick<
  PageMeta,
  | "path"
  | "redirect"
  | "alias"
  | "validate"
  | "scrollToTop"
  | "keepalive"
  | "pageTransition"
  | "layoutTransition"
  | "key"
  | "middleware"
> & {
  layout?: PageMeta["layout"]
  name: RouteName
  children?: PageMetaCustom[]
}

const defaultMeta: Partial<PageMetaCustom> = {}

type IRouteRecord = {
  [K in RouteName]: PageMetaCustom
}

const addDefaultMeta = (record: IRouteRecord) => {
  return Object.fromEntries(
    Object.entries(record).map(([key, value]) => [
      key,
      { ...defaultMeta, ...value },
    ])
  )
}

const metas: IRouteRecord = {
  Home: {
    name: "Home",
    path: "/",
  },
  
  HelpCenter: {
    name: "HelpCenter",
    path: "/help-center",
  },
  "Affiliate-ID": {
    name: "Affiliate-ID",
    path: "/affiliate/:id",
  },
  "Affiliate-Rule": {
    name: "Affiliate-Rule",
    path: "/affiliate/rule",
  },
  blog: {
    name: "blog",
  },
  blogAll: {
    name: "blogAll",
  },
  blogDigitalCurrency: {
    name: "blogDigitalCurrency",
  },
  blogEntertainment: {
    name: "blogEntertainment",
  },
  blogGaming: {
    name: "blogGaming",
  },
  blogInformation: {
    name: "blogInformation",
  },
  blogSports: {
    name: "blogSports",
  },
  blogContent: {
    name: "blogContent",
  },
  Slots: {
    name: "Slots",
    path: "/slots",
  },
  Live: {
    name: "Live",
    path: "/live",
  },
  Fishing: {
    name: "Fishing",
    path: "/fishing",
  },
  Chess: {
    name: "Chess",
    path: "/chess",
  },
  Lottery: {
    name: "Lottery",
    path: "/lottery",
  },
  Bingo: {
    name: "Bingo",
    path: "/bingo",
  },
  Table: {
    name: "Table",
    path: "/table",
  },
  Sports: {
    name: "Sports",
    path: "/sports",
  },
  Favorites: {
    name: "Favorites",
    path: "/favorites",
  },
  Recent: {
    name: "Recent",
  },
  Originals: {
    name: "Originals",
  },
  Promotions: {
    name: "Promotions",
    path: "/promotions",
  },
  AboutUs: {
    name: "AboutUs",
    path: "/about-us",
  },
  PromotionInfo: {
    name: "PromotionInfo",
    path: "/promotions/:id",
  },
  Vip: {
    name: "Vip",
  },
  VipCenter: {
    name: "VipCenter",
  },
  VipDetail: {
    name: "VipDetail",
  },
  My: {
    middleware: ["auth"],
    redirect: { name: "Wallet" },
    name: "My",
    path: "/my",
  },
  Wallet: {
    middleware: ["auth"],
    name: "Wallet",
    path: "/my/wallet",
  },
  AccountHistory: {
    middleware: ["auth"],
    name: "AccountHistory",
    path: "/my/account-history",
  },
  ActivityRecord: {
    middleware: ["auth"],
    name: "ActivityRecord",
    path: "/my/activity-record",
  },
  Swap:{
    middleware: ["auth"],
    name: "Swap",
    path: "/my/swap",
  },
  Transfer:{
    middleware: ["auth"],
    name: "Transfer",
    path: "/my/transfer",
  },
  BetRecord: {
    middleware: ["auth"],
    name: "BetRecord",
    path: "/my/bet-record",
  },
  Information: {
    middleware: ["auth"],
    name: "Information",
    redirect() {
      return {
        key: "Information-List",
        name: "Information-List",
        params: {
          category: 1,
        },
        replace: true,
      }
    },
    path: "/my/information",
  },
  "Information-ID": {
    middleware: ["auth"],
    name: "Information-ID",
    path: "/my/information/:category/:id",
  },
  "Information-List": {
    middleware: ["auth"],
    name: "Information-List",
    path: "/my/information/:category",
  },
  MyAccount: {
    middleware: ["auth", "account"],
    name: "MyAccount",
    path: "/my/account",
  },
  Deposit: {
    middleware: ["auth", "deposit"],
    name: "Deposit",
    path: "/my/deposit",
  },
  Withdraw: {
    middleware: ["auth", "withdraw"],
    name: "Withdraw",
    path: "/my/withdraw",
  },
  GameRoom: {
    name: "GameRoom",
    path: "/game-room",
  },
  Terms: {
    name: "Terms",
    path: "/terms",
  },
  RaffleTicket: {
    name: "RaffleTicket",
    path: "/my/raffle-ticket"
  },
  ResponsibleGambling: {
    name: "ResponsibleGambling",
    path: "/responsible-gambling"
  },
  Staking: {
    name: "Staking",
    path: "/staking",
  },
  VIPClub:{
    name: "VIPClub",
    path: "/VIPClub",
  },
  404: {
    name: "404",
    path: "/404",
  },
}

const metasFixed = Object.fromEntries(
  Object.entries(metas).map(([key, value]) => {
    return [
      key,
      {
        ...value,
        key: `key-${key}`,
      },
    ]
  })
) as IRouteRecord

export const RouteRecord = addDefaultMeta(metasFixed)
