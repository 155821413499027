import { defineStore } from "pinia"
import type { StoreTypes } from "~/types/store"

const initialValue: StoreTypes.Asset.State = {
  assetMap: {},
}

export const useAssetStore = defineStore("Asset", {
  state: (): StoreTypes.Asset.State => ({
    ...initialValue,
  }),
  getters: {},
  actions: {
    async INIT_ASSET_LIST() {
      const assets = await assetPromises()

      this.$patch({
        assetMap: assets,
      })
    },
  },
})
