<template>
  <RootComponent :="$attrs" />
</template>

<script setup lang="tsx">
import { useSvgStore } from "~/stores/svg"
import { useAssetStore } from "~/stores/asset"

type PropsType = {
  name?: AssetKey | String
  // eslint-disable-next-line vue/require-default-prop
  src?: string
  svgErrorImg?: string
  client?: boolean
}
//@ts-ignore
const props = withDefaults(defineProps<PropsType>(), {
  name() {
    return "default-pic-none"
  },
  svgErrorImg() {
    return ""
  },
})

const emit = defineEmits(["svgError"])

const defaultName = "default-pic-none"
const svgIdList = computed(() => useSvgStore().svgIdList)
const svgViewBoxMap = computed(() => useSvgStore().svgViewBoxMap)
const isSvg = computed(
  () => !props.src && !useAssetStore().assetMap[props.name as AssetKey]
)

const targetSrc = computed(() => {
  if (props.src) {
    return props.src
  }
  if (isSvg.value) {
    const isHaveSvg = svgIdList.value.includes(`sprite_id-${props.name}`)
    if (isHaveSvg) {
      emit("svgError", false)
      return `#sprite_id-${props.name}`
    }
    if (!isHaveSvg) {
      emit("svgError", true)
      if (props.svgErrorImg) {
        return `#sprite_id-${props.svgErrorImg}`
      }
      return `#sprite_id-${props.name}`
    }
  }
  return useAssetStore().assetMap[(props.name || defaultName) as AssetKey]
})

const svgViewBox = computed(() => {
  const __svgViewBox = svgViewBoxMap.value[targetSrc.value.replace("#", "")]
  if (__svgViewBox) {
    return {
      ...__svgViewBox,
    }
  }
  return {
    height: "15px",
    width: "15px",
  }
})

// nuxt-img 支援的格式
const isSupportType = computed(() =>
  /.(webp|avif|jpeg|jpg|png|gif|svg)$/.test(targetSrc.value)
)
const isNoFormat = computed(() => /.(avif|gif|svg)$/.test(targetSrc.value))
const isDefaultSrc = computed(() => !(props.src || props.name))
const altName = computed(() => {
  return (props.name || defaultName) as string
})
const componentAttrs = useAttrs()

const attrsComputed = computed(() => componentAttrs)

function SvgComponent() {
  const classComputed = computed(() => {
    const classObject = {
      "object-fill": isDefaultSrc.value,
      "svg-icon": true,
      [`sprite_id-${altName.value}`]: true,
    }
    return (
      Object.entries(classObject)
        .filter(([, value]) => value)
        .map(([key]) => key)
        // @ts-ignore
        .concat(attrsComputed.value.class || [])
        .join(" ")
    )
  })
  return (
    <svg
      width={svgViewBox.value.width}
      height={svgViewBox.value.height}
      shape-rendering="geometricPrecision"
      style={{
        height: "auto",
      }}
      {...attrsComputed.value}
      class={classComputed.value}
    >
      <use xlinkHref={targetSrc.value} />
    </svg>
  )
}

function ImgComponent() {
  return (
    <img
      src={targetSrc.value}
      alt={altName.value}
      loading="lazy"
      {...attrsComputed.value}
    />
  )
}

function RootComponent() {
  const Output = computed(
    () => () => isSvg.value ? <SvgComponent /> : <ImgComponent />
  )
  if (props.client) {
    return (
      <client-only>
        <Output.value />
      </client-only>
    )
  }
  return <Output.value />
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: currentColor;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
</style>
