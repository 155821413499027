import revive_payload_client_meeYgxaiMk from "/home/powerbtend-web/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.10.5_eslint@8.56.0_rollup@2.79.1_sass@1.62.1_typescript@5.3.3_vite@4.3.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RiJ3LA7kCl from "/home/powerbtend-web/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.10.5_eslint@8.56.0_rollup@2.79.1_sass@1.62.1_typescript@5.3.3_vite@4.3.8_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_n5uanyjiXH from "/home/powerbtend-web/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.10.5_eslint@8.56.0_rollup@2.79.1_sass@1.62.1_typescript@5.3.3_vite@4.3.8_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_g90yJfDr51 from "/home/powerbtend-web/node_modules/.pnpm/nuxt-site-config@2.1.2_@nuxt+devtools@1.0.6_@vue+compiler-core@3.3.13_axios@1.6.3_nuxt@3.8.0__x6vys5oe2dytprgtulploc2mea/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import plugin_vue3_0qpLCFrK8e from "/home/powerbtend-web/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@2.79.1_typescript@5.3.3_vue@3.3.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/powerbtend-web/.nuxt/components.plugin.mjs";
import prefetch_client_tfsHpcuJLX from "/home/powerbtend-web/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.10.5_eslint@8.56.0_rollup@2.79.1_sass@1.62.1_typescript@5.3.3_vite@4.3.8_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_jAIuMnhNz7 from "/home/powerbtend-web/node_modules/.pnpm/nuxt-site-config@2.1.2_@nuxt+devtools@1.0.6_@vue+compiler-core@3.3.13_axios@1.6.3_nuxt@3.8.0__x6vys5oe2dytprgtulploc2mea/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/i18n.mjs";
import templates_pwa_client_2f82e41f_H7ArQMoe54 from "/home/powerbtend-web/.nuxt/templates.pwa.client.2f82e41f.ts";
import element_plus_teleports_plugin_h4Dmekbj62 from "/home/powerbtend-web/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/home/powerbtend-web/.nuxt/element-plus-injection.plugin.mjs";
import composition_u5f06FylgM from "/home/powerbtend-web/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_rollup@2.79.1_vue@3.3.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_MaqizLiKEr from "/home/powerbtend-web/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_rollup@2.79.1_vue@3.3.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_oOsORJyGZ0 from "/home/powerbtend-web/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.10.5_eslint@8.56.0_rollup@2.79.1_sass@1.62.1_typescript@5.3.3_vite@4.3.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_VGtuYqZN6a from "/home/powerbtend-web/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.10.5_eslint@8.56.0_rollup@2.79.1_sass@1.62.1_typescript@5.3.3_vite@4.3.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import check_outdated_build_client_97HEU8UQc0 from "/home/powerbtend-web/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.10.5_eslint@8.56.0_rollup@2.79.1_sass@1.62.1_typescript@5.3.3_vite@4.3.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import ssr_plugin_6W00Oz1uwa from "/home/powerbtend-web/node_modules/.pnpm/@vueuse+nuxt@10.7.0_nuxt@3.8.0_rollup@2.79.1_vue@3.3.13/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import plugin_mYTFyCb0Bl from "/home/powerbtend-web/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.4.11_pinia@2.1.7_rollup@2.79.1/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import animate_client_UvtMvKa0w1 from "/home/powerbtend-web/plugins/animate.client.ts";
import lazyload_ehEjmux8k8 from "/home/powerbtend-web/plugins/lazyload.ts";
import nodata_kZg0gopdau from "/home/powerbtend-web/plugins/nodata.ts";
import scroll_S7pZiC3d46 from "/home/powerbtend-web/plugins/scroll.ts";
import social_sharing_ntpaD0Rjds from "/home/powerbtend-web/plugins/social-sharing.ts";
export default [
  revive_payload_client_meeYgxaiMk,
  unhead_RiJ3LA7kCl,
  router_n5uanyjiXH,
  _0_siteConfig_g90yJfDr51,
  plugin_vue3_0qpLCFrK8e,
  components_plugin_KR1HBZs4kY,
  prefetch_client_tfsHpcuJLX,
  i18n_jAIuMnhNz7,
  templates_pwa_client_2f82e41f_H7ArQMoe54,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  composition_u5f06FylgM,
  i18n_MaqizLiKEr,
  chunk_reload_client_oOsORJyGZ0,
  cross_origin_prefetch_client_VGtuYqZN6a,
  check_outdated_build_client_97HEU8UQc0,
  ssr_plugin_6W00Oz1uwa,
  plugin_mYTFyCb0Bl,
  animate_client_UvtMvKa0w1,
  lazyload_ehEjmux8k8,
  nodata_kZg0gopdau,
  scroll_S7pZiC3d46,
  social_sharing_ntpaD0Rjds
]