/* eslint-disable camelcase */
import type { FormInstance, FormRules, UploadInstance } from "element-plus"
import type { ShallowRef } from "vue"
import { Dictionary } from "~/types/dictionary"
import { useUserStore } from "~/stores/user"
import Http from "~/request/server"
import { EnumKycStatus } from "~/types/enum"
import { dayjsUtilManila } from "#imports"
import { getAiKycSwitchStatus } from "./appCommonUse"

export enum KycSceneStep {
  FIRST_STEP = 1,
  AI_KYC_STEP = 2,
  FINAL_STEP = 0,
}

type TypeInitCityData = {
  province: string
  cities: string[]
}[]

const initCityDataRef = ref<TypeInitCityData>([])

export const useInitCityData = () => {
  const output = asyncComputed(async () => {
    if (initCityDataRef.value.length === 0) {
      const response = await Http.post("getCity")
      initCityDataRef.value = response.data ?? []
    }
    return initCityDataRef.value ?? []
  })

  const returnValue = computed(() => output.value ?? [])

  return returnValue
}

export const useCities = () => {
  const initCityData = useInitCityData()
  const selectedProvince = computed(
    () =>
      (initCityData.value?.find(
        (item) => item.province.trim() === form.province
      )?.cities ?? []) as TypeInitCityData[0]["cities"]
  )
  return computed(() => selectedProvince.value?.map((item) => item.trim()))
}

export const useProvinces = () => {
  const initCityData = useInitCityData()

  return computed(() => initCityData.value?.map((item) => item.province))
}

export const useKycFormRules = () => {
  const userStore = useUserStore()
  const { checkMobile, checkEmail, checkDate, checkUpload, checkRealName } = useCheck()
  
  const kycFormRules = computed<FormRules<IKycForm>>(() => ({
    firstName: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "first_name")?.requiredEnabled,
        validator: (
          rule: any,
          value: string,
          cb: (arg?: string) => void
        ) => {
          let cbArg
          if (!value){
            if (userStore.kycIsVisibleData?.find((item:any) => item.validKey === "first_name")?.requiredEnabled) {
             cbArg = "required!"}
             else{
              cb()
             }
            }
          else if (isZhOrEn(value)) cbArg = undefined
          else cbArg = "required!"
      
          cb(cbArg)
        },
        message: "invalid!",
        trigger: "change",
      },
    ],
    middleName: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "middle_name")?.requiredEnabled,
        validator: (
          rule: any,
          value: string,
          cb: (arg?: string) => void
        ) => {
          let cbArg
          if (!value){
            if (userStore.kycIsVisibleData?.find((item:any) => item.validKey === "middle_name")?.requiredEnabled) {
             cbArg = "required!"}
             else{
              cb()
             }
            }
          else if (isZhOrEn(value)) cbArg = undefined
          else cbArg = "required!"
      
          cb(cbArg)
        },
        message: "invalid!",
        trigger: "change",
      },
    ],
    lastName: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "last_name")?.requiredEnabled,
        validator:(
          rule: any,
          value: string,
          cb: (arg?: string) => void
        ) => {
          let cbArg
          if (!value){
            if (userStore.kycIsVisibleData?.find((item:any) => item.validKey === "last_name")?.requiredEnabled) {
             cbArg = "required!"}
             else{
              cb()
             }
            }
          else if (isZhOrEn(value)) cbArg = undefined
          else cbArg = "required!"
      
          cb(cbArg)
        },
        message: "invalid!",
        trigger: "change",
      },
    ],
    idType: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "id_type")?.requiredEnabled,
        message: "invalid!",
        trigger: "change",
      },
    ],
    birthday: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "birthday")?.requiredEnabled,
        validator: checkDate as any,
        trigger: "change",
      },
    ],
    nationality: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "nationality")?.requiredEnabled,
        message: "invalid!",
        trigger: "change",
      },
    ],
    placeOfBirth: [
      {
        required: true,
        message: "invalid!",
        trigger: "change",
      },
    ],
    currentAddress: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "current_address")?.requiredEnabled,
        message: "invalid!",
        validator: (
          rule: any,
          value: string,
          cb: (arg?: string) => void
        ) => {
          let cbArg
          if (!value){
            if (userStore.kycIsVisibleData?.find((item:any) => item.validKey === "current_address")?.requiredEnabled) {
             cbArg = "required!"}
             else{
              cb()
             }
            }
          else if (isZhOrEn(value)) cbArg = undefined
          else cbArg = "required!"
      
          cb(cbArg)
        },
        trigger: "change",
      },
    ],
    permanentAddress: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "permanent_address")?.requiredEnabled,
        message: "invalid!",
        validator: (
          rule: any,
          value: string,
          cb: (arg?: string) => void
        ) => {
          let cbArg
          if (!value){
            if (userStore.kycIsVisibleData?.find((item:any) => item.validKey === "permanent_address")?.requiredEnabled) {
             cbArg = "required!"}
             else{
              cb()
             }
            }
          else if (isZhOrEn(value)) cbArg = undefined
          else cbArg = "required!"
      
          cb(cbArg)
        },
        trigger: "change",
      },
    ],
    employmentType: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "employment_type")?.requiredEnabled,
        message: "invalid!",
        trigger: "change",
      },
    ],
    mainSourceOfFunds: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "main_source")?.requiredEnabled,
        message: "invalid!",
        trigger: "change",
      },
    ],
    occupation: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "occupation")?.requiredEnabled,
        message: "invalid!",
        trigger: "change",
      },
    ],
    gender: [
      {
        required: true,
        message: "必填",
      },
    ],
    civilStatus: [
      {
        required: true,
        message: "必填",
      },
    ],
    contactNo: [
      {
        required: true,
        validator: checkMobile,
        // message: "Contact number is required",
      },
    ],
    emailAddress: [
      {
        required: true,
        // message: "Email address is required",
        validator: checkEmail,
      },
    ],
    idNumber: [
      {
        required: true,
        message: "必填",
      },
    ],
    imgHandheld: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "selfie_with_id_card_image")?.requiredEnabled,
        validator: checkUpload,
        trigger: "change",
      },
    ],
    imgFront: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "front_photo_of_id_image")?.requiredEnabled,
        validator: checkUpload,
        trigger: "change",
      },
    ],
    imgBack: [
      {
        required: true,
        validator: checkUpload,
        trigger: "change",
      },
    ],
    city: [
      {
        required: true,
        message: "必填",
      },
    ],
    province: [
      {
        required: true,
        message: "必填",
      },
    ],
    relationStore: [
      {
        required: userStore.kycIsVisibleData?.find((item:any) => item.validKey === "nearest_branch")?.requiredEnabled,
        message: "invalid!",
      },
    ],
    // age: [
    //   {
    //     required: true,
    //     message: "Age is required",
    //   },
    // ],
  }))
  return kycFormRules
}

export const kycFriendlyDisplayName = computed(() => {
  const status = useKycStatus()
  switch (status.value) {
    case EnumKycStatus.NOT_LOGIN:
    case EnumKycStatus.INITIAL:
      return "Start"
    case EnumKycStatus.AI_APPROVED:
    case EnumKycStatus.AI_REJECTED:
      return "Scanned"
    case EnumKycStatus.AI_SKIPPED:
      return "Skipped"
    case EnumKycStatus.CERTIFY:
    case EnumKycStatus.REVERIFY:
      return "Review"
    case EnumKycStatus.CSR_REJECTED:
      return "Rejected"
    case EnumKycStatus.CSR_APPROVED:
    case EnumKycStatus.COMPLETED:
      return "Completed"
    case EnumKycStatus.BLOCKED:
      return "Blocked"
    default:
      return "Start"
  }
})

export const kycFormScopedRef = reactive<{
  originalData: Partial<IKycForm>
}>({ originalData: {} })

const form = reactive<Partial<IKycForm>>({
  imgFront: undefined,
  idType: undefined,
  remark: undefined,
  firstName: undefined,
  lastName: undefined,
  middleName: undefined,
  birthday: undefined,
  relationStore: undefined,
  employmentType: undefined,
  currentAddress: undefined,
  nationality: undefined,
  placeOfBirth: undefined,
  permanentAddress: undefined,
  mainSourceOfFunds: undefined,
  occupation: undefined,
  age: undefined,
  province: undefined,
  city: undefined,
  civilStatus: undefined,
  contactNo: undefined,
  emailAddress: undefined,
  gender: undefined,
  idNumber: undefined,
  imgBack: undefined,
  imgHandheld: undefined,
})

const currentStep: ShallowRef<KycSceneStep> = shallowRef(
  KycSceneStep.FINAL_STEP
)

const steps = shallowRef<KycSceneStep[]>([
  KycSceneStep.FIRST_STEP,
  KycSceneStep.AI_KYC_STEP,
  KycSceneStep.FINAL_STEP,
])

const formRef = ref<FormInstance>()
const uploadRefImgFront = ref<UploadInstance>()
const uploadRefImgBack = ref<UploadInstance>()
const uploadRefImgHandheld = ref<UploadInstance>()

const refs = {
  formRef,
  uploadRefImgFront,
  uploadRefImgBack,
  uploadRefImgHandheld,
}

const NamesRegex = /[^A-Za-z. ]/g
const SpaceRegex = /\s+/g

const isLoading = ref(false)

const isAnyEmptyField = shallowRef(false)

const availableItemList: (keyof IKycForm)[] = [
  "firstName",
  "middleName",
  "lastName",
  "birthday",
  "imgFront",
  "imgHandheld",
  "idType",
  "nationality",
  "currentAddress",
  "permanentAddress",
  "employmentType",
  "occupation",
  "mainSourceOfFunds",
]

watchEffect(() => {
  let keys = [] as (keyof IKycForm)[]
  switch (currentStep.value) {
    case KycSceneStep.FINAL_STEP:
      keys = [...availableItemList]
      break
  }
  isAnyEmptyField.value = keys.some(
    (key) =>
      form[key] === undefined ||
      form[key] === null ||
      (form[key] === "" && !["middleName"].includes(key))
  )
})

export const useKycForm = () => {
  const { getDirection } = useDictionary()
  const userStore = useUserStore()
  const kycStatus = useKycStatus()

  const getLastestKycData = async () => {
    const aiKycSwitchStatus = await getAiKycSwitchStatus()
    const res = await userStore.GET_USER_KYC()
    const data = res.data
    if (data) {
      const {
        firstName: __firstName,
        lastName: __lastName,
        middleName: __middleName,
        birthday: __birthday,
        idType,
        contactNo,
        gender,
        age,
        ...rest
      } = data
      // 宣告 orcJsonData 有可能是 undefined
      const ocrJsonData = data.ocrJsonData as IKycForm["ocrJsonData"]

      const firstName = (__firstName || ocrJsonData?.firstName) ?? ""
      const middleName = (__middleName || ocrJsonData?.middleName) ?? ""
      const lastName = (__lastName || ocrJsonData?.lastName) ?? ""
      const birthday_thirdparty = ocrJsonData?.birthday ?? ""

      const birthdayMillis = __birthday
        ? __birthday * 10 ** 3
        : /\d{4}-\d{2}-\d{2}/s.test(birthday_thirdparty)
        ? dayjsUtilManila(birthday_thirdparty).valueOf()
        : dayjsUtilManila().subtract(21, "year").valueOf()

      const transformedRest = Object.fromEntries(
        Object.entries(rest).map(([key, value]) => {
          if (
            value === 0 ||
            value === null ||
            (typeof value === "string" && value.trim() === "")
          )
            return [key, undefined]
          return [key, value]
        })
      )

      // 姓名把後端不合法的字元去除
      const regex = /\p{Script_Extensions=Latin}|[-. ]/gu
      const formatName = (s: string) => {
        return s.match(regex)?.join("") ?? ""
      }

      const generateNameProxy = (initValue: string) => {
        const __ref = shallowRef(formatName(initValue))
        return computed({
          get: () => __ref.value,
          set: (value: string) => {
            __ref.value = formatName(value)
          },
        })
      }
      const converted = {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        birthday: dayjsUtilManila(birthdayMillis).valueOf(),
        idType: (() => {
          if (idType === "0") return undefined
          return idType !== undefined ? `${idType}` : undefined
        })(),
        contactNo: contactNo
          ? contactNo.toString()
          : userStore.info?.mobile
          ? userStore.info.mobile.toString()
          : undefined,
        gender: gender !== undefined ? `${gender}` : undefined,
        age: computed(() => {
          const birthdayDate = dayjsUtilManila(form.birthday ?? birthdayMillis)
          const currentDate = dayjsUtilManila()
          return currentDate.diff(birthdayDate, "year")
        }),
        ...transformedRest,
      }
      Object.assign(form, converted)
      Object.assign(kycFormScopedRef.originalData, converted)
    } else {
      Object.keys(form).forEach((key) => {
        form[key as keyof typeof form] = undefined
      })
      form.contactNo = userStore.info?.mobile
        ? userStore.info.mobile.toString()
        : undefined
    }
    form.relationStore = "Pasay Branch"
    switch (kycStatus.value) {
      case EnumKycStatus.INITIAL:
        setCurrentStep(
          aiKycSwitchStatus ? KycSceneStep.FIRST_STEP : KycSceneStep.FINAL_STEP
        )
        break
      default: {
        setCurrentStep(KycSceneStep.FINAL_STEP)
        break
      }
    }
    if (
      [
        //
        EnumKycStatus.INITIAL,
        EnumKycStatus.AI_APPROVED,
        EnumKycStatus.AI_REJECTED,
        EnumKycStatus.AI_SKIPPED,
        EnumKycStatus.CSR_REJECTED,
      ].includes(kycStatus.value)
    ) {
      await validate(true)
    }
  }

  // 收入来源
  const MainSourceOfFundOptionList = computed(() =>
    getDirection(Dictionary.dict_main_source_of_funds)
  )
  // 工作性质
  const EmployTypeOptionList = computed(() =>
    getDirection(Dictionary.dict_employment_type)
  )
  // 国籍
  const NationalityOptionList = computed(() =>
    getDirection(Dictionary.dict_kyc_nationality)
  )
  // 账号类型
  const KycTypeOptionList = computed(() =>
    (
      getDirection(Dictionary.dic_kyc_type) as {
        code: `${number}`
        sort: number
        title: string
        disabled: boolean
      }[]
    )
      .sort((a, b) => {
        if (a.sort === 0) return 1
        if (b.sort === 0) return -1
        return a.sort - b.sort
      })
      .map((it) => ({
        ...it,
        disabled: it.sort === 0,
        hidden: it.sort === 0,
      }))
  )

  // 審核状态
  const KycStatusOptionList = computed(() =>
    getDirection(Dictionary.dic_kyc_status)
  )
  // 婚姻状况
  const CivilStatusOptionList = computed(() =>
    getDirection(Dictionary.dic_civil_status)
  )

  const GenderOptionList = computed(() =>
    getDirection(Dictionary.dic_user_profile_sex)
  )

  // 图片 object
  const ImageObjectCollection = computed(() => {
    const IdFrontPhoto =
      typeof form.imgFront === "string"
        ? form.imgFront
        : form.imgFront
        ? URL.createObjectURL(form.imgFront)
        : undefined
    const IdHandheldPhoto =
      typeof form.imgHandheld === "string"
        ? form.imgHandheld
        : form.imgHandheld
        ? URL.createObjectURL(form.imgHandheld)
        : undefined
    const IdBackPhoto =
      typeof form.imgBack === "string"
        ? form.imgBack
        : form.imgBack
        ? URL.createObjectURL(form.imgBack)
        : undefined

    return { IdFrontPhoto, IdHandheldPhoto, IdBackPhoto }
  })
  // 关系店铺
  const RelationStoreOptionList = computed(() => [
    {
      title: "Powerbtent 2024 Taft Ave",
      code: "Powerbtent 2024 Taft Ave",
    },
  ])
  // 职业领域
  const OccupationOptionList = computed(() => [
    {
      title: "Healthcare",
      code: '1',
    },
    {
      title: "Education",
      code: '2',
    },
    {
      title: "Technology and Information Technology",
      code: '3',
    },
    {
      title: "Engineering",
      code: '4',
    },
    {
      title: "Business and Finance",
      code: '5',
    },
    {
      title: "Law",
      code: '6',
    },
    {
      title: "Arts, Design, and Entertainment",
      code: '7',
    },
    {
      title: "Public Service and Government",
      code: '8',
    },
    {
      title: "Hospitality and Catering",
      code: '9',
    },
    {
      title: "Architecture and Planning",
      code: '10',
    },
    {
      title: "Others",
      code: '11',
    },
  ])
  // 属性
  const Properties = computed(() => ({
    MainSourceOfFundOptionList: MainSourceOfFundOptionList.value,
    EmployTypeOptionList: EmployTypeOptionList.value,
    NationalityOptionList: NationalityOptionList.value,
    KycTypeOptionList: KycTypeOptionList.value,
    KycStatusOptionList: KycStatusOptionList.value,
    ImageObjectCollection: ImageObjectCollection.value,
    CivilStatusOptionList: CivilStatusOptionList.value,
    RelationStoreOptionList: RelationStoreOptionList.value,
    OccupationOptionList: OccupationOptionList.value,
    GenderOptionList: GenderOptionList.value,
  }))

  const replaceName = (name: string) => {
    return name.replace(NamesRegex, "").replace(SpaceRegex, " ")
  }

  const submit = async () => {
    await validate()
    const formHandled = Object.fromEntries(
      Object.entries(form)
        .filter(([key]) => availableItemList.includes(key as never))
        .map(([key, value]) => {
          if (value === undefined || value === null) return [key, ""]
          return [key, value]
        })
    ) as IKycForm

    const body: IKycForm = {
      ...formHandled,
      birthday: dayjsUtilManila(form.birthday).unix(),
      gender: Number(form.gender) ? Number(form.gender) : undefined,
    }

    const bodyTrim = Object.fromEntries(
      Object.entries(body)
        .map(([key, value]) => {
          if (typeof value === "string") return [key, value.trim()]
          return [key, value]
        })
        .filter(([, value]) => value !== undefined && value !== null)
    )


    const response = await Http.post(
      "setOrUpdateKyc",
      { ...bodyTrim },
      {},
      {
        isNotTrim: true,
      }
    )
    if (response.code === 0) {
      const route = useRoute()
      if (route.query?.dialogOptions) delete route.query.dialogOptions
      useDialog().closeDialog()
      userStore.GET_USER_PROFILE()
      useNotify({
        title: "Successfully",
        message: "Kyc set Successfully",
        type: "success",
        duration: 1000,
        position: "top-left",
      })
    }
    await userStore.GET_USER_KYC()
  }

  const validate = async (ignore?: boolean) => {
    if (!formRef.value) return
    // @ts-ignore
    await formRef.value.validate((valid, fields) => {
      if (valid) {
        return true
      } else {
        console.log("error submit!", fields)
        if (!ignore) {
          throw new Error("error submit!")
        }
      }
    })
  }

  const reset = () => {
    formRef.value?.resetFields()
  }

  const setCurrentStep = (step: KycSceneStep) => {
    currentStep.value = step
  }
  const getCurrentStep = () => {
    return currentStep.value
  }
  const getCurrentStepIndex = () => {
    return steps.value.findIndex((step) => step === currentStep.value)
  }

  const { scrollTo } = useDialogContainerControll()

  const nextStep = () => {
    const current = getCurrentStep()
    const currentIndex = steps.value.findIndex((step) => step === current)
    const next =
      currentIndex + 1 > steps.value.length - 1
        ? steps.value[steps.value.length - 1]
        : steps.value[currentIndex + 1]
    setCurrentStep(next)
    scrollTo({ top: 0 })
  }

  const previousStep = () => {
    const current = getCurrentStep()
    const currentIndex = steps.value.findIndex((step) => step === current)
    const previous =
      currentIndex - 1 < 0 ? steps.value[0] : steps.value[currentIndex - 1]
    setCurrentStep(previous)
    scrollTo({ top: 0 })
  }

  const jumpToStep = (step: KycSceneStep) => {
    setCurrentStep(step)
    scrollTo({ top: 0 })
  }

  const StepTitle = computed(() => {
    switch (currentStep.value) {
      case KycSceneStep.FIRST_STEP:
        return "验证流程指南"
      case KycSceneStep.AI_KYC_STEP:
        return "人工智能 KYC 流程"
      case KycSceneStep.FINAL_STEP:
        switch (kycStatus.value) {
          case EnumKycStatus.INITIAL:
            return "填写基本信息"
          case EnumKycStatus.AI_APPROVED:
            return "填写基本信息（暂停）"
          case EnumKycStatus.AI_REJECTED:
            return "编辑信息并提交"
          case EnumKycStatus.AI_SKIPPED:
            return "填写基本信息"
          case EnumKycStatus.CSR_REJECTED:
            return "更正信息并重新提交"
          case EnumKycStatus.COMPLETED:
          case EnumKycStatus.CSR_APPROVED:
            return "KYC验证已获批准"
          case EnumKycStatus.CERTIFY:
          case EnumKycStatus.REVERIFY:
            return "申请正在审核中"
          default:
            return undefined
        }
    }

    return `${getCurrentStepIndex() + 1} / ${steps.value.length}`
  })
  const StepProgress = computed(() => {
    return `${getCurrentStepIndex() + 1} / ${steps.value.length}`
  })

  const uploadImage =
    (key: "imgFront" | "imgHandheld" | "imgBack") => async (file: File) => {
      const responseInfo = await userStore.UPLOAD(file)
      form[key] = responseInfo.url
      return responseInfo.url
    }

  const stepConfirm = async () => {
    isLoading.value = true
    try {
      await formRef.value?.validate()
      if (currentStep.value === KycSceneStep.FINAL_STEP) {
        if (form.imgFront instanceof File)
          await uploadImage("imgFront")(form.imgFront)
        if (form.imgHandheld instanceof File)
          await uploadImage("imgHandheld")(form.imgHandheld)
        if (form.imgBack instanceof File)
          await uploadImage("imgBack")(form.imgBack)
        await submit()
      } else {
        nextStep()
      }
    } catch (error) {
      console.log(error)
      try {
        const firstError = Object.values(error as Object)
          .flat()
          .pop()
        formRef.value?.scrollToField(firstError)
      } catch (error) {
        console.log(error)
      }
    }
    isLoading.value = false
  }

  const isFormDisabled = computed(
    () =>
      ![
        //
        EnumKycStatus.AI_APPROVED,
        EnumKycStatus.INITIAL,
        EnumKycStatus.AI_REJECTED,
        EnumKycStatus.AI_SKIPPED,
        EnumKycStatus.CSR_REJECTED,
        EnumKycStatus.BLOCKED,
      ].includes(kycStatus.value)
  )

  const isConfirmButtonDisabled = computed(() => {
    return isFormDisabled.value || isLoading.value 
  })

  const useKycStatusListener = () => {
    let interval: ReturnType<typeof setInterval> | null = null

    const startListen = (callback?: (status: EnumKycStatus) => void) => {
      async function listen() {
        await userStore.GET_USER_KYC()
        const status = computed(() => userStore.GET_UESR_KYC_STATUS_ACTION())
        callback && (await callback(status.value))
        if (
          [
            EnumKycStatus.AI_APPROVED,
            EnumKycStatus.AI_REJECTED,
            EnumKycStatus.AI_SKIPPED,
          ].includes(status.value)
        ) {
          interval && clearInterval(interval)
        }
      }
      interval = setInterval(listen, 5000)
    }

    const stopListen = () => interval && clearInterval(interval)

    return {
      startListen,
      stopListen,
    }
  }

  const isKycStatusTitleShow = computed(() =>
    [
      //
      EnumKycStatus.AI_REJECTED,
      EnumKycStatus.AI_SKIPPED,
      EnumKycStatus.AI_APPROVED,
      EnumKycStatus.CSR_REJECTED,
      EnumKycStatus.CSR_APPROVED,
      EnumKycStatus.REVERIFY,
      EnumKycStatus.CERTIFY,
      EnumKycStatus.COMPLETED,
      EnumKycStatus.BLOCKED,
    ].includes(kycStatus.value)
  )

  return {
    isLoading,
    isFormDisabled,
    isConfirmButtonDisabled,
    form,
    formRef,
    uploadRefImgFront,
    uploadRefImgBack,
    uploadRefImgHandheld,
    getLastestKycData,
    Properties,
    replaceName,
    submit,
    steps,
    currentStep,
    setCurrentStep,
    getCurrentStep,
    nextStep,
    previousStep,
    jumpToStep,
    StepTitle,
    StepProgress,
    reset,
    uploadImage,
    refs,
    stepConfirm,
    kycStatus,
    useKycStatusListener,
    isKycStatusTitleShow,
  }
}
