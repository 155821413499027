/**  获取字典参数 */
export enum Dictionary {
  // 国籍
  dict_kyc_nationality = "dict_kyc_nationality",
  // KYC工作性质
  dict_employment_type = "dict_employment_type",
  // KYC收入来源
  dict_main_source_of_funds = "dict_main_source_of_funds",
  // KYC账号类型
  dic_kyc_type = "dic_kyc_type",
  // KYC账号状态
  dic_kyc_status = "dic_kyc_status",
  dic_civil_status = "dic_civil_status",
  // 性别
  dic_user_profile_sex = "dic_user_profile_sex",
}
