import { log } from "console"
import { defineStore } from "pinia"
import Http from "~/request/server"
import type { StoreTypes } from "~/types/store"
import { AppInitConfigID } from "~/utils/constant"
console.log(typeof window !== 'undefined' ? localStorage.getItem('lang') : "en");

export const useAppStore = defineStore("app", {
  state: (): StoreTypes.App.State => ({
    config: [],
    dictionary: [],
    pageScrollTop: {} as StoreTypes.App.PageScrollTop,
    language: typeof window !== 'undefined' ? localStorage.getItem('lang') : "en",
    kycValidDialogVisible:{},
    registerItemData:[],
}),
  getters: {
    websocketURL(): string | null {
      const url =
        this.config?.find((c) => c.id === AppInitConfigID.ws_server)?.value ??
        null
      return url
    },
    isQuickLogin(): boolean {
      const quickLoginState = this.config?.find((item) => {
        return item.title === "quickLoginEnable"
      })
      return quickLoginState?.value === "1"
    },
    getLang(state): string {
      return state.language ?? 'en';
    },
  },
  actions: {
    getConfigByTitle(title: PlatformConfigTitle) {
      return this.config?.find((c) => c.title === title)
    },
    async GET_CONFIG() {
      const res = await Http.post("init")
      this.$patch({
        config: res?.data ?? [],
      })
      return res?.data
    },
    GET_CONFIG_BY_ID(id: AppInitConfigID) {
      return this.config?.find((c) => c.id === id)
    },
    async GET_DICTIONARY() {
      const res = await Http.post("getDictionary")
      if (res.code === 0)
        this.$patch({
          dictionary: res.data ?? [],
        })
      return res.code
    },
    SET_PAGE_SCROLLTOP(routeName: RouteName, scrollTop: number) {
      this.$patch({
        pageScrollTop: { [routeName]: scrollTop },
      })
    },
    SET_LANG(language: string) {
      this.$patch({
        language,
      })
    },
    async GET_KYC_DIALOG(){
      const responseInfo = await Http.post("getKycValidVisible", )
      this.$patch({
        kycValidDialogVisible:responseInfo.data
      })
      return responseInfo.data
    },
    async GET_REGISTER_CONFIG(params:string){
      const responseInfo = await Http.post("getKycVisible", { groupName:params })
      this.$patch({
        registerItemData:responseInfo.data
      })
      return responseInfo.data
    },
  },
})
