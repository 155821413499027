export const Apis = {
  login: "/v1/user/login", // 登录
  getSportToken: "/v1/bet/getSportToken", // 取得三方體育登录用的 token
  checkOpenGoogleCode: "/v1/user/checkOpenGoogleCode", // 校验是否需要谷歌验证器
  register: "/v1/user/register", // 注册
  profile: "/v1/user/userProfile", // 获取用户详情
  logout: "/v1/user/logout", // 登出
  notice: "/v1/home/notice", // 公告
  createGoogleCode: "/v1/user/createGoogleCode", // 获取验证器
  bindGoogleCode: "/v1/user/bindGoogleCode", // 绑定谷歌验证码
  openOrCloseGoogleCode: "/v1/user/openOrCloseGoogleCode", // 开启或者关闭Google验证器
  sendEmailCode: "/v1/user/sendEmailCode", // 获取验邮箱验证码
  resetPassword: "/v1/user/resetPassword", // 重置密码
  forgotPassword: "/v1/user/forgotPassword", // 忘记密码
  resetMobile: "/v1/user/resetMobile", // 修改手机号
  updateUserProfile: "/v1/user/updateUserProfile", // 更新用户信息
  getWithdrawalPlat: "/v1/wallet/getWithdrawalPlat", // 获取币种提款平台
  withdrawalAddressList: "/v1/wallet/withdrawalAddressList", // 提款地址列表
  getPlatConfigList: "/v1/wallet/getPlatConfigList", // 获取支付平台列表
  addWithdrawalAddress: "/v1/wallet/addWithdrawalAddress", // 新增提款地址
  deleteWithdrawalAddress: "/v1/wallet/deleteWithdrawalAddress", // 删除提款地址
  updateWithdrawalAddress: "/v1/wallet/updateWithdrawalAddress", // 设置默认提款地址
  getDictionary: "/v1/dictionary/getDictionary", // 获取字典
  gameList: "/v1/platform/gameList", // gameList
  slotGameList: "/v1/platform/slotGameList", // 游戏列表
  gameFavorite: "/v1/platform/gameFavorite", // 游戏收藏/取消
  platList: "/v1/platform/platList", // 平台列表
  helpTypes: "/v1/help/pageHelpType", // 帮助类型
  helpContent: "/v1/help/pageHelpInfo", // 帮助类型下的内容
  helpDetail: "/v1/help/helpInfoById", // 帮助内容详情
  blog: "/v1/blog/list", // 博客列表
  init: "/v1/home/init", // 初始化接口
  getUserWalletCoin: "/v1/wallet/getUserWalletCoin", // 单独只获取馀额
  getCurrencyCoin: "/v1/wallet/getCurrencyCoin", // 获取钱包余额
  hasBindFiatWallet: "/v1/wallet/hasBindFiatWallet", // 查询是否绑定法币钱包（除USD钱包）
  bindFiatWallet: "/v1/wallet/bindFiatWallet", // 绑定法币钱包（除USD钱包）
  getDepositRecord: "/v1/wallet/getDepositRecord", // 获取充值记录
  getWithdrawalRecord: "/v1/wallet/getWithdrawalRecord", // 获取充值记录
  getDepositPlats: "/v1/wallet/getDepositPlat", // 获取充值渠道列表
  startGame: "/v1/platform/startGame", // 获取游戏启动url
  getDepositAddress: "/v1/wallet/deposit", // 获取充值二维码地址
  queryMyBets: "/v1/bet/queryMyBets", // 我的注单
  updateMainWallet: "/v1/wallet/updateMainWallet", // 设置主钱包
  getMessageList: "/v1/notice/getMessageList",
  getNoticeCount: "/v1/notice/getNoticeCount", // 每个类型消息数量
  updateMessageStatus: "/v1/notice/updateMessageStatus", // 修改消息状态
  deleteMessageStatus: "/v1/notice/deleteMessageStatus", // 删除全部消息
  getCoinLogList: "/v1/wallet/getCoinLogList", // 获取帐变记录
  getLampList: "/v1/notice/getLampList", // 跑马灯信息,
  getPromotionList: "/v1/promotions/promotionsActivityList", // 优惠活动列表
  applyPromotion: "/v1/promotions/promotionsInfo", // 申请活动
  // 代理
  coinTransfer: "/v1/agent/coinTransfer", // 转账
  commissionRecords: "/v1/agent/commissionRecords", // 佣金流水
  subordinateDetails: "/v1/agent/subordinateDetails", // 佣金明细
  teamBenefits: "/v1/agent/teamBenefits", // TeamBenefits
  commissionRateList: "/v1/agent/commissionRateList",
  //
  queryMySportBets: "/v1/bet/queryMySportBets", // queryMySportBets
  casinoList: "/v1/platform/casinoList", // casino列表
  // vip
  receive: "/v1/vip/receive", // vip領取獎勵
  userLevelInfo: "/v1/vip/userLevelInfo", // vip中心-使用者vip資訊
  userLeveReward: "v1/vip/userLeveReward", // vip中心領獎表
  userLevelBenefits: "/v1/vip/userLevelBenefits", // vip等級獎勵詳情
  userLevelDetails: "/v1/vip/userLevelDetails", // vip詳情資訊
  memberShipDetails: "/v1/vip/memberShipDetails", // vip中心-MemberShip
  memberShipLevelDetails: "/v1/vip/memberShipLevelDetails", // vip中心-MemberShip Level
  withdrawal: "/v1/wallet/withdrawal", // 在线提款
  sendSesCode: "/v1/user/sendSesCode", // 获取邮箱验证码
  resetEmail: "/v1/user/resetEmail", // 重置邮箱地址
  listLevel: "/v1/user/listLevel",
  blogSearch: "/v1/blog/search", // 博客搜索
  getWithdrawalChannelList: "/v1/wallet/getWithdrawalChannelList", //
  currencyRateApi: "/v1/defi/currencyRateApi", //
  checkPixDeposit: "/v1/wallet/checkDeposit", // 检查充值
  getDepositChannelList: "/v1/wallet/getDepositChannelList",
  getWithdrawalAddressType: "/v1/wallet/getWithdrawalAddressType", // 获取提款地址类型
  getBannerList: "/v1/home/getHomeBannerList",
  getJackpotAndPayout: "/v1/home/getJackpotAndPayout", //
  rankingList: "/v1/home/rankingList", // 排行榜
  betList: "/v1/bet/betList",
  hotGameList: "/v1/platform/hotGameList",
  sendSmsCode: "/v1/user/sendSmsCode", // 发送手机验证码
  getCap: "/captcha/get",
  promotionsApplyAgain: "/v1/promotions/promotionsApplyAgain",
  sendSnsCode: "/v1/user/sendSnsCode",
  getUserKyc: "/v1/user/getUserKyc", // 获取kyc信息
  uploadFile: "/v1/upload/uploadFile", // kyc id上传
  setOrUpdateKyc: "/v1/user/setOrUpdateKyc", // 更新kyc信息
  sportGameList: "/v1/platform/gameSlotList",
  searchGameList: "/v1/platform/searchGameList",
  gameClassifyList: "/v1/platform/gameClassifyList", // 分类接口
  thirdLogin: "/thirdLogin/auth", // 三方登录token
  thirdAuthUrl: "/thirdLogin/authUrl", // 三方登录
  betStatistics: "v1/bet/betStatistics",
  userStatusInfo: "/v1/user/userStatusInfo", // 谷歌脸书绑定状态
  bindingUrl: "/thirdLogin/bindingUrl", // 获取绑定三方账号url
  binding: "/thirdLogin/binding", // 绑定三方账号
  details: "/v1/platform/details", // 游戏详情
  GameBannerList: "/v1/home/bigGameCategoryBanner/list",
  mobileLogin: "/v1/user/mobileLogin", // 登录和注册
  checkIn: "/v1/checkIn/checkIn",
  currentActivity: "/v1/checkIn/currentActivity",
  setPassword: "/v1/user/setPassword",
  endingRecommend: "/v1/platform/endingRecommend", // ending 推荐
  getCity: "/v1/home/initCity",
  kycGetUrl: "/aikyc/kyc/getUrl",
  kycReportGetReport: "/v1/kyc/report/getReport",
  kycReportCallback: "/v1/kyc/report/callback",
  depositChannelActivityList: "/v1/promotions/activityFilter", // 优惠活动->充值時返回符合條件的活動
  completeSharing: "/v1/promotions/completeSharing", // 优惠活动->完成分享回調接口
  getHelpDetail: "v1/promotions/getHelpDetail", // 优惠活动->獲取助力訊息 (阶段信息查询接口)
  getShareHelpLink: "/v1/promotions/getShareHelpLink", // 优惠活动->獲取分享連結
  getChristmasActivityStatus: "/v1/promotions/getChristmasActivityStatus", // 优惠活动->取得聖誕節活動開關狀態
  christmasActivityHelping: "/v1/promotions/christmasActivityHelping", // // 优惠活动->助力
  gameTypeList: "/v1/platform/gameTypeList", // 游戏分类
  getFakeHelping: "/v1/promotions/getFakeHelpingDetail", // 优惠活动->取得聖誕節活動助力假資料
  getPromoPopupList: "/v1/promotions/getPopupImg", // 优惠活动取得活动轮播图
  depositTrack: "/v1/wallet/depositTrack",
  getDepositTrack: "/v1/wallet/getDepositTrack",
  getCheckApplyTimes: "/v1/promotions/checkApplyTimes",
  checkChristmasActivity: "/v1/promotions/checkChristmasActivity", // 查询用户是否能够参加圣诞活动
  getWithdrawalDetail: "/v1/wallet/getWithdrawalDetail", // 提款打码量查询
  cleanUserCodeRecords: "/v1/wallet/cleanUserCodeRecords", // 强制提款清空当前用户未完成的打码量
  getHelpRealDetail: "/v1/promotions/getRealHelpingDetail", // 获取助力真实数据
  ticketList: "/v1/ticket/list", // 獲取抽獎券 GET: ?uid=3
  ticketCreate: "/v1/ticket/create", // 創建抽獎券 POST: {uid: 3, event_type: 1}
  geteVoucherInfo: "/v1/wallet/eVoucherInfo", // LazazdaConfirm 取得優惠券資訊
  eVoucherDeposit: "/v1/wallet/eVoucherDeposit", // LazazdaConfirm 充值
  stakingInfo: "/v1/defi/defiInfo", // 质押数据
  stakingList: "/v1/defi/defiRecord", // 质押表格
  stakingAdd: "/v1/defi/addDefi", // 新增质押
  stakingReturn: "/v1/defi/refundDefi", // 解质押
  currenyRate: "/v1/defi/currencyRateApi", // 汇率获取
  coinList: "/v1/swap/coinList", // swap货币列表
  swapTrans: "/v1/swap/transform", // swap
  transferToChainWallet: "/v1/defi/transferToChainWallet", // 质押提领奖励
  myWalletGetCode: "/v1/wallet/walletInfo", // 我的钱包页面获取打码量
  getCryptoAddress: "/v1/wallet/getCryptoAddress", // 获取用户数字币地址列表
  transferGetThirdWallet: "/v1/platform/getGameCoin", // 划转获取三方钱包余额
  transferGameCoin: "v1/platform/transferGameCoin", // 划转
  clubInfo: "/api/v1/vip/info",//当前的盈亏总额以及月度有效投注金额
  clubRebates:"/api/v1/vip/rebates",//vip反水列表
  clubRecord: "/v1/vip/records",//彩金记录和月俸记录
  getKycVisible: "/v1/user/getValidConfig",//获取kyc是否显示列表
  getKycValidVisible: "/v1/system/getPlatConfig",//注册与出款时是否弹出kyc
  applyActivity: "/v1/activity/apply", // 申请活动
  activityInfo: "/v1/activity/info", // 活动详情
  activityList: "/v1/activity/list", // 活动列表
  activityRecords: "/v1/activity/records", // 活动記錄列表
}

export const ApiDegradableList = [
  Apis.depositChannelActivityList,
  Apis.completeSharing,
  Apis.getHelpDetail,
  Apis.getShareHelpLink,
  Apis.getChristmasActivityStatus,
  Apis.christmasActivityHelping,
  Apis.getFakeHelping,
  Apis.getCheckApplyTimes,
  Apis.checkChristmasActivity,
]
