import { ElNotification } from "element-plus"
function DebounceLeading(func: (...args: any) => void, timeout = 1000) {
  let timer: ReturnType<typeof setTimeout> | null
  return (...args: any) => {
    // @ts-ignore
    if (!timer) func.apply(this, args)
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
    }, timeout)
  }
}

const debounceNotify: Record<string, ReturnType<typeof DebounceLeading>> = {}
export function useNotify(...args: Parameters<typeof ElNotification>) {
  // @ts-ignore
  const message = args[0].message as string
  if (!debounceNotify[message])
    debounceNotify[message] = DebounceLeading(ElNotification)

  return debounceNotify[message](...args)
}
