export enum Size {
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
  EXTRA_LARGE = "xl",
  EXTRA_2LARGE = "2xl",
}
export type ScreenSize =
  | typeof Size.SMALL
  | typeof Size.MEDIUM
  | typeof Size.LARGE
  | typeof Size.EXTRA_LARGE
  | typeof Size.EXTRA_2LARGE

export const defaultScreenConfig = {
  [Size.SMALL]: 576,
  [Size.MEDIUM]: 768,
  [Size.LARGE]: 992,
  [Size.EXTRA_LARGE]: 1200,
}

export enum EnumKycStatus {
  NOT_LOGIN = -1,
  NOT_SUBMITTED = 0, // 未进行kyc提交
  // 开始验证
  INITIAL = 1,
  // 三方拒绝
  AI_REJECTED = 2,
  // 三方通过
  AI_APPROVED = 3,
  // 匹配通过
  CERTIFY = 4,
  // 匹配不通過
  REVERIFY = 5,
  // 人工通過
  CSR_APPROVED = 6,
  // 人工拒絕
  CSR_REJECTED = 7,
  // 自動通過
  COMPLETED = 8,
  // 封鎖
  BLOCKED = 9,
  // 跳過 AI KYC 驗證
  AI_SKIPPED = 10,
}
