<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    text?: string
    name?: AssetKey
  }>(),
  {
    text() {
      return "No data."
    },
    name() {
      return "icons-noData"
    },
  }
)
const innerText = ref(props.text)
defineExpose({
  innerText,
})
</script>

<template>
  <div class="common-no-data flex justify-center justify-center p-1em">
    <CommonAsset name="icons-no-data" class="w-4em" />
    <strong class="text-1.2em font-500 ml-1.2em">{{ innerText }}</strong>
    <slot />
  </div>
</template>
<style lang="scss" scoped>
.common-no-data {
  color: #b3bad1;
  @apply text-16px items-center <sm:text-12px <lg:text-14px;
}
</style>
