import validate from "/home/powerbtend-web/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.10.5_eslint@8.56.0_rollup@2.79.1_sass@1.62.1_typescript@5.3.3_vite@4.3.8_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45init_45global from "/home/powerbtend-web/middleware/1.init.global.ts";
import _2_45force_45kyc_45global from "/home/powerbtend-web/middleware/2.force-kyc.global.ts";
import _3_45dialog_45global from "/home/powerbtend-web/middleware/3.dialog.global.ts";
import manifest_45route_45rule from "/home/powerbtend-web/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.10.5_eslint@8.56.0_rollup@2.79.1_sass@1.62.1_typescript@5.3.3_vite@4.3.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45init_45global,
  _2_45force_45kyc_45global,
  _3_45dialog_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  account: () => import("/home/powerbtend-web/middleware/account.ts"),
  auth: () => import("/home/powerbtend-web/middleware/auth.ts"),
  deposit: () => import("/home/powerbtend-web/middleware/deposit.ts"),
  withdraw: () => import("/home/powerbtend-web/middleware/withdraw.ts")
}