import { createApp } from "vue"
import CommonNoData from "~/components/common/NoData.vue"
const NoDataDirective = {
  mounted(el: HTMLBaseElement, binding: any) {
    try {
      const { text } = binding.value
      const app = createApp(CommonNoData)
      const instance = app.mount(document.createElement("div"))
      // @ts-ignore
      el.instance = instance
      if (text) {
        // @ts-ignore
        instance.innerText = text
      }
    } catch (error) {
      // @ts-ignore
      console.log(error.message)
    }
  },
  updated(el: HTMLBaseElement, binding: any) {
    // @ts-ignore
    const instance = el.instance
    const { show } = binding.value
    try {
      if (show) {
        el.appendChild(instance.$el)
      } else {
        instance.$el.remove()
      }
    } catch (error) {
      // @ts-ignore
      console.log(error.message)
    }
  },
}
export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.directive("noData", NoDataDirective)
})
