import { useUserStore } from "~/stores/user"
import { useAppStore } from "~/stores/app"
import type { StoreTypes } from "~/types/store"

const routerList: RouteName[] = [
  "Affiliate-ID",
  "Affiliate-Rule",
  "blog",
  "blogAll",
  "blogDigitalCurrency",
  "blogEntertainment",
  "blogGaming",
  "blogInformation",
  "blogSports",
  "blogContent",
  "Originals",
  "Recent",
  "Vip",
  "VipCenter",
  "VipDetail",
]

export default defineNuxtRouteMiddleware(async (to: MiddlewareToType) => {
  if (gameCategoriesMenuState.gameCategoriesMenu.length === 0) {
    await useGameCategoriesMenu().getGameCategoriesMenu()
  }
  const tokenManager = useToken()
  let token = tokenManager.getToken()
  const hiddenRouterList = [...routerList]
  if (hiddenRouterList.includes(to.name)) {
    return navigateTo({ name: "404" })
  }

  const userStore = useUserStore()

  const signupToken = to.query.signupToken
  if (process.server) {
    try {
      const jwt = (await import("jsonwebtoken")).default
      const key = (await import("~/assets/others/jwtRS256.key.pub")).default
      if (signupToken) {
        const { data } = jwt.verify(signupToken, key) as {
          data: StoreTypes.User.Info
        }
        tokenManager.setToken(data.apiToken)
        token = data.apiToken
      }
    } catch (error) {
      console.log((error as Error).message)
    }
  }

  userStore.$patch({
    isLogin: !!token,
  })

  if (!!token && userStore.userKyc === null) {
    await userStore.GET_USER_KYC()
  }

  if (signupToken) {
    return navigateTo(
      {
        name: "Home",
      },
      {
        replace: true,
      }
    )
  }

  if (to.path === "/register") {
    if (!token) {
      const appStore = useAppStore()
      return navigateTo({
        name: "Home",
        query: {
          ...to.query,
          dialogType:
            appStore.isQuickLogin && !to.query.promoCode ? "PwdSign" : "signIn",
        },
      })
    } else {
      return navigateTo({
        name: "Home",
      })
    }
  }
})
