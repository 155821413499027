
import { updateAppConfig } from '#app'
import { defuFn } from '/home/powerbtend-web/node_modules/.pnpm/defu@6.1.3/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "nuxt": {
    "buildId": "1a10a758-81a0-4560-832c-24e6611f28ac"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/powerbtend-web/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
