/**
 * 校验用户名
 * {6-20}
 */
export function isUsername(str: string) {
  const user = /^[A-Za-z][A-Za-z0-9]{5,19}$/
  return user.test(str)
}
/**
 * 校验密码
 * {9-16}
 */
export function isPassword(str: string) {
  const pass = /^[a-zA-Z0-9!@#$%^&*_+]{9,16}$/
  return pass.test(str)
}
export function isPasswordOld(str: string) {
  const pass = /^[a-zA-Z0-9!@#$%^&*_+]{4,16}$/
  return pass.test(str)
}
export function isPasswordSignIn(str: string) {
  const pass = /^[a-zA-Z0-9!@#$%^&*_+]{0,16}$/
  return pass.test(str)
}
export function isMobile(str: string) {
  const mobileReg = /^(9|09)[0-9]{8}$/
  if (str.startsWith("1")) {
    // cn
    if (str.length === 11) return true
    return false
  } else if (
    str.startsWith("9") ||
    str.startsWith("09") ||
    str.startsWith("8") ||
    str.startsWith("08") ||
    str.startsWith("7") ||
    str.startsWith("07")
  ) {
    // tw & vn
    if (str.length === 9 || str.length === 10) return true
    return false
  }
  return mobileReg.test(str)
}
export function isCode(str: string) {
  const code = /^[0-9]{6}$/
  return code.test(str)
}

export function isRealName(str: string) {
  const code = /^(\p{Script_Extensions=Latin}|[- .]){2,40}$/u
  return code.test(str)
}
export function isZhOrEn(str: string) {
  const code = /^[\u4E00-\u9FA5A-Za-z]{1,40}$/
  return code.test(str)
}
export function isBankNo(str: string) {
  const code = /^[1-9][0-9]{4,18}$/
  return code.test(str)
}
export function isEmail(str: string) {
  const code = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
  return code.test(str)
}
export function isCity(str: string) {
  const code = /[a-zA-Z0-9]{2,40}/
  return code.test(str)
}
export function isAddress(str: string) {
  const code = /[a-zA-Z0-9]{2,80}/
  return code.test(str)
}
/* 邀请码 */
export function isPromoCode(str: string) {
  const code = /^([a-zA-Z0-9]{6,8}|MERCHANT[a-zA-Z0-9]+)$/
  return code.test(str)
}
export function isNumber(value: string) {
  const reg = /^[0-9]*$/
  return reg.test(value)
}
export function isCoin(value: string) {
  const reg = /^[0-9]+\.\d{2}$/
  return reg.test(value)
}
export function isBetAmount(value: string) {
  const reg = /^[1-9]+\d?$/
  return reg.test(value)
}
export function isVerify(value: string) {
  const code = /^[0-9]{6,6}$/
  return code.test(value)
}
export function isAmount(value: string) {
  const reg = /^(?!0\d)\d*(?:\.0+)?$/
  return reg.test(value)
}
export function isMobileequipment() {
  const reg =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|sm-/i
  return reg.test(navigator.userAgent)
}
