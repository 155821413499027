import { defineStore } from "pinia"
import Http from "~/request/server"
import type { StoreTypes } from "~/types/store"
export const useHomeStore = defineStore("home", {
  state: (): StoreTypes.Home => ({
    bannerList: [], // banner list in home index
    jackpotList: {},
    rankList: [],
    classificationList: [],
    loginBannerList: [],
    isVisible: true,
    searchFocus: false,
  }),
  actions: {
    async GET_BANNER(params = { type: 1 }) {
      const res = await Http.post("getBannerList", params)
      if (params.type === 1) {
        this.$patch({
          bannerList: res.data || [],
        })
      } else if (params.type === 2) {
        this.$patch({
          loginBannerList: res.data || [],
        })
      }

      return res
    },
    async GET_JACKPOT() {
      const res = await Http.post("getJackpotAndPayout")
      this.$patch({
        jackpotList: res.data,
      })
      return res
    },
    async GET_RANK(params: any = {}) {
      const res = await Http.post("rankingList", params)
      const rankingList = res.data ?? []
      this.$patch({
        rankList:
          params.category === 0
            ? rankingList.slice(0, 10)
            : rankingList.slice(0, 20),
      })
      return res
    },
    async GET_CLASSIFY_LIST(params: any = {}) {
      const res = await Http.post("gameClassifyList", params)
      this.$patch({
        classificationList: res.data || [],
      })
      return res
    },
    MODIFY_VISIBLE(visible: boolean) {
      this.$patch({
        isVisible: visible,
      })
    },
    MODIFY_SEARCH_FOCUS(searchFocus: boolean) {
      this.$patch({
        searchFocus,
      })
    },
  },
})
