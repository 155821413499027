import { defineStore } from "pinia"
import { dayjs } from "element-plus"
import { useUserStore } from "~/stores/user"
import Http from "~/request/server"
import type { StoreTypes } from "~/types/store"
import type { HttpUseFetch } from "~/types/http"

const initialValue: StoreTypes.CheckIn.State = {
  currentActivity: null,
  isClosed: false,
  isCheckIn: null,
  show: false,
  animationShow: false,
  story: "Close",
}

export const useCheckInStore = defineStore("checkIn", {
  state: (): StoreTypes.CheckIn.State => ({
    ...initialValue,
  }),
  getters: {},
  actions: {
    async GET_CURRENT_ACTIVITY(show: boolean) {
      const res: HttpUseFetch.Response<
        StoreTypes.CheckIn.State["currentActivity"]
      > = await Http.post("currentActivity")
      this.$patch({
        currentActivity: res.data ?? {},
        show,
      })
      return res
    },
    async CHECK_IN() {
      const res: HttpUseFetch.Response<StoreTypes.CheckIn.State["isCheckIn"]> =
        await Http.post("checkIn")
      this.$patch({
        isCheckIn: res.data,
        animationShow: true,
      })
      useUserStore().GET_USER_PROFILE()
      return res
    },
    OPEN_CHECK_IN_DIALOG() {
      this.$patch({
        show: true,
      })
    },
    CLOSE_CHECK_IN_DIALOG() {
      this.$patch({
        show: false,
        isClosed: false,
      })
    },
    CLOSE_CHECK_IN_ANIMATION_DIALOG() {
      this.$patch({
        animationShow: false,
      })
    },
    UPDATE_STORY(action: "Click" | "Close") {
      this.$patch({
        story: action,
      })
    },
    // 確認使用者操作、並決定如何執行
    async CHECK_IN_STORY() {
      await this.GET_CURRENT_ACTIVITY(false)

      if (
        this.currentActivity?.isPopUp === 1 &&
        this.currentActivity?.checkInStatus !== 4 &&
        this.currentActivity?.checkInInfoDto
      )
        switch (this.story) {
          case "Click":
            if ((this.currentActivity?.checkInStatus ?? 1) <= 2) {
              useRouter().push({ name: "Deposit" })
            } else if ((this.currentActivity?.checkInStatus ?? 1) === 3) {
              this.CHECK_IN()
            }
            break
          case "Close":
            if (!this.isClosed) this.OPEN_CHECK_IN_DIALOG()
            break
          default:
            if (!this.isClosed) this.OPEN_CHECK_IN_DIALOG()
            break
        }
    },
    // 一天一次的限制
    CHECK_ONCE_A_DAY() {
      const isCheckInOpenDate = localStorage.getItem("isCheckInOpenDate")
      const today = dayjs().format("YYYYMMDD")

      if (isCheckInOpenDate !== today) {
        localStorage.setItem("isCheckInOpenDate", today)
        this.CHECK_IN_STORY()
      }
    },
  },
})
