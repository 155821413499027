import { useUserStore } from "~/stores/user"
import { EnumKycStatus } from "~/types/enum"

export const isFormData = (v: {}): boolean => {
  return Object.prototype.toString.call(v) === "[object FormData]"
}

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))

export const formatters = {
  mobile(value: string) {
    return value.replace(/[^\d]/g, "")
  },
}

let timeout: NodeJS.Timeout
export function lastEffect(__task: () => void) {
  clearTimeout(timeout)
  timeout = setTimeout(__task, 100)
}

export function formatMobile(mobile: string) {
  if (mobile.startsWith("0")) {
    mobile = mobile.substring(1)
  }
  return mobile
}

export function isInIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const isFacebookPixelInited = shallowRef(false)

export function determineKycStatus(status: Required<IKycForm>["status"]) {
  const __status = Number(status ?? -1)
  switch (__status) {
    case -1:
      return EnumKycStatus.NOT_LOGIN
    case 0:
    case 1:
      return EnumKycStatus.INITIAL
    case 2:
      return EnumKycStatus.AI_REJECTED
    case 3:
      return EnumKycStatus.AI_APPROVED
    case 4:
      return EnumKycStatus.CERTIFY
    case 5:
      return EnumKycStatus.REVERIFY
    case 6:
      return EnumKycStatus.CSR_APPROVED
    case 7:
      return EnumKycStatus.CSR_REJECTED
    case 8:
      return EnumKycStatus.COMPLETED
    case 9:
      return EnumKycStatus.BLOCKED
    case 10:
      return EnumKycStatus.AI_SKIPPED
    default:
      return EnumKycStatus.NOT_LOGIN
  }
}

export async function startCoinAnime(
  max: number,
  dynamicValue: Ref<number>,
  callback?: () => void
) {
  const bonus = max
  const targetAmount = bonus // 目标金额
  let currentAmount = bonus * 0.1
  const fastEasingFactor = 0.1
  const slowEasingFactor = 0.075
  const finalEasingFactor = 0.02
  const updateInterval = 5 // 更新间隔（毫秒）

  function updateAmount() {
    const percentage = (currentAmount / targetAmount) * 100
    let easingFactor

    if (percentage < 80) {
      easingFactor = fastEasingFactor
    } else if (percentage < 99.9) {
      easingFactor = slowEasingFactor
    } else {
      easingFactor = finalEasingFactor
    }

    const distance = targetAmount - currentAmount
    const increment = distance * easingFactor

    if (Math.abs(distance) > 0.01) {
      currentAmount += increment
      dynamicValue.value = Number(currentAmount.toFixed(2))
      setTimeout(updateAmount, updateInterval)
    } else {
      dynamicValue.value = Number(targetAmount.toFixed(2))
      callback?.()
    }
  }

  updateAmount()
}

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))
