import dayjs from "dayjs"
import { useUserStore } from "~/stores/user"

export const useKycStatus = () =>
  computed(() => useUserStore().GET_UESR_KYC_STATUS_ACTION())

export const useOriginalKycStatus = () =>
  computed(() => useUserStore().GET_ORIGINAL_UESR_KYC_STATUS_ACTION())

export const useIsUnderForceAiKyc = (to: MiddlewareToType) => {
  const route = (() => to ?? useRoute())()
  const dialogType = computed(() => route.query.dialogType)
  const dialogOptions = computed(() => route.query.dialogOptions)
  return computed(() => {
    return (
      dialogType.value === "kycSetting" &&
      dialogOptions.value === "UnableToClose"
    )
  })
}

export const useIsAfterForceCheckDate = () => {
  // import
  const userStore = useUserStore()
  const today = dayjs()
  const createdAtDay = dayjs((userStore.info?.createdAt ?? 0) * 1000)
  const verifyDay = createdAtDay.add(7, "day")
  return userStore.info?.createdAt ? today.isAfter(verifyDay) : false
}
