/**
 * @author django
 * @description 字典类调用 获取数据
 */

import { useAppStore } from "~/stores/app"
import { EnumKycStatus } from "~/types/enum"

/**
 * @description 获取某一个字典
 * @param key {string} 字典的key
 */
const getDirection = (key: string) => {
  try {
    // @ts-ignore
    const value = useAppStore().dictionary[key] ?? []
    return value
  } catch (e) {
    return []
  }
}
/**
 * @description 根据字典的value 获取到相对应的label(文本)
 * @param list {array} 字典的列表
 * @param value {string|number} 数据的value
 * @return {string} 字典的label
 */
const getTitle = (list: any[], value: unknown) => {
  try {
    // @ts-ignore
    const label =
      list?.find((item: any) => item.code === value?.toString())?.title ?? ""
    return label
  } catch (e) {
    return ""
  }
}

const getKycStatusTitle = (short?: boolean) => {
  const status = useKycStatus()
  switch (status.value) {
    case EnumKycStatus.NOT_LOGIN:
    case EnumKycStatus.INITIAL:
      return "Start"
    case EnumKycStatus.AI_APPROVED:
    case EnumKycStatus.AI_REJECTED:
      return "Scanned"
    case EnumKycStatus.AI_SKIPPED:
      return "Skipped"
    case EnumKycStatus.CERTIFY:
    case EnumKycStatus.REVERIFY:
      return "In Review"
    case EnumKycStatus.CSR_REJECTED:
      return "Rejected"
    case EnumKycStatus.CSR_APPROVED:
    case EnumKycStatus.COMPLETED:
      return "Completed"
    case EnumKycStatus.BLOCKED:
      return "Blocked"
    default:
      return "Start"
  }
}

export const useDictionary = () => {
  return {
    dictionary: useAppStore().dictionary,
    getDirection,
    getTitle,
    getKycStatusTitle,
  }
}
