type ListType = {
  [key: string]: string
}
export const animateList: ListType = {
  sm: "grid-cols-3 fadeIn",
  md: "!grid-cols-4 pulse",
  lg: "!grid-cols-5 fadeIn",
  xl: "!grid-cols-7 pulse",
  "2xl": "!grid-cols-8 fadeIn",
}
