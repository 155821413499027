export const useToken = () => {
  const cookie = useCookie(__cookie_powerbtent_token_name, {
    path: "/",
    priority: "high",
  })
  const getToken = (): string => {
    return cookie.value as string
  }
  const setToken = (value: string) => {
    cookie.value = value
  }
  const clearToken = () => {
    cookie.value = ""
  }
  return {
    getToken,
    setToken,
    clearToken,
  }
}
