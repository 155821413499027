import { useIsAfterForceCheckDate, forceKyc } from "#imports"
import { useUserStore } from "~/stores/user"
import { EnumKycStatus } from "~/types/enum"

const KYC_SETTING_QUERY_NAME = "kycSetting"

export default defineNuxtRouteMiddleware(
  (to: MiddlewareToType, from: MiddlewareFromType) => {
    const userStore = useUserStore()
    const isLogin = !!useToken().getToken()
    const isUnderKycSetting = from.query?.dialogType === KYC_SETTING_QUERY_NAME
    const isGoToKycSetting = to.query?.dialogType === KYC_SETTING_QUERY_NAME

    if (!isGoToKycSetting) {
      forceKyc.value = false
    }

    if (!isLogin) {
      if (isGoToKycSetting) {
        return navigateTo({
          ...to,
          name: to.name,
          query: {
            ...to.query,
            dialogType: undefined,
          },
        })
      }
      return
    }
    return

    const kycStatus = userStore.GET_UESR_KYC_STATUS_ACTION()
    const isAfterForceCheckDate = useIsAfterForceCheckDate()

    const isForceOpenKycSetting = [
      isAfterForceCheckDate,
      ![
        EnumKycStatus.AI_REJECTED,
        EnumKycStatus.CSR_APPROVED,
        EnumKycStatus.COMPLETED,
        EnumKycStatus.REVERIFY,
      ].includes(kycStatus),
    ].every(Boolean)

    // 進入或者已經在 kycSetting 頁面，才開啟 forceKyc
    if (isUnderKycSetting || isGoToKycSetting) {
      forceKyc.value = isForceOpenKycSetting
    } else {
      forceKyc.value = false
    }

    // 如果已經要去 kycSetting 頁面，就不要再跳轉了
    if (isGoToKycSetting) {
      return
    }

    if (isForceOpenKycSetting) {
      return navigateTo({
        ...to,
        name: to.name,
        query: {
          ...to.query,
          dialogType: "kycSetting",
        },
      })
    }
  }
)
