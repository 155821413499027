/* eslint-disable import/no-named-as-default-member */
import FingerprintJS from "@fingerprintjs/fingerprintjs"

export const useFingerprint = () => {
  const fpPromise = FingerprintJS.load()
  async function generateFingerprint() {
    const fp = await fpPromise
    const result = await fp.get()
    return result.visitorId
  }

  return { generateFingerprint }
}
