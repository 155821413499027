import { useUserStore } from "~/stores/user"
import { useCheckInStore } from "~/stores/checkIn"
import { useAppStore } from "~/stores/app"

export const dialogState = reactive<{
  type: DialogTypeKey
}>({
  type: "",
})

export const forceKyc = shallowRef(false)
export const lockNx = shallowRef(false)
export const isUnableToClose = computed(() => forceKyc.value || lockNx.value)

export const setDialogState = (type: DialogTypeKey) => {
  dialogState.type = type
}

export const onRequestSuccessRef = ref<() => Promise<any>>()

const containerRef = ref<HTMLElement>()

export const useDialogContainerControll = () => {
  const scrollTo = (options: ScrollToOptions) => {
    containerRef.value?.scrollTo(options)
  }

  return {
    containerRef,
    scrollTo,
  }
}

export const useDialog = (to?: MiddlewareToType) => {
  const openDialog = async (
    dialogType: DialogTypeKey,
    dialogOptions?: "UnableToClose" | String,
    onRequestSuccess?: () => Promise<any>
  ) => {
    const route = (() => to ?? useRoute())()
    if (onRequestSuccess) {
      onRequestSuccessRef.value = onRequestSuccess
    }
    switch (dialogOptions) {
      case "UnableToClose":
        lockNx.value = true
        break
      default:
        break
    }
    await navigateTo(
      {
        name: route.name,
        query: {
          ...route.query,
          dialogType,
          dialogOptions,
        },
      },
      {
        replace: true,
      }
    )
  }

  const closeDialog = async () => {
    if (isUnableToClose.value) return
    const route = (() => to ?? useRoute())()
    const isLogin = useUserStore().isLogin
    const { dialogOptions } = route.query
    onRequestSuccessRef.value = undefined
    if (dialogOptions) {
      // 自定義處理任何事情
      switch (dialogOptions) {
        case "CheckIn":
          originalRouterReplace()
          if (isLogin) useCheckInStore().CHECK_IN_STORY()
          break
        case "VipCenter":
        case "Favorites":
        case "RaffleTicket":
        case "Deposit":

          if (isLogin) {
            await navigateTo({
              name: dialogOptions,
            })
          } else {
            originalRouterReplace()
          }
          break
        case "PromoCode":
          if (isLogin)
            await navigateTo({
              name: "Home",
            })
          else originalRouterReplace()
          break
        case "VerifyPhoneNumber":
          if (!isLogin) {
            delete route.query.dialogOptions
            openDialog("PwdSign")
          } else originalRouterReplace()
          break
        default:
          originalRouterReplace()
          break
      }
    } else originalRouterReplace()
  }

  const originalRouterReplace = async () => {
    const route = useRoute()
    const router = useRouter()
    const { dialogType, dialogOptions, ...routerQuery } = route.query

    await router.replace({
      name: route.name,
      query: { ...routerQuery },
    })
  }

  const isQuickLoginOpenDialog = (dialogOptions?: string) => {
    if (useAppStore().isQuickLogin) {
      openDialog("PwdSign", dialogOptions)
    } else {
      openDialog("signIn", dialogOptions)
    }
  }

  return {
    openDialog,
    closeDialog,
    onRequestSuccessRef,
    isQuickLoginOpenDialog,
  }
}
