/* eslint-disable n/no-callback-literal */
import * as validate from "~/utils/validate"
import { useUserStore } from "~/stores/user"

export const useCheck = () => {
  const { t } = useI18n()
  const validatorText03 = t("validator.text03")
  const validatorText04 = t("validator.text04")
  const validatorText07 = t("validator.text07")
  const validatorText08 = t("validator.text08")
  const validatorText09 = t("validator.text09")
  const validatorText11 = t("validator.text11")
  const validatorText13 = t("validator.text13")
  const additionalAmountFormat = t("additional.amountFormat")

  const checkUpload = (rule: any, value: unknown | any, cb: any) => {
    if (typeof value === "string" && value.length > 0) return cb()
    if (!value) {
      cb("Please Select image")
    } else if (!value?.type?.includes("image/")) {
      cb("Upload file must be Image format!")
    } else if (value.size / 1024 / 1024 > 5) {
      cb("Image size can not exceed 5MB!")
    } else cb()
  }
  const checkDate = (
    rule: any,
    value: unknown | any,
    cb: (message: string | void) => void
  ) => {
    let callbackMessage = ""
    if (!value) {
      callbackMessage = "Please select birthday"
    } else if (new Date(value).getFullYear() > new Date().getFullYear() - 21) {
      callbackMessage = "Your age at least 21"
    } else if (new Date(value).getFullYear() < 1921) {
      callbackMessage = "minimum year is 1921"
    } else {
      callbackMessage = ""
    }
    callbackMessage ? cb(callbackMessage) : cb()
  }
  const checkUsername = (
    rule: any,
    value: string,
    cb: (arg?: string) => void
  ) => {
    let cbArg
    if (!value) cbArg = "Username is required"
    else if (validate.isUsername(value)) cbArg = undefined
    else cbArg = "Username is invalid"

    cb(cbArg)
  }
  const checkUsernameOrMobile = (
    rule: any,
    value: string,
    cb: (arg?: string) => void
  ) => {
    if (!value) return cb("Enter User Name/Phone No.")
    if (!validate.isMobile(value)) return cb(validatorText09)
    if (!validate.isUsername(value)) return cb("User Name/Phone No. is invalid")
    cb()
  }
  const checkPassword = (rule: any, value: string, cb: Function) => {
    let cbArg
    if (!value) cbArg = "Please enter password"
    else if (validate.isPassword(value)) cbArg = undefined
    else cbArg = "9-16 characters and number"
    cb(cbArg)
  }
  const checkPasswordOld = (rule: any, value: string, cb: Function) => {
    let cbArg
    if (!value) cbArg = "Please enter password"
    else if (validate.isPasswordOld(value)) cbArg = undefined
    else cbArg = "Password is invalid"
    cb(cbArg)
  }
  const checkPasswordSignIn = (rule: any, value: string, cb: Function) => {
    let cbArg
    if (!value) cbArg = "Please enter password"
    else if (validate.isPasswordSignIn(value)) cbArg = undefined
    else {
      cbArg = "Password is invalid"
    }
    cb(cbArg)
  }

  const checkGoogleCode = (
    rule: any,
    value: string,
    cb: (arg?: string) => void
  ) => {
    if (value) {
      if (validate.isCode(value)) cb()
      else cb(validatorText07)
    } else cb(validatorText08)
  }
  const checkPromoCode = (
    rule: any,
    value: string,
    cb: (arg?: string) => void
  ) => {
    if (value) {
      if (validate.isPromoCode(value)) cb()
      else cb("The promotion code is invalid")
    } else cb()
  }
  const checkMobile = (
    rule: any,
    value: string,
    cb: (arg?: string) => void
  ) => {
    if (!value) return cb("Please enter your mobile number")
    if (!validate.isMobile(value)) return cb("Please enter the correct mobile")
    cb()
  }

  const checkMobileOriginal = (
    rule: any,
    value: string,
    cb: (arg?: string) => void
  ) => {
    const originalNumber = useUserStore().info?.mobile
    if (!value) return cb("Please enter your mobile number")
    if (!validate.isMobile(value)) return cb("Please enter the correct mobile")
    if (value.charAt(0) === "0") value = value.slice(1, value.length)
    if (value !== originalNumber)
      return cb("Not the mobile phone number bound to this account")
    cb()
  }

  const checkAccountNumber = (
    rule: any,
    value: string,
    cb: (arg?: string) => void
  ) => {
    if (!value) return cb("Please enter your account number")
    if (value.length === 9) {
      if (!validate.isMobile(value)) {
        return cb("Please enter the correct account number")
      }
    } else if ([16, 17, 19].includes(value.length)) {
      return cb()
    } else {
      return cb("Please enter the correct account number")
    }
    cb()
  }

  const checkAmount = (
    rule: any,
    value: string,
    cb: (arg?: string) => void
  ) => {
    if (value) {
      if (validate.isAmount(value)) cb()
      else cb(additionalAmountFormat)
    } else cb(validatorText11)
  }

  const checkAmountOrBet = (
    rule: any,
    value: string,
    cb: (arg?: string) => void
  ) => {
    if (value) {
      if (validate.isCoin(value)) cb()
      if (validate.isBetAmount(value)) cb()
      else cb(additionalAmountFormat)
    } else cb(validatorText11)
  }

  const checkVerify = (
    rule: any,
    value: string,
    cb: (arg?: string) => void
  ) => {
    if (value) {
      if (validate.isVerify(value)) cb()
      else cb(validatorText13)
    } else cb(validatorText08)
  }

  const checkEmail = (rule: any, value: string, cb: Function) => {
    if (value) {
      if (validate.isEmail(value)) cb()
      else {
        cb(validatorText03)
      }
    } else {
      cb(validatorText04)
    }
  }

  const checkRechargeAmount = (rule: any, value: string, cb: Function) => {}

  // 校验真实姓名
  const checkRealName = (
    rule: any,
    value: string,
    cb: (arg?: string) => void
  ) => {
    let cbArg
    if (!value) cbArg = "required!"
    else if (validate.isZhOrEn(value)) cbArg = undefined
    else cbArg = "required!"

    cb(cbArg)
  }
  const checkName = (rule: any, value: string, cb: (arg?: string) => void) => {
    let cbArg
    if (!value) cbArg = "Field is required"
    else if (validate.isRealName(value)) cbArg = undefined
    else cbArg = "Format matching is 2-40."

    cb(cbArg)
  }
  return {
    checkUsername,
    checkUsernameOrMobile,
    checkPassword,
    checkMobileOriginal,
    checkPasswordOld,
    checkPasswordSignIn,
    checkMobile,
    checkAmount,
    checkAccountNumber,
    checkAmountOrBet,
    checkVerify,
    checkEmail,
    checkRechargeAmount,
    checkGoogleCode,
    checkRealName,
    checkPromoCode,
    checkName,
    checkUpload,
    checkDate,
  }
}
