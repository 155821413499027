import { setDialogState } from "#imports"

export default defineNuxtRouteMiddleware(async (to: MiddlewareToType) => {
  /**
   * @description
   * 判斷是否為第三方登入跳轉機制
   * 將會存取：當前路由、當前路由參數
   * 以便於登入後，可以跳轉回來可以回到原本的頁面
   */
  if (process.client) {
    const query = to.query
    const isThirdpartyAccess = query?.code && query?.scope
    // 如果是以下彈出框，則需要移除 searchParams
    const excludeDialogType: DialogTypeKey[] = [
      //
      "PwdSign",
      "signIn",
      "signUp",
    ]

    const url = new URL(window.location.href)
    // @ts-ignore
    if (excludeDialogType.includes(url.searchParams.get("dialogType") ?? "")) {
      url.searchParams.delete("dialogType")
    }

    if (!isThirdpartyAccess) {
      const fullPath = `${url.pathname}${url.search}`
      localStorage.setItem(__thirdparty_redirect_url, fullPath)
      const searchJson = JSON.stringify(
        Object.fromEntries(Array.from(url.searchParams.entries()))
      )
      localStorage.setItem(__thirdparty_search_before_redirect, searchJson)
    }
  }
  const dialogType = (to.query?.dialogType ?? "") as DialogTypeKey

  /**
   * @description
   * 判斷彈出框類型
   */
  switch (dialogType) {
    case "kycSetting":
      try {
        const kycFormManager = useKycForm()
        await kycFormManager.getLastestKycData()
      } catch (error) {
        console.error(error)
      }
      break
    default:
      break
  }
  setDialogState(dialogType)
})
