const VITE_APP_TITLE = import.meta.env.VITE_APP_TITLE
export default {
  menu: {
    Jackpot:"Jackpot",
    Via:"Via",
    sports: "Sports",
    bingo: "Bingo",
    originals: "Powerbtent Originals",
    inPlay: "InPlay",
    Upcoming: "Upcoming",
    MyBet: "My bet",
    Casino: "Casino",
    Live: "Live",
    Slots: "Slots",
    Turbo: "Turbo",
    Club: `${VITE_APP_TITLE} Club`,
    Promotions: "Promotions",
    Affiliate: "Affiliate",
    All: "All",
    Bingo: "Bingo",
    Fishing: "Fishing",
    Chess: "Chess",
    Lottery: "Lottery",
    Sports: "Sports",
    Tables: "Tables",
    Blog: "blog",
    Help: "Help",
    About: "About Us",
    liveChat: "Live chat",
    chess: "Chess",
    lottery: "Lottery",
    table: "Table",
    categories: "Game Categories",
    help: "Help Center",
    terms: "Terms",
    FAQ: "FAQ",
    follow: "Follow Us",
    methods: "Payment Methods",
    gameCategories: {
      sports: "Sports",
      bingo: "Bingo",
      slots: "Slot Games",
      fishing: "Fishing",
      live: "Live Casino",
      chess: "Chess",
      table: "Table Games",
      lottery: "Lottery",
    },
    favorite: "Favorites",
    Favorites: "Favorites",
    promotion: "Promotions",
    dailyTask: "Daily Tasks",
    staking: "ATG Staking",
    club: "VIP Club",
  },
  public: {
    Date: "Date",
    All: "All",
    Single: "Single",
    Multi: "Multi",
    Type: "Type",
    Select: "Select",
    Filter: "Filter",
    Inquire: "Inquire",
    Submit: "Submit",
    Loading: "Loading",
    Reminder: "Reminder",
    HaveNoMessages: "You Have No Messages",
    start: "start",
    EndDate: "End date",
    title: "title",
    Cancel: "Cancel",
    Confirm: "Confirm",
    EnterSearch: "Enter Search",
    timeout: "timeout",
    RequestFail: "Request Fail",
    NoRequest: "No request",
    ASC: "Ascending",
    DESC: "Descending",
    lastFewSeconds: "Just now",
    lastFewMinutes: "{min} minutes earlier",
    lastFewHours: "{hour} hours earlier",
    allGame: 'All Providers',
  },
  game: {
    SeeAll: "See All",
    dondHaveGames: "You don’t have any games yet",
    needLoginFirst: "Before you start gaming you need to login first",
    EditSuccessfully: "Edit successfully",
    sureQuitGame: "Are you sure you want to quit this game?",
    sureChangeModel: "Are you sure you want to change play model?",
  },
  casino: {
    BestLiveCasinoGames: "Best Live Casino Games",
    BestSlotsGames: "Best Slot Games",
    TurboGames: "Turbo Games",
  },
  affiliate: {
    Notification: "The agency commission is settled every day at GMT{time}",
    banner: {
      TeamBenefits: "Team benefits",
      AddAnAgentToday: "Add An Agent Today",
      NumberOfAgents: "Number Of Agents",
      TotalCommission: "Total Commission",
      AllowWithdrawal: "Allow Withdraw",
      Withdrawal: "Withdraw",
      Transfer: "Transfer",
      agencyEarnings: "Your agency earnings",
      text: "The User Enters The Game Through The Link And Automatically Becomes Your Subordinate Agent",
      JoinAgent: "Join Agent",
    },
    invitationCodeLink: {
      InvitationCode: "Your Invitation Code",
      InviteLink: "Your Invite Link",
    },
    agentDetails: {
      agentLevel: "Agent level",
      commission: "commission amount",
      createdAt: "Registration time",
      details: "Personal sub-agent details",
    },
    commissionDetails: {
      CommissionDetails: "Commission Details",
      Username: "Username",
      Level: "Level",
      BettingTurnover: "Betting Turnover",
      CommissionUSD: "Commission",
      NoRecords: "You don’t have any Commission Details yet",
    },
    commissionBetting: {
      MyTeam: "My Team",
      CommissionDetails: "Commission Details",
      TotalCommission: "Total Commission",
      BettingTurnover: "Betting Turnover",
      Agent: "Agent",
    },
    transfer: {
      Transfer: "Transfer",
      TransferUSD: "Transfer USD",
      AllowTransfers: "Allow Transfers",
      TransferAll: "Transfer All",
      Submit: "Submit",
      InsufficientBalance: "Insufficient balance",
      Cancel: "Cancel",
      Confirm: "Confirm",
    },
    about: {
      title: "AGENT RULES",
      aboutUs: "About Us",
      title00:
        'All players invited by "Promoters" will receive a certain percentage of platform advantage bonus for every wager.',
      title01: "The following are the proportions of the three levels",
      title02: "Example: Your subordinate player",
      title03: "TERMS & CONDITIONS",
      text01:
        "LEVEL 1: You will be eligible for 0.3% benefits on the platform.",
      text02: "LEVEL 2: You will earn 0.1% benefits on the platform.",
      text03: "LEVEL 3: You will receive 0.05% of the platform's benefits.",
      text04:
        "level 1: top up 1000p: total amount wagered during the period 30:000: you can get 0.3% which means the commission you will receive is 90P.",
      text05:
        "level 2: top up 1000p: total amount wagered during the period 30:000: you can get 0.1% which means the commission you will receive is 30P.",
      text06:
        "level 3: top up 1000p: total amount wagered during the period 30:000: you can get 0.05% which means the commission you will receive is 15P.",
      text07: "level 1 : 30:000*0.3% = 90P.",
      text08: "level 2: 30:000*0.1% = 30P.",
      text09: "level 3: 30:000*0.05% = 15P.",
      text10:
        "The commission you can get is 30:000+30:000+30:000 X 0.45% = 135P.",
      text11:
        "1.The salary is distributed at 10 a.m. on a daily commission basis.",
      text12:
        "2. Just 1peso turnover is enough to get commission，this is based on net turnover commission",
      text13:
        "3.Players under the age of 21 are not permitted to register or work as an agent.",
      text14:
        "4.Just contact our customer service on the website to join our agent plan.",
      text15:
        "5.if any form of cheating is discovered: the commission will be ineligible.",
      text16:
        "6.POWERBTENT reserves the right to change the terms of this offer: cancel or renew it: and refuse participation in it at any time without prior notice.",
    },
  },
  mine: {
    Register: "Register",
    EnterYourAccount: "Username",
    eg: "e.g.example.com",
    GetCode: "Get Code",
    EnterCodeVerification: "Enter Code Verification",
    PhoneNumber: "Phone number",
    Password: "Password",
    InvitationCode: "Invitation code (optional)",
    confirmText:
      "I agree to the Terms & Conditions and Privacy Policy: including Cookie use.",
    CreateAccount: "Create account",
    HaveAccount: "Have an account ?",
    SignIn: "Sign in",
    PromoIncorrect: "Promo code was incorrect",
    Username: "User Name",
    ForgotPassword: "Forgot Password ?",
    DontHaveAccount: "Don’t have an account ?",
    ForgetPassword: "Forgot Password",
    GoogleAuthenticator: "Google Authenticator",
    IncorrectInput: "Incorrect input",
    EnterUsernameOrEmail: "Enter username",
    EnterPassword: "Enter password",
    WelcomeTo: "Welcome to",
    OfficialPartners: "Official Partners",
    CodeVerification: "Code Verification",
    SixdigitCodeToVerify: "Enter The Six-digit Code To Verify",
    AccountBindingEmail: "Account Binding Email",
    NewPassword: "New Password",
    information: {
      Delete: "Delete",
      DeleteAll: "Delete All",
      MarkAllAsRead: "Mark All As Read",
      Notice: "Notice",
      Bulletin: "Bulletin",
      Information: "Information",
      sureDleteAll: "Are you sure to delete all message?",
      sureDeleteThis: "Are you sure to delete this message?",
      sureMarkAll: "Are you sure to mark all as read?",
      Reminder: "Reminder",
      Check: "Check",
    },
    adjust: {
      AccountHistory: "Account History",
      IncomeAndExpenditure: "Income And Expenditure",
      Date: "Date",
      DateTime: "Time",
      Type: "Trans Type",
      CreditDebit: "Credit/Debit",
      PreviousBalance: "Previous Balance",
      CurrentBalance: "Current Balance",
    },
    profile: {
      GoogleAuthenticator: "Google Authenticator",
      Email: "Email",
      Password: "Password",
      Phone: "Phone",
      Unbind: "Unbind",
      Opened: "Opened",
      Unopened: "Unopened",
      Bind: "Bind",
      Change: "Change",
      sureTurnOffGoogleAuthenticator:
        "Are you sure you want to turn off Google Authenticator",
      sureTurnONGoogleAuthenticator:
        "Are you sure you want to turn on Google Authenticator",
      Reminder: "Reminder",
      Withdrawal: "Withdraw",
      Default: "Default",
      SetDefault: "Set default",
      AddUSDTAddress: "Add Address",
      sureDeleteCard: "Are you sure to delete this card?",
      sureSetCard: "Are you sure to set this card to be default",
      CryptoType: "Crypto type",
      PleaseSelect: "Please select",
      Address: "Address",
      EnterAddress: "Enter address",
      CryptoAddressIncorrect: "The Crypto address was incorrect",
      Mobile: "Mobile",
      PhoneNumber: "Phone number",
      OldPassword: "Old Password",
      NewPassword: "New Password",
      CurrentMail: "Current Mail",
      EnterMmail: "Enter your email",
      GetCode: "Get Code",
      CodeVerification: "Code Verification",
      NewEmail: "New Email",
      ToActivate: "To Activate: Scan The Qr Code Or Enter The Code Manually",
      KeyValue: "Key Value :",
      NextStep: "Next Step",
      selectID: "Select Valid ID",
      frontPhoto: "Front photo of ID",
      refuseReason: "Refuse Reason",
      reminders: "Reminders",
      kyc: "KYC Settings",
      valid: "Valid ID",
      unSetting: "Not Set",
      review: "In Review",
      description: `Update 1 valid ID must be government issued and clear with photo inside of frame<br/>
      Use original ID not photocopied<br/>
      Information of ID should include Name: Birthdate: photo is compulsory<br/>
      In legal age 21 years and all the details provided are all true<br/>
      With the PAGCOR request: the website have the right to requires user to<br/>
      provide a video verification`,
    },
  },
  blog: {
    latestblog: "Latest blog",
    highlights: "Highlights",
    previousblog: "Previous blog",
    releaseTime: "Release Time",
    noData: "No Data!",
    Blogblog: "Blog blog",
    BlogDetail: "Blog Detail",
  },
  help: {
    title: "Hi. How Can We Help?",
    seeAll: "See All",
    GettingStarted: "Getting Started",
    hi: "Hi, We’re here to help you",
    General: "General",
    Deposit: "Deposit",
    Withdrawal: "Withdrawal",
    Others: "Others",
    ATG:"ATG Club"

  },
  about: {
    tab: `About Us | Affiliates | ${VITE_APP_TITLE} Club`,
    clubhouse: "Xwin Clubhouse",
    aboutUs: {
      text01: `${VITE_APP_TITLE} was conceptualised to be the pre-eminent online destination for all your gaming and betting needs. Delivering a suite of industry-leading entertainment options that include online sports betting: live dealers: slots: high-end virtual casino and table games: we offer a complete i-gaming experience.`,
      text02:
        "Our strengths lie in understanding the needs of our valued players in their respective key markets to ensure we provide an i-gaming experience tailor made to their needs.",
      text03:
        "In line with this: we have created an innovative and comprehensive loyalty program with true rewards for real players coupled with generous promotions to ensure maximum entertainment for the first timer to the seasoned punter.",
      text04:
        "Our players are at the heart of everything we do: from providing provably fair games to cryptocurrency or traditional fiat Deposit and withdraw options. Our state-of-the-art securely encrypted servers and 24/7/365 customer support work in unison to achieve our goal of giving YOU the best entertainment value possible.",
    },
    affiliates: {
      text01: `Join ${VITE_APP_TITLE}'s affiliate program and earn money for referring our brands! You will receive commission every time a user signs up and uses ${VITE_APP_TITLE} through your referral link. As an affiliate of ${VITE_APP_TITLE}: the commission earned will be based on all bets made by the referred user. This is different from traditional affiliate programs in that you get the same commission regardless of whether the referred user wins or loses! All bets are calculated using the following table to calculate your commission:`,
      text02:
        "If you are an Affiliate with exceptional influence and reach to the masses or can provide other special services: please contact our outstanding online support team. After discussion with the affiliate manager: we can customize an affiliate program for you which may include higher commissions!",
      thead:
        "Affiliate sub-levels | Commission rate | User bet amount * commission rate",
      Commission: "Commission",
      col: "{num}st level",
    },
    club: {
      text01:
        "Welcome to the club!<br/>Whether you're seasoned or just starting out: you're a winner in our Loyal Players Club!",
      text02: `${VITE_APP_TITLE} Club is a community dedicated to our valuable players: allowing them to enjoy all the promotions on the site at any time. You will earn Club Points for every bet: every spin and every hand of the game. Use the points you earn as you progress through the club: unlocking amazing rewards along the way.`,
      text03:
        "Every time you unlock a bonus: you can get free bets: free spins: free games or cash back: and special gifts: the higher the level: the more valuable the gifts you unlock!",
      text04:
        "Club tier determines the value and type of rewards you can claim. Your rewards will continue to increase as the club progresses through the ranks.",
      text05:
        "Early in the game: your game multiplier is 1: meaning you earn 1 point for every dollar wagered and as you level up: so does your point multiplier: meaning you can use the same amount to earn higher points and unlock higher value rewards!",
      text06:
        "If you do not collect the minimum points required for each tier within a month: you will be downgraded to the next tier the following month.",
      text07:
        "If you have more questions to answer: please contact our online customer service team: we will answer all your questions patiently until you are satisfied!",
      thead: "level | title | required points",
      keep: "keep grade points",
      multiplier: "Points multiplier",
      col: "copper | bronze | silver | gold | ruby | sapphire | diamond | VIP",
    },
    partnerLabel: "Official Partners",
    partnerTitle:
      "Official Partner of Digitain | Official Partner of LEAP | Official Partner of KSV Holstein",
    partnerCotent1:
      "Digitain is focused on delivering unique customer experiences with their product offerings.<br/>Their team of sports traders employ the latest AI and machine learning technology to incomplete sentence.",
    partnerCotent2:
      "A gaming provider of high-end virtual games for online: mobile and retail markets:LEAP is devoted to melding<br/>technology and gaming to engage users with original content and proprietary realistic 3D entertainment.",
    partnerCotent3:
      "As one of the most dominant sides in North Germany: KSV Holstein has been a force since the 1900's. This German association football and sports club based in Kiel has unmatched pedigree and been the breeding ground for a significant number of German and International sporting elite.<br/>We are proud to strengthen our presence in Europe through our sponsorship of KSV Holstein. The synergies between our association will ensure we are better positioned to further drive our brand recognition within the European continent.",
  },
  bet: {
    betDetails: {
      All: "All",
      Single: "Single",
      Multi: "Multi",
      ActiveBets: "Active Bets",
      SettledBets: "Settled Bets",
    },
    betItem: {
      Multi: "Multi",
      Odds: "Odds",
      Stake: "Stake",
      PotentialWin: "Potential win",
    },
    betrecord: {
      Single: "Single",
      Multi: "Multi",
      Time: "Time",
      Sport: "Sport",
      Type: "Type",
      StakeUSD: "Stake",
      PotentialWin: "Potential winme",
      TransactionID: "Transaction ID",
      NoRecords: "No valid records",
      NoActiveTickets: "You have no active tickets",
    },
  },
  wallet: {
    filter: "Filter",
    recharge: {
      RechargeUSDT: "Deposit",
      ChooseRechargeMethod: "Choose A Recharge Method",
      QuickAmount: "Quick Amount",
      DepositAmount: "Deposit Amount",
      REAL: "REAL",
      MinimumDepositAmountREAL: "Minimum Deposit amount: 10 REAL",
      pageTitle: "Deposit",
      title: "Choose a Deposit Method",
      label1: "Urawa Red Diamonds",
      error:
        "Please make sure you use only the TRC-20 network when depositing USDT: in order to receive your balance seamlessly.",
      label2: "Recharge Address",
      tip1: "Minimum Deposit amount: 10 USDT",
      tip2: "After the recharge is successful: USDT will be automatically converted into fiat currency USD into the account balance.",
      tip3: "The exchange rate is 1:",
      qrLabel:
        "Scan the QR code through the wallet app to complete the Deposit.",
      webpay: "Web pay",
      qrCode: "QR code",
      onlyBindOneTime: "Only bind one time",
    },
    myWallet: {
      pageTitle: "My Wallet",
      wallet: "Wallet",
      use: "Wallet In Use",
      allowWithdrawal: "Allow Withdraw",
      code: "Required Bet Amount",
      bonus: "Bonus",
      assetsReal: "Assets Real",
      deposit: "Deposit",
      withdrawal: "withdraw",
      useThis: "Use This Wallet",
      previousBets: "Previous Bets",
      filter: "Filter",
      sureChangeWallet: "Are you sure to change this wallet in use?",
      Reminder: "Reminder",
    },
    withdraw: {
      pageTitle: "Choose A Recharge Method",
      withdrawAs: "Withdraw As",
      assets: "Assets",
      withDrawType: "WithDraw {type}",
      settledBets: "Settled Bets",
      depositMainnet: "Deposit Mainnet",
      withDrawAddress: "Recharge Address",
      quantity: "Quantity",
      mainnetFees: "Mainnet Fees",
      exchangerateType: "{type} Exchangerate",
      actualType: "Actual Credited {type}",
      withdrawAll: "Withdraw All",
      WithdrawalSucceeded: "Withdraw succeeded",
      Mainnet: "Mainnet",
      TransactionID: "Transaction ID",
      WithdrawUSD: "Withdraw USD",
      ExchangeRate: "Exchange Rate",
      ActualCredit: "Actual Credit",
      State: "State",
      Time: "Time",
    },
  },
  club: {
    title: "Welcome to the Club!",
    text01: `Exclusive Membership for Crypto Players! Whether you are on a hot streak or grinding hard: becoming a member of our ${VITE_APP_TITLE} Club will open the door to dazzling rewards and prizes.`,
    text02: "Your Membership Benefits",
    text03: "Your Road To Glory Starts Here!",
    TotalDeposits: "Accumulated Deposits",
    text04: "Distance To The Next Level : Vip",
    text05: "Deposit Required For Next Tire:",
    text06: "You Play: We Pay!",
    text07:
      "Switch to cryptocurrency to start accumulating points and accruing rewards with every bet.",
    Points: "Points",
    PointsMultiplier: "Points multiplier",
    TierRewards: "Tier rewards",
  },
  promote: {
    title: "Details",
    button01: "YOU HAVE OPTED IN",
    button02: "PLAY NOW",
    tooltip: "Successful application",
  },
  live: {
    MyFavourites: "My Favourites",
    HotPicks: "Hot Picks",
    AllGames: "All Games",
  },
  noData: {
    blog: "No data on the current page",
    modeGame: "You don’t have any games yet",
    helpDetail: "No more",
    gameCollection: "You don’t have any favorite games yet",
  },
  new: {
    start: "Start date",
    end: "End date",
    select: "Please select",
    currentProgress: "Current progress",
    home: "Home",
    footerText: `${VITE_APP_TITLE} is owned and operated by Mbet Solutions NV Schout Bij Nacht Doormanweg 40: P.O. Box 4745: Curaçao. It is licensed and regulated by the Government of Curaçao under the gaming license 1668/JAZ. Some payment processes are handled by its wholly owned subsidiary: mProcessing Solutions Ltd: Cyprus Menandrou 4: 1066: Nicosia: Cyprus.`,
    Register: "Register",
    signIn: "Sign in",
    Deposit: "Deposit",
    assets: "Balance",
    signOut: "Sign out",
    myAccount: "My Account",
    Amount: "Amount",
  },
  validator: {
    text01: "only alphanumeric char: no space: max of 10 characters",
    text02: "Please enter your account",
    text03: "Email was incorrect",
    text04: "Please enter your email",
    text05: "Inconsistent password input",
    text06: "Please enter your confirm password",
    text07: "The format is six digits",
    text08: "Please enter your code",
    text09: "The format was incorrect",
    text10: "Please enter your mobile number",
    text11: "Please enter your Amount",
    text12: "Code was incorrect",
    text13: "6 digits",
    text14: "required",
    text15: "Please input correct account number",
  },
  tooltips: {
    text01: "Verification code sent successfully",
    text02: "Reset successfully",
    text03: "Document Submitted",
    text04: "The size cannot exceed {m}m",
  },
  routerTitle: {
    PromotionsDetail: "Promotions Detail",
    Gameroom: "Game room",
    Withdraw: "Withdraw",
    Detail: "Detail",
  },
  vip: {
    VIP: "VIP",
    FullLevel: "Full level",
    SignIn: "Sign In",
    UpgradeMembership: "Upgrade Membership",
  },
  additional: {
    dontHaveWithdrawAccount: "You haven't bind any withdraw account",
    copySuccessfully: "Copy successfully",
    Mainnet: "Mainnet",
    Amount: "Amount",
    TransactionID: "Transaction ID",
    ExchangeRate: "Exchange Rate",
    AmountReceived: "Amount Received",
    Status: "Status",
    DateTime: "Date/Time",
    withAddress: "{name} Withdraw Address",
    inputAccountNo: "Enter Account No",
    accountType: "Account Type",
    accountNo: "Account No",
    withdrawAddress: "Withdraw Address",
    chooseWithdraw: "Choose A Withdraw Method",
    enterAmount: "Please enter Amount",
    amountFormat: "Amount formatted incorrectly: only integers can be entered",
    insufficientAmount: "Insufficient balance available for withdraw",
    correctAmount: "Minimum withdraw amount min :Maximum withdraw amount max",
    withdrawSuccess: "Withdraw succeeded",
    minAmount:
      "The limit per transaction is between {min} - {max} {mainCurrency}.",
    depositError:
      "Please make sure you use only the {channel} network when depositing {code}: in order to receive your balance seamlessly.",
    Maintenance: "The Deposit Channels Under Maintenance",
    changeWay: "Please Deposit with another way",
    state: "State",
    depositRecord: "Deposit record",
    withdrawRecord: "Withdraw record",
    MinimumDepositAmountREAL: "Minimum Deposit amount: {num} {currency}",
    channel: "Channel",
    // withAddress:"Withdraw Address"
    minimumAmountIs: "The minimum amount is", // 最小额度为
    errorTips01: "Dear User!",
    errorTips02:
      "Sorry: page is not available. Please contact customer service for further assistance. We apologize for the inconvenience. Thanks for your support and understanding!",
    Back2Home: "Back to Home",
    OfficialPartner: "Official Partners",
    Fees: "Fees",
    next: "Distance To The Next Level {name}",
    ConfirmNewPassword: "Confirm New Password",
    vipRequired: "Current EXP is {current}: EXP remaining {num} to level up",
    vipTips: "EXP = Deposit Amount * EXP rate of current level",
    Experience: "Experience",
    logout: "Are you sure you want to log out?",
    Delete: "Delete",
    Change: "Change",
    vipName: "VIP Level",
    range: "EXP Range",
    rate: "EXP Rate",
    TransactionList: "Trans List",
    Previous: "Previous",
    Next: "Next",
    unFinished:
      "You still have an unfinished order: do you continue to complete it?",
    insufficientWithdrawal: "Insufficient balance available for withdraw",
    turnOver: "{amount} more bets needed ",
    resend: "Resend",
    reinput: "Re-Input",
    yes: "Yes",
    later: "Later",
    continuePay: "Continue Pay",
    depositTips:
      "Please make sure that the input amount is the same as the final transfer amount to avoid recharge failure",
    accountNumber: "Please input correct account number",
    validateNo: "Please input correct account",
    realName: "Please input correct account name",
    accountName: "Account Name",
    depositLock: "Deposit Lock {time}s.",
    address: "Please input address",
    completed:'Completed',
    Start:'Start',
    Scanned:'Scanned',
    Skipped:'Skipped',
    Review:'Review',
    Rejected:'Rejected',
    Completed:'Completed',
    Blocked:'Blocked',
  },
  extra: {
    invite: "Your invite code",
    current: "Current",
    vip: " Member benefits",
    currentLevel: " Current level",
    message: "Your message",
    cockfight: "Cockfight",
    fishing: "Fishing",
    more: "More",
    hot: "Recommend",
    haveFun: "Classic mini games: happy to play",
    slots: "Total Slots Jackpots",
    todayReward: "Total Payout For Today",
    comingSoon: "Coming Soon",
    moreGame: "More Games",
    rank: "Ranking list",
    top: "Profit For Top 10",
    todayProfit: "Profit For Today",
    username: "Username",
    game: "Game Name",
    betAmount: "Amount",
    profit: "Profit",
    footer: `${VITE_APP_TITLE} Has passed all compliance reviews and is legally authorized to conduct entertainment operations of all wagering games.`,
    copyright: `© 2022 ${VITE_APP_TITLE} | Copyright Reserved`,
    gameBet: "In {name}:bet {bet}",
    InProfit: "Profit {amount}",
    betRecord: "Bet Record",
    bet: "Bet Amount",
    payout: "Payout",
    orderId: "ID",
    promoText: "Non-stop activity prizes: non-stop cash collection",
    slotText: "Enjoy different fun with classic slot machines",
    liveText: "Beauty takes you to make money in the game",
    cockText: "animal world an endless enjoyment",
    manageAddress: "Manage Address",
    myLevel: "My Level",
    platform: "Game Platform",
    balance: "Balance",
    fishingTitle: "Happy Fishing",
    fishingText: "Get Rich Instantly with High Jackpots",
    collectSuccess: "collect success",
    cancelCollect: "collect canceled",
    registerTip:
      "Sign up for free bonus event: open for a limited time: waiting for you to participate",
    join: "Join",
    slideToRight: "Swipe right to complete verification",
    finished: "Please complete the security verification",
    verFail: "verification failed",
    verSuccess: "Verification successful",
    toBind: "To Bind",
    giveUp: "Give Up",
    rule: "Commission Rules",
    maintenance: "Maintenance",
    EstimatedTime: "Estimated Time",
  },
  errorCode: {
    "-1": "System error",
    "0": "Success",
    "400": "Bad request",
    "401": "Invalid Token",
    "409": "TOKEN has expired",
    "402": "You have been automatically logged out due to inactivity",
    "403": "Forbidden",
    "411": "IP address rejected",
    "404": "Not Found",
    "405": "Resources are forbidden",
    "408": "Request timed out",
    "500": "Internal server error: please contact the administrator",

    "10000": "Parameters Invalid",
    "10002": "Invalid language",
    "10003": "Invalid device source",
    "10008": "Http request invalid",
    /** *************** 账户 20100 - 20199 ******************/
    "20100": "Username already exists",
    "20102": "Username no exists",
    "20112": "Username can not be null",
    "20113": "Merchant not found",
    "20114": "Not merchant account",
    "20115": "Merchant account: please login via merchant portal",
    "20116": "Phone no exists",
    "20117":
      "Sorry: The system is being upgraded and maintained: please contact online customer service to get more information and details.",
    "20120": "Please login the agent url",
    "20201": "Can't transfer member agent to super agent",
    "20104": "Account status is abnormal: please contact the online customer",
    "20105": "Verification Code no exists",
    "20108": "User wallet does not exist",
    "20107": "Cash can be withdrawn only after KYC certification",
    "30015": "sms send invalid",
    "30011": "Sms invalid",
    "30012": "Sms expired",
    "22000": "Agency relationship already exists",
    "30006": "sms send too high frequency",
    "30009": "Please contact online customer service",
    "30010": "Account already bounded mobile",
    /** *************** 密码 20400 - 20499******************/
    "20401": "Invalid password. Please try again!",
    "20402": "Invalid username or password. Please try again!",
    "20403": "INVALID GOOGLE CODE",
    /** *************** 手机 20500 - 20599 ******************/
    "20502": "Phone number already exists",
    "20503": "Email already exists",
    "20505": "Mobile no.needs to start with 09",
    "20506": "Please enter the correct mobile",
    "20504": "Mobile repeat",
    /** *************** 推广码 20600 - 20699 ******************/
    "20601": "Share Code Invalid",
    /** *************** 金额 20700 - 20799******************/
    "20701": "Transaction Error",
    "20706": "Insufficient balance",
    /** *************** 登录/登出 20800 - 20899 ******************/
    "20802":
      "Too many login errors: please contact the administrator or retrieve your password",
    /** *************** 注册 20900 - 20999 ******************/
    "20902": "Registration error",
    "20903": "Google login code expired",
    "20904": "Google login email exists",
    "20905": "Facebook login code expired",
    "20906": "Facebook login user id exists",
    /** *************** 三方绑定 21000 - 21100 ******************/
    "21000": "You already linked this account",
    "21001": "User bounded facebook",
    "21002": "Google login failed",
    "21003": "Facebook login failed",
    "21004": "The current Google account has been linked to other accounts",
    "21005": "Facebook login binding failed",
    "21006": "The current Google account has been linked to other accounts",
    "21007": "Facebook has bounden",
    "21203": "Modification failed",
    "21204": "Email address invalid",
    "21205": "Phone number invalid",
    "21206": "Bind mobile first",
    "21207": "Already set password",
    "21208": "Password not set",
    "21209": "Uid not set",
    /** *************** SMS 30000 - 30099 ******************/
    "30003": "Sms code invalid",
    "30004": "Sms code expired",
    "30005": "Sms code already used",
    /** *************** 优惠活动 30100 - 30199 ******************/
    "30119": "Insufficient valid bet amount!",
    "30126": "Active code can not be repeated!",
    "30127": "Active code can not be null!",
    "30131": "Active apply id invalid",
    "30110": "Already deposit can't join this active",
    "30111": "Active repeat apply",
    "30112": "Active deposit num out of limit",
    "130131": "Only can join 1 activity at a time",
    "30132": "The first order has been completed",
    "30141": "Active code_zh can not be repeated!",
    "30133": "Ladder Name is not null",
    "30134": "Check in rule is null",
    "30135": "Today already checked in",
    "30136": "Activity setting error",
    "30137": "Check in activity exists in this period",
    "30138": "Deposit not meet the standard",
    "30139": "Check in activity not exists",
    "30140": "Check in activity exists",
    /** *************** 游戏平台相关 39000 - 39999 ******************/
    "39001": "Game no exist",
    "39002": "The game is close for maintenance: please wait patiently",
    "39003": "The game is under maintenance",
    "39004": "Already in favorite list",
    "39005": "Please collect to the favorite list first",
    "39006": "No such game record!",
    "39008": "Game link generator invalid: please try again later",
    "39007": "Platform factory does not exist",

    "39901": "Invalid parameter ",
    "39908": "Request too frequent",
    "39909": "Invalid currency type",
    "39910": "Invalid language type",
    /** *************** APIEND 50000 - 59999 ******************/
    "5006": "Commission coin insufficient",
    /** *************** BACKEND 60000 - 69999 ******************/
    "60002": "The superior agent does not exist",
    "60005": "Transaction record query fail",
    "60016": "Member no exist",
    "60018": "Admin group exist",
    "60019":
      "This role has been associated with users: if you want to delete: please remove all users corresponding to the role!",
    "60020":
      "This group has been associated with users. If you need to delete it: please remove all users corresponding to the roles in the group!",
    "60021": "No auth to get rules",
    "60022": "Agent group don't update",
    "60028": "Role already exist!",
    "60029": "Test account cannot inquiry report data",
    "60031": "Search account not exists",
    "60032": "Search account not agent",
    "60050": "Tasks per user limit exceeds",
    "60041": "Threads limit exceeds",
    "60042": "Storage size limit exceeds",
    "60023": "Delete file failed",
    "60024": "Upload file failed",
    "60025": "No auth to act",
    "80001": "Invalid parameters",
    /** *************** 图片上传 70000 - 70100 ******************/
    "70003": "Failed to upload file",
    "70004": "Maximum upload size exceeded",
    /** ***************注单异常 70100 - 70200 ******************/
    "70100": "ES user coinLog synchronization exception",
    "70101": "ES Statistics The current bet flow is abnormal",
    "70103": "Failed to save coin Log",
    "70105": "Withdrawal order error",
    "70106": "Betslips refund error",
    "70108": "Withdrawal order is being processed",
    "70109": "Coin rate does not exist",
    "70110": "Withdrawal address article 3 the most ",
    "70210": "Withdrawal address not exists",
    "70111": "Betslips save repetition error",
    "70112": "Betslips refund order nothingness error",
    "70115": "No betting was recorded",
    "70116": "Repeated bets",
    "70117": "The uid of the in-station message cannot be empty ",
    "70118": "Save coin stream failure",
    /** ***************Digi 体育异常 70300 - 70400 ******************/
    "70300": "Sports signature verification error",
    "70301": "Sports PartnerId error",
    "70302": "Sports currencyid error",
    "70304": "Sports user not exist",
    "70314": "Sports user black",
    "30013": "Mobile format invalid",
    "70305": "Sports bet transaction id exist",
    "70306": "Sports bet order number exist",
    "70307": "Sports bet amount max",
    "70308": "The record does not exist",
    "70408": "The withdrawal amount is abnormal",
    "70309": "Pay channel is close!",
    "70320": "The deposit channel is abnormal",
    "70310": "Pay plat is close!",
    "70311": "Withdrawal order payment has been initiated",
    "70313": "Withdrawal address already exits",
    "70414": "Amount is invalid",
    "70316":
      "If you participate in the current activity: the previous unfinished activity will automatically canceled. Do you want to participate in the new activity immediately?",
    "70317": "Users need to top up to initiate a withdrawal",
    "70318": "Verification failure",
    "90001": "Decrypt Error",
    "70350": "Channel error: please re-select",
    "90003": "Unique key insert invalid",
    "90004": "System presence code",
    /** ***************不要直接抛出异常、仅作用于中转 ******************/
    "10004": "Not Allow Duplicate data",
    "99000": "Custom exception",
    "99001": "Database save exception",
    "99002": "Account transaction exception",
    "70321": "The number of daily withdrawals has been exceeded",
    "70322": "The daily withdrawal amount is exceeded",
    "70326": "No withdrawal address types available",
    "99003": "SNS Send Exception",
  },
  footer: {
    copyRight: "Copyright © 2024 Atg.club All rights reserved.",
    privacy: "Privacy Policy",
    contact: "Contact Us",
    gameCategories: "Game Categories",
    help: "Help Center",
    follow: "Follow Us",
    payment: "Payment Methods",
    terms: "Terms",
    res: "Responsible Gambling"
  },
  header:{
    login: "Login",
    signUp: "Sign Up",
    deposit: "Deposit",
    withdraw: "Withdraw",
    wallet: "My Wallet",
    accountHistory: "Account History",
    bet: "Bet Record",
    information: "Information",
    myAccount: "My Account",
    raffle: "Raffle Ticket",
    signOut: "Sign out",
    swap: "Swap",
    transfer: "Transfer",
    activityRecord: "Activity Record",
  },
  tabs:{
    totalFunds: "Total Funds",
    bonus: "Bonus",
    allowWithdraw: "Allow Withdraw",
    depositeLimit: "Deposit Limit",
    howToDeposit: "How to deposit",
    chooseMethod: "Choose a Deposit Method",
    balance: "Balance",
    current: "Current Balance",
    depositRecord: "Deposit Record",
    withdrawRecord: "Withdraw Record",
    status: "Status",
    date: "date",
    channel: "Channel",
    amount: "Amount",
    transactionId: "Transaction ID",
    exchangeRate: "Exchange Rate",
    mainnetFee: "Mainnet Fees",
    actualCredit: "Actual Credit",
    amountReceived: "Amount Received",
    time: "Time",
    noRecord: "You Have No Record",
    transType: "Trans Type",
    transList: "Trans List",
    previousBanlance: "Previous Balance",
    gamePlat: "Game Platform",
    id: "ID",
    gameName: "Game Name",
    betAmount: "Bet Amount",
    payout: "Payout",
    totalStatistics: "Total Statistics",
    incentives: "Incentives",
    verifyNow: "Verify Now",
    kycInfo: "KYC successful, you will receive a raffle ticket.",
    depositNow: "Deposit Now",
    depositInfo: "Deposit successful, you will receive a raffle ticket.",
    betNow: "Bet Now",
    betInfo: "Bet successful, you will receive a raffle ticket.",
    claim: "Claim",
    claimed: "Claimed",
    ticketDay: "Ticket/Day",
    type:"Type",
    referenceCode: "Reference Code",
    raffleRecord: "Raffle Ticket - Record",
    total: "Total",
    app: "APP Download",
    home: "Home",
    currency: "Currency",
    walletCategory: "Wallet category",
  },
  kyc:{
    step01: "Step",
    step02: "",
    help: "Help us prove your identity",
    agree: "Agree to terms",
    choose: "Choose and upload your ID.",
    document: "Document Verification",
    refer: "Refer to",
    term: "Terms & Conditions",
    accept: "for accepted ID List.",
    confirm01: "Confirm Information",
    confirm02: "Confirm Full Name and Date of Birth",
    face01: "Face Verification",
    face02: "Take a photo of your entire face",
    confirmBtn: "Confirm",
    startBtn: "Start Now",
    firstname: "First Name",
    firstname01: "Please input your first name",
    username: "Username",
    middlename: "Middle name",
    middlename01: "Please input your middle name",
    lastname: "Last Name",
    lastname01: "Please input your last name",
    birthday: "Birthday",
    birthday01: "Please pick a date",
    nearest:"Nearest Branch",
    nearest01:"Please select the relation store",
    nation:"Nationality",
    nation01:"Please pick your nationality",
    current: "Current Address",
    current01: "Please input current address",
    permanent: "Permanent address",
    permanent01: "Please input permanent address",
    employment: "Employment Type",
    employment01: "Please pick a type",
    occupation: "Occupation",
    mainSource: "Main source of funds",
    idtype:"ID Type",
    front: "Front photo of ID",
    back: "Back photo of ID",
    selfie: "Selfie with ID Card",
    faceInfo01: "Take a clear photo of your Valid ID for verification",
    faceInfo02: "Take a clear selfie photo holding  your Valid ID",
    reminder01: "Reminders",
    reminder02: "Update 1 valid ID (must be government issued and clear) with photo inside of frame",
    reminder03: "Use original ID (not photocopied)",
    reminder04: "Information of ID should include Name, Birthdate, photo is compulsory",
    reminder05: "In legal age (21 years) and all the details provided are all true",
    reminder06: "With the PAGCOR request, the website have the right to requires user to provide a video verification",
    recharge: "The recharge channel is under maintenance . .",
    bonus: "Complete KYC now and receive 🎁 50 PESO BONUS!!!",
  },
  sign:{
    yet:"Don‘t have an account yet？",
    signUpNow: "Sign Up Now",
    forgot:"Forgot Password?",
    phone: "Phone No.",
    verification:"Verification Code",
    enterPsw: "Enter the Password",
    orWith: "Or sign in with",
    bylogin: "By login or register, you are confirming that you are above 21 years old, and agreed to our",
  },
  term:{
    text01: "General Introduction and Definition",
    text02: "Ownership of POWERBTENT website belongs to Gotech Entertainment, Inc. a company duly registered under the laws of the Republic of the Philippines with SEC register no. 2022-060056776-98 and currently has a license issued by the Philippine Amusement and Gaming Corporation (PAGCOR) and is supervised by it, and having its official registered website Atg.club",
    text03: "This terms and conditions constitute the complete, final and exclusive agreement between the Account Holder and POWERBTENT, and governing the contractual relationship between POWERBTENT and the Account Holder. The POWERBTENT Terms and Conditions supersede and merge all prior agreements, representations and understandings between the Account Holder and POWERBTENT. By opening an POWERBTENT Account, registering, logging-in, using the Services, participating in the games, or accepting any Prize, a visitor or an Account Holder fully understands and agrees:",
    text04: "to become a party to the POWERBTENT Rules",
    text05: "POWERBTENT reserves the right to update, amend, edit and supplement the Atg.club Terms and Conditions at any time. Any substantial amendment to the POWERBTENT Terms and Conditions herein shall be notified in advance by POWERBTENT to the Account Holder before coming into effect.",
    text06: "All references in the POWERBTENT Rules to the singular shall include the plural and vice versa. All references to “the” shall include “a/an” and vice versa.",
    text07: "The account holder must read this agreement carefully and completely before accepting the services. This agreement forms a legally binding agreement between the account holder and POWERBTENT (herein referred to as 'POWERBTENT'). Gotech Entertainment, Inc. is www.Atg.club and Atg.club mobile version app (hereinafter defined as 'website' or 'site').",
    text08: "When an account holder clicks the 'Register' or 'Complete Registration' button during the registration process and use the software specified below, it means that he/she fully agrees to the rules and terms listed in the agreement, privacy protection rules, and other related gambling and website regular updates. Any violation of this agreement, will have the following consequences (deprivation of membership, account closure, confiscation of funds or other related legal proceedings).",
    text09: "Definition of Terms",
    text10: "'POWERBTENT account' is an account held by an Account Holder, for bona fide transactions, with a strict aim to establish a normal commercial relationship with POWERBTENT and with the strict purpose of conducting betting and other gaming and gambling transactions.",
    text11: "'Equipment' refers to all application equipment, including allbequipment used to access online services but not limited to personalbcomputers, notebooks, mobile phones, person electronic devices, PDAbphones, etc.",
    text12: "'Game' refers to the online entertainment system provided on the website.",
    text13: "'Software' refers to all computer programs, data files or any other requirements that are installed on a device and in turn capable of accessing websites and participating in services through the Internet.",
    text14: "'Sports betting' refers to 'sports', 'casino' or 'poker' and other related services and online entertainment provided by the website.",
    text15: `"Information" refers to the information provided on the website,
    including but not limited to match results, statistics, sports data,
    odds, etc.`,
    text16: `"Website" is the internet gateway accessible through the internet
    address www.Atg.club where all current and relevant information
    regarding POWERBTENT's operations is published, and through which the
    Services are provided to the Account Holders.`,
    text17: `"Service" is the gaming and betting offers provided by POWERBTENT to the
    Account Holder through the Website.`,
    text18: `"Card" refers to all types of cards with a function of "payment",
    "charge", "debit", "credit", "virtual" and/or similar.`,
    text19: `"Payment Solution Provider" is an intermediary acting as a payment
    agent for various different payment methods.`,
    text20: `"Regulator” is the Philippine Amusement and Gaming Corporation
    (PAGCOR), which is the regulatory authority established by Philippine
    law to govern all forms of gaming in the Philippines. For more
    information, please go to https://www.pagcor.ph/`,
    text21: `"Financial Institution" is a bank and/or other institution regulated
    by an applicable domestic financial services Act or similar.`,
    text22: `"Client Application" shall mean the web browser application opened by
    the Account Holder in order to use the Services.`,
    text23: `"Software" shall mean all software used by POWERBTENT to offer and/or
    operate the Services and/or run the Client Application.`,
    text24: `“Prize” is an amount, a bonus or a reward that can be won by the
    Account Holder.`,
    text25: `“Inactive Account” is an POWERBTENT Account which has not recorded any
    log-in and/or log-out for a period exceeding 6 consecutive months.`,
    text26: "License/Intellectual Property",
    text27: "The coding and organizational structure of the software belong to POWERBTENT’s copyright, trade secrets, intellectual property rights and other rights. An account holder or a visitor must not:",
    text28: "Forward and/or transfer streaming media services and project videos to any other person through the website, or post relevant information on any electronic communication website or service website accessible by third parties.",
    text29: "Copy, distribute, publish, reverse engineer, reverse compile, disassemble, modify, or translate the software or try to obtain the source code to make derivative files.",
    text30: "Sell, distribute, sublicense, transfer, distribute or rent the software.",
    text31: "Provide the software to third parties via the Internet or other means.",
    text32: "Export the software to any country through electronic transmission or other means without the written permission of POWERBTENT.",
    text33: "Use the software for any illegal purposes by nature.",
    text34: "If there are any losses, expenses or related commission fees caused by any illegal use, the perpetrator shall bear the responsibility.",
    text35: "All trademarks, service marks and trade names as well as images, graphics, text, concepts or methodologies (collectively the “Intellectual Assets”) found on the Website, the Client Application and the material contained therein are the exclusive property of POWERBTENT and/or POWERBTENT’s suppliers and partners. The Account Holder or any website visitor is not entitled to exploit or otherwise use any Intellectual Asset for whatever purpose, except for what is allowed by law.",
    text36: "Financial Security",
    text37: "The funds of POWERBTENT registered members are deposited separately from the company funds in a client account held with a Financial Institution approved by the Regulator. POWERBTENT will take measures to ensure that customer funds are not directly connected to any funds of the company. Client’s funds will be protected by all means.",
    text38: "POWERBTENT reserves the right to adjust any aspect of its business operations at any time to comply with other judicial regulations.",
    text39: "Account Holder Registration, Access and Security",
    text40: "1. POWERBTENT recognizes customers' personal rights (non-transferable and not exclusive) to use POWERBTENT website software, use equipment to obtain information on the website, and participate in online games.",
    text41: "2. The POWERBTENT website software is not suitable for the following people:",
    text42: "Under 21 years old",
    text43: "Where in the jurisdiction where they belong, they are below their legal age.",
    text44: "According to local judicial regulations, visiting the website is illegal and/or POWERBTENT cannot identify whether the customer's behavior is legal in the local jurisdiction such as:",
    text45: "Public officials, which includes elective and appointive officials and employees, permanent or temporary, whether in the career or non-career service, whether or not they receive compensation, regardless of amount.",
    text46: "Members of the Armed Forces of the Philippines, including the Army, Navy, Air Force or Philippine National Police",
    text47: "Persons under 21 years of age or students of any school, college or university in the Philippines;",
    text48: "PAGCOR officials and employees;",
    text49: "Gaming site Operators and employees;",
    text50: "Unregistered players;",
    text51: "Spouse, common-law partner, children, parents of officials and persons mentioned in items (a), (b), and (d) above",
    text52: "Players currently residing outside of the Philippines;",
    text53: `POWERBTENT employees and relatives of POWERBTENT employees ("relatives" refer to spouses, partners, parents, children or brothers and sisters.), or any POWERBTENT related former employees or relatives of former employees within 6 months of resignation (Including anything connected with POWERBTENT).`,
    text54: "3. In order to prevent people who do not meet the legal age from using the services, POWERBTENT reserves the right to require customers to provide proof of age at any time. If the account holder doesn’t qualify for the age standard or we suspect that he/she is below the age standard, we have the right to suspend/cancel the POWERBTENT account for a long-term/short-term period to prevent the unauthorized use of the services.",
    text55: "4. An individual cannot participate in a game for money unless that individual is an Account Holder. To be registered as a player, an individual must submit an application for registration and; must faithfully understand and fulfill the following terms:",
    text56: "The account holder is not an individual whose capacity is restricted by law;",
    text57: "The account holder’s statutory actions can only represent him/her personally, but not other people or other companies;",
    text58: "The account holder understands that his/her participation in POWERBTENT services is purely voluntary;",
    text59: "The account holder is not experiencing nor diagnosed with Compulsive Gambling Disorder nor registered as a Self-Excluded player in accordance to the guidelines set by the Regulator;",
    text60: "The account holder fully agrees that certain personal information (including specific information about payment methods) must be provided to POWERBTENT for confirmation when using its services.",
    text61: "POWERBTENT will follow the privacy policy to manage and control the information provided;",
    text62: "The account holder is responsible for the relevant taxes arising from gaming profits;",
    text63: "The account holder can only use the website for personal purposes. He/she shall only place bets for personal entertainment;",
    text64: "The account holder is not a citizen of the following countries: Anguilla, Antarctica, Argentina, Australia, Belgium, Bermuda, Bosnia and Herzegovina, Bouvet Island, British Indian Ocean Territory, British Virgin Islands, Cayman Islands, Channel Islands, Christmas Island, Cocos Islands, Croatia, Cyprus, Denmark (including Greenland and the Faroe Islands), Estonia, Ethiopia, Falkland Islands, France (including French Guiana, French Polynesia, French Southern Territory, Guadeloupe, Martinique) , Mayotte, New Caledonia, Reunion, Saint Martin, Saint Pierre and Miquelon, Saint Barthélemy, Wallis and Futuna), Germany, Gibraltar, Greece , Hilde and McDonald Islands, Hungary, Andhra Pradesh, Nagaland, Rajasthan, Sikkim, Tamil Nadu, Telangandi; Ireland, Iran, Iraq, Israel, Italy , Latvia, Lithuania, Montserrat, Netherlands (including Aruba, Bonaire, Curaçao, Saba, St. Eustatius and St. Martin), Norfolk Island, North Korea, Pakistan, Pitcairn Island, Poland, Portugal, Puerto Rico, Romania, Serbia, Singapore, Slovakia, South Africa, Georgia and the South Sandwich Islands, South Sudan, Spain, Sri Lanka, the State of Palestine, Swaziland, Sweden, Switzerland, Syria, Trinidad and Tobago, Tunisia, Turkey, Turks and Caicos Islands, Uganda, Ukraine, the United Kingdom and the United States of America (including American Samoa, Guam and the U.S. Virgin Islands), Vanuatu, Yemen;",
    text65: "The account holder agrees and can bear the risk of loss caused by using this service, POWERBTENT does not bear any risk for its loss;",
    text66: "All of the account holder’s deposit is not from illegal or criminal activities;",
    text67: "The account holder must not engage in any illegal activities by using his/her POWERBTENT account, or allow others to use its account to engage in any activities that violates both local and POWERBTENT rules;",
    text68: "The account holder shall keep the account credentials including the password properly and must not allow others to use it. If in case the password has been forgotten or lost, the POWERBTENT Customer Service Department will assist in setting up a new security information after a thorough authentication process;",
    text69: "The account holder is responsible for ensuring the confidentiality of the POWERBTENT account and login information. Any online gambling onducted with the account holder’s username and password will be considered valid;",
    text70: "When using POWERBTENT services, websites, equipment, software or related information, the account holder or a visitor must not in any way hinder other members from enjoying the services that POWERBTENT provides;",
    text71: "Stealing any relevant information of other members is strictly prohibited;",
    text72: "Uploading or publishing any virus-bearing programs, files or data that affect the performance of the website equipment, software, services, etc. is strictly prohibited;",
    text73: "The account holder shall not attempt to modify, reverse compile, reverse engineer or disassemble the software in any form;",
    text74: "The account holder must not use any equipment, automatic equipment, software, programs or other methods (similar to the aforementioned) to hinder or attempt to interfere with the normal operation of the services, equipment, software, URL, information or any transaction provided by the website, use of artificial intelligence Including the unrestricted plug-in system is strictly forbidden to connect to the server. All customer service operations must be performed by the player through the use of POWERBTENT program interface;",
    text75: "The account holder must not post any illegal information about harassment, insults, defamation, threats, obscenity, and incitement  to racial dissatisfaction on the website or equipment or to other users, or any criminal behavior that constitutes or encourages criminal acts, or causes civil liability incidents, etc. And other  related illegal information;",
    text76: "The account holder is not allowed to initiate or participate in investigations, quizzes, or mass release of chain letters/spam mails, mail peddling and other activities aimed at POWERBTENT;",
    text77: "The account holder cannot have multiple accounts at the same time. If POWERBTENT find that a customer has multiple accounts, the company has the right to cancel their accounts and place bets. The company reserves the right to cancel or close the duplicate account. The company reserves the right to restrict customers to keep only one of the accounts;",
    text78: "The account holder must not be a professional player in any sport, competition or league where POWERBTENT offers betting;",
    text79: "The account holder must not deposit nor withdraw through a Card which the Account Holder is not authorized to use and/or utilizing a Card in a jurisdiction in which betting and gaming are prohibited;",
    text80: "The account holder must not be employed or employs individuals who makes use of a system (including machines, robots, computers, software or any other automated system) designed to defeat or capable of defeating the Client Application and/or Software;",
    text81: "The account holder is not possessing an active account with another legal entity which is part of the same group as POWERBTENT, meaning other entities directly or indirectly controlled by Gotech Entertainment, Inc.;",
    text82: "The account holder must not find the Website or the Services offensive, objectionable, unfair, nor indecent; and to maintain his/her POWERBTENT Account details up-to-date in terms of the following: first and last name, valid email address, phone number and any vital information as required by POWERBTENT;",
    text83: "The account holder shall not allow any other individual, including any minor, to use or reuse its created POWERBTENT Account, access and/or use any material or information from the Website, accept any Prize, or access and/or participate in the Services;",
    text84: "The account holder must not sell, transfer or acquire account from  or to other players. Funds cannot be transferred between POWERBTENT accounts;",
    text85: "The account holder is not allowed to access the website through the use of a proxy server or any tool that interferes with the IP address of the device being used, or to take any measure that will prevent POWERBTENT from identifying the correct IP address of the device being used to access the website;",
    text86: "The account holder agrees with the application of the above general terms and agree that if there is any suspicion or violation of the customer's behavior, POWERBTENT has the right to report to the relevant authorities without prior notice or explanation.",
    text87: "The account holder agrees that upon registration, a USD 1,000 security deposit must be made which is non-refundable for the next 30 days as required by PAGCOR. After the indicated period is over, the account holder may utilize the said funds at will. POWERBTENT shall bear the cost of any transaction fees by providing a credit in the player’s POWERBTENT account.",
    text88: "After the security deposit, the minimum succeeding deposit is set at USD 1,000. As courtesy to its players, POWERBTENT shall bear the cost of any transaction fees through providing a credit in the player’s POWERBTENT account.",
    text89: "Minimum withdraw amount is set at USD 1,000 per transaction and the daily withdraw limit for normal account is set at USD 1,250,000. POWERBTENT will shoulder the transaction fee of up to 3 withdraw requests per day through providing a credit in the player’s POWERBTENT account otherwise all succeeding withdraws are subject to applicable transaction fees.",
    text90: "The account holder must agree that for every successful deposit, they must need to reach the required 100% turnover before they can submit a withdraw.",
  },
  content:{
    display: " Displaying",
    of: "of",
    game: "games",
    load: "Load More",
    promotion: "Non-stop activity prizes, Non-stop cash collection",
  },
  staking:{
    title: "ATG Staking",
    tab1: "Staking bonus",
    tab2: "About Defi",
    tab3: "About ATG",
    communityRewards: "Cumulative rewards: ",
    running:"Prize pool data",
    totalDefi:"ATG total staking amount",
    issued:"Amount of USDT issued",
    myTitle: "My Staking",
    depositUsd:"Current ATG quantity",
    issueUsd: "Balance of USDT wallet",
    code: "Amount of code washing",
    defiAmount: "Total staking number of ATG",
    totalUsd: "Total amount of USDT",
    communityTitle:"My community",
    time:"Time",
    type:"Reward type",
    amount: "Amount",
    communityDetail: "Community reward details",
    type1: "Defi",
    type2: "Return Defi",
    type3: "Defi rewards",
    type4: "Tier rewards",
    report: "search report",
    defiButton: "Defi",
    returnDefiButton: "Return Defi",
    withdrawRewards: "Withdraw Rewards",
    remark: "Note: Minimum defi amount >= 1000ATG",
    refundNote: "Are you sure you want to refund the Defi?",
    refundTitle: "Refund Defi",
    myCode: "My referral code",
    myAreaSize: "Number of people in the community",
    classBonus: "community rewards(USDT)",
    atgWalletBalance: "ATG balance(Can be withdrawn)",
    defiBonusAmount: "My ATG reward accumulation(Airdrop or other activities)",
    parentDefiPromoCode: "Defi Invitation Code",
    inputPromoCode: "Please input Defi Invitation Code",
    defiPromoUsername: "Recommender",
    aboutAtgTitle: "About ATG",
    aboutAtgContent: "$ATG is the internal token of ATG platform launched on BNB Chain. ATG’s unique mining solution, by placing bets, and Staking opportunity became a hallmark of the ATG project. Once you’ve got and hold $ATG tokens on ATG you can receive a part of the platform's bonus from the Staking pool.",
    getAtgTitle:"How to get $ATG?",
    playGamesTitle: "Play games",
    playGamesContent: "Win $ATG tokens by playing games on ATG. Enjoy fun gaming & betting on $ATG token! ",
    exchangeTitle: "Exchange",
    exchangeContent: "$ATG can be purchased on one of the TOP exchanges.Pancakeswap is the fastest and easiest way to buy $ATG.",
    atgStakingTitle: "$ATG Staking",
    atgStakingContent: "Staking is the system of USD daily drops for all $ATG token holders. Every 24 hours, the Staking pool releases 0.1% of the total betting turnover of the platform. The amount is shared among all users who hold $ATG tokens. The more $ATG tokens, the greater Staking income! $ATG token became a tradable asset! Buy and Sell $ATG tokens anytime!",
    reminder:"All tokens mined before going to exchange are available for trading. New tokens will be locked for withdrawals. The user will be able to get Staking rewards and play with $ATG until the tokens are unlocked.",
  },
  swap:{
    send: "Send",
    balance: "Your balance:",
    get: "Get",
    currency: "Currency",
    amount: "Amount",
    agree: "I have read and agree to the terms and conditions",
    confirm: "Confirm",
    selectCurrecy: "Please select currency",
    inputAmount: "Please input Amount",
    note1: "I have read and agree \xa0",
    note2: "terms and Conditions",
    oneSecond: "Completed in one second",
    remind1: "By clicking「Confirm」you agree that you have accepted that I have read and agreed to \xa0",
    remind2: ".By entering into a transaction, you also acknowledge that you are entering into the transaction voluntarily and that you are aware of the potential consequences of your actions.",
    confirmError1: "Please select a different currency to swap",
    confirmError2: "Please enter the amount",
  },
  withdraw:{
    title: 'Choose a Withdraw Card',
    cardNumber: 'Card Number',
    beenSet: 'Has been set as default',
    set: 'Set as default',
    quickNum: 'Quick Amount',
    withdrawNum: 'Withdraw Amount',
    enterNum: 'Please enter the amount',
    successMsg: 'You have submit successfully ,please wait to processed!',
    allow: 'Allow Withdraw:',
    all: 'withdraw all',
    actual: 'Actual Credited',
    withdraw: 'Withdraw',
    ForcedWithdrawal: "Forced withdrawal",
    normalAuditFees: "Regular audit fees",
    promotionAuditFees: "Activity audit handling fee",
  },
  transfer:{
    title: "Transfer",
    balance: "Balance",
    transWalletBalance: "Transfer wallet balance",
    thirdWalletBalance: "BBIN wallet balance",
    from: "From",
    to: "To",
    transWallet: "Transfer wallet",
    thirdWallet: "BBIN wallet",
    toGame: "Enter Game",
    success: "Transfer successful",
    toGameText: "Do you want to enter the game now?",
    yes: "Yes",
    no: "No"
  },
  vipClub:{
    tab1:"Vip Club",
    tab2:"Vip Level",
    subTitle:'JOIN ATG AND ENJOY DAILY REWARDS',
    clubTitle1:'Bet Return',
    clubContent1:'ATG Games encourages customers to continue playing games on the website by continuously accumulating growth points through VIP, thereby obtaining more rewards and honors.',
    clubContent2:'',
    clubTitle2:'Example',
    example1:'Member A',
    example2:'Level : Platinum',
    example3:'Total Rewards : 2,450,000',
    clubTitle3:'Rules',
    rules1:'The peak VIP level starts to accumulate the required conditions after registration.',
    rules2:'The quasi-VIP incentive threshold is recalculated every month and the required conditions for valid bets are calculated from the 1st to the 31st of the month until 23:59.',
    rules3:'Prize collection time: Please follow the instructions for prize collection',
    rules4:'The activity bonus can be withdrawn after 3 times the turnover. Turnover generated by videos and lottery tickets are not included in the calculation.',
    rules5:'Creating multiple accounts, cheating on bets or forming cliques for betting is strictly prohibited. We have a very professional betting monitoring and inspection system and will strictly handle fraudulent betting cases.',
    groupId:'Game Type',
    levelBtn1:'Gift record',
    levelBtn2:'Monthly record',
    contribution:'Contribution',
    bet:'Valid bet',
    giftMoney:'Gift bonus',
    week:'Weekly benefits',
    weekContent:'Get weekly bonuses based on your activity.',
    birthday:'Birthday bonus',
    birthdayContent:'Bonuses are ready for you to make your birthday celebration even sweeter.(It needs to be registered for one month before it can be sent.)',
    categoryList1:'promotion bonus',
    categoryList2:'birthday bonus',
    categoryList3:'weekly bonus',
    categoryList4:'monthly bonus',
    categoryList5:'Peak permanent upgrade',
    categoryList6:'Monthly Honor Upgrade',
    amount:'Amount',
    category:'Category',
    releaseTime:'Release Time',
    status:'Status',
    createTime:'Creation Time',
  },
  activity: {
    apply: "Apply",
    createdAt: "Created At",
    activityStatus: "Activity status",
    bonus: "Bonus",
    flowClaim: "Flow claim",
    customActivityApply: "Custom activity apply",
    submit: "Submit",
  },
}
